import React from 'react';
import { usePassengerIconStyles } from './PassengerIcon.styles';

export const PassengerIcon = React.memo(function PassengerIcon() {
    const styles = usePassengerIconStyles();
    return (
        <svg
            className={styles.root}
            width="20"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2992_6708)">
                <path d="M6.59 4.41C5.81 3.63 5.81 2.36 6.59 1.58C7.37 0.799995 8.64 0.799995 9.42 1.58C10.2 2.36 10.2 3.63 9.42 4.41C8.63 5.2 7.37 5.2 6.59 4.41ZM5 15V7C5 6.45 4.55 6 4 6C3.45 6 3 6.45 3 7V15C3 17.76 5.24 20 8 20H13C13.55 20 14 19.55 14 19C14 18.45 13.55 18 13 18H8C6.34 18 5 16.66 5 15ZM18.28 18.35L14.51 14.58C14.14 14.21 13.63 14 13.1 14H10.5V10.32C11.59 11.21 13.16 12.02 14.7 12.34C15.37 12.48 16 11.98 16 11.3C16 10.77 15.61 10.34 15.08 10.25C13.66 10.01 12.2 9.24 11.33 8.27999L9.93 6.73C9.74 6.52 9.5 6.35 9.24 6.23C8.95 6.09 8.62 6 8.28 6H8.25C7.01 6 6 7.01 6 8.25V14C6 15.66 7.34 17 9 17H14.07L16.85 19.78C17.24 20.17 17.89 20.17 18.28 19.78C18.68 19.39 18.68 18.75 18.28 18.35Z" />
            </g>
            <defs>
                <clipPath id="clip0_2992_6708">
                    <rect width="20" height="20" />
                </clipPath>
            </defs>
        </svg>
    );
});
