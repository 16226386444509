import { makeStyles, shorthands, tokens } from "@fluentui/react-components";


export const useBroadcastMessageReportPageStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    table: {
        marginTop: tokens.spacingVerticalM,
        overflow: 'auto',
        flexGrow: 1,
        height: 'fit-content',
    },
    tableRow: {
        justifyContent: 'space-between',
        minHeight: '44px',
        minWidth: 'fit-content',
        ...shorthands.padding(0, tokens.spacingHorizontalXL),
    },
    spiner: {
        position: 'absolute',
        minWidth: '100%',
        top: '50%',
    },
    flightsBroadcast: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        overflow: 'hidden',
        gap: tokens.spacingHorizontalS,
    },
    pendingSendingIcon: {
        color: tokens.colorBrandForeground2,
    },
    successfullySentIcon: {
        color: tokens.colorStatusSuccessBackground3,
    },
    sendFailedIcon: {
        color: tokens.colorStatusWarningBackground3,
    },
    acknowledgedIcon: {
        color: tokens.colorBrandForeground2,
        ...shorthands.margin(0, 0, 0, tokens.spacingHorizontalM),
    },
    messageGroup: {
        position: 'absolute',
        zIndex: 1000,
        top: '50px',
        right: 0,
        width: '350px',
    }
});