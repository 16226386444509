import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';


export interface SendMessageToTeamState {
  message: string;
  isSubmitting?: boolean;
}

const initialState: SendMessageToTeamState = {
  message: '',
};

export const sendMessageToTeamSlice = createSlice({
  name: 'sendMessageToTeam',
  initialState,
  reducers: {
    updateMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setIsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    }
  },
  extraReducers: (builder) => {

  }
});

export const { updateMessage, setIsSubmitting } = sendMessageToTeamSlice.actions;

export const selectSendMessageToTeam = (state: RootState) => state.sendMessageToTeam;

export default sendMessageToTeamSlice.reducer;
