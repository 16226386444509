import { app } from '@microsoft/teams-js';
import React, { useCallback, useEffect } from 'react';
import {
    Button,
    Input,
    InputOnChangeData,
    mergeClasses,
    SelectTabData,
    SelectTabEvent,
    Tab,
    TabList,
    TabValue,
    Toolbar,
    ToolbarGroup,
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useAppConfigurationPageStyles } from './AppConfigurationPage.styles';
import WelcomeMessagesPanel from './Panels/WelcomeMessagesPanel/WelcomeMessagesPanel';
import { Dismiss24Regular, Search24Regular } from '@fluentui/react-icons';
import { useToolbarStyles } from '../../controls';
import { useDebouncedCallback } from 'use-debounce';
import { useAppDispatch, useSelector } from '../../store/hooks';
import { selectAppConfigurationSearchQuery, setSearchQuery } from '../../store/features/appConfiguration/appConfigurationSlice';

enum AppConfigTabs {
    WelcomeMessages,
    SecondPage,
}

const AppConfigurationPage = () => {
    const { t } = useTranslation();
    const styles = useAppConfigurationPageStyles();
    const toolbarStyles = useToolbarStyles();
    const dispatch = useAppDispatch();
    const searchQuery = useSelector(selectAppConfigurationSearchQuery);
    const [search, setSearch] = React.useState(searchQuery);


    const [selectedValue, setSelectedValue] = React.useState<TabValue>(
        AppConfigTabs.WelcomeMessages
    );

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
    };

    useEffect(() => {
        app.notifySuccess();
    }, []);

    const searchDebounced = useDebouncedCallback(
        useCallback(
            (_:React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                dispatch(setSearchQuery(data.value ?? ''));
            },
            [dispatch]
        ),
        500
    );

    const onQueryChange = useCallback(
        (_: any, data: InputOnChangeData) => {
            setSearch(data.value);
            searchDebounced(_, data);
        },
        [searchDebounced]
    );

    const onClearSearchTextClick = useCallback(() => {
        setSearch('');
        dispatch(setSearchQuery(''));
    }, [dispatch]);

    return (
        <div className={styles.root}>
            <Toolbar aria-label="Flights" className={toolbarStyles.toolbar}>
                <ToolbarGroup role="presentation" className={toolbarStyles.toolbarGroup}>
                    <TabList
                        selectedValue={selectedValue}
                        onTabSelect={onTabSelect}
                        className={styles.tabList}
                    >
                        <Tab
                            value={AppConfigTabs.WelcomeMessages}
                            className={styles.tab}
                        >
                            {t('AppConfiguration_WelcomeMessages_Section')}
                        </Tab>
                        {/* <Tab
                            value={AppConfigTabs.SecondPage}
                            className={styles.tab}
                        >
                            Second Tab
                        </Tab> */}
                    </TabList>
                </ToolbarGroup>
                <ToolbarGroup
                    role="presentation"
                    className={mergeClasses(toolbarStyles.toolbarGroup, toolbarStyles.toolbarRightGroup)}
                >
                    <Input
                        className={toolbarStyles.searchBar}
                        placeholder={t('SearchFlightsInputPlaceholder')!}
                        size="small"
                        contentAfter={
                            <Button
                                appearance="transparent"
                                onClick={onClearSearchTextClick}
                                icon={
                                    !search ? (
                                        <Search24Regular />
                                    ) : (
                                        <Dismiss24Regular />
                                    )
                                }
                            />
                        }
                        onChange={onQueryChange}
                        value={search}
                    />
                </ToolbarGroup>
            </Toolbar>
            {selectedValue === AppConfigTabs.WelcomeMessages && (
                <WelcomeMessagesPanel />
            )}
            {selectedValue === AppConfigTabs.SecondPage && (
                <div>Second Page</div>
            )}
        </div>
    );
};

export default AppConfigurationPage;
