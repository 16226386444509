import React, { memo } from "react";
import { FlightPassenger } from "../model";
import {
  makeStyles,
  mergeClasses,
  Body1Stronger,
  Body1,
  tokens
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { formatPassengerLoyalty } from "../utils/formatters";


const useStyles = makeStyles({
  passengers: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalS,
  },
  passenger: {
    display: 'flex',
    flexDirection: 'column',
  },
  passengerFact: {
    display: 'flex',
    columnGap: tokens.spacingHorizontalXS,
    "> *": {
      width: '50%'
    }
  }
});


const PassengersList = (props: { className?: string, passengers: FlightPassenger[] }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  return <div className={mergeClasses(props.className, styles.passengers)}>
    {(props.passengers ?? []).map((p) =>
      <div key={p.id} className={styles.passenger}>
        <div className={styles.passengerFact}>
          <Body1Stronger >
            {t('Passenger_DisplayName')}
          </Body1Stronger>
          <Body1>
            {p.displayName}
          </Body1>
        </div>
        <div className={styles.passengerFact}>
          <Body1Stronger>
            {t('Passenger_SeatNumber')}
          </Body1Stronger>
          <Body1>
            {p.seatNumber}
          </Body1>
        </div>
        <div className={styles.passengerFact}>
          <Body1Stronger>
            {t('Passenger_LoyaltyLevel')}
          </Body1Stronger>
          <Body1>
            {formatPassengerLoyalty(p.loyalty)}
          </Body1>
        </div>
      </div>)}
  </div>
}

export default memo(PassengersList);