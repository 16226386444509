import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useWelcomeMessagesPanelStyles } from './WelcomeMessagesPanel.styles';
import {
    Button,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableColumnDefinition,
    TableColumnSizingOptions,
    TableHeader,
    TableHeaderCell,
    TableRow,
    ToolbarButton,
    createTableColumn,
    useTableColumnSizing_unstable,
    useTableFeatures,
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
    Add24Regular,
    bundleIcon,
    Edit24Filled,
    Edit24Regular,
} from '@fluentui/react-icons';
import { useAppDispatch } from '../../../../store/hooks';
import {
    createWelcomeMessages,
    deleteWelcomeMessages,
    loadWelcomeMessages,
    searchWelcomeMessages,
    selectWelcomeMessagesPanel,
    updateWelcomeMessages,
} from '../../../../store/features/welcomeMessages/welcomeMessagesSlice';
import { useSelector } from 'react-redux';
import { WelcomeMessageEntity } from '../../../../model';
import { useDebouncedCallback } from 'use-debounce';
import CreateOrUpdateMessageDialogAction, {
    WelcomeMessageDialogMode,
} from './components/CreateOrUpdateMessageDialogAction';
import ConfirmDeletionDialogAction from './components/ConfirmDeletionDialogAction';
import Pagination from '../../../Pagination';
import { selectAppConfigurationSearchQuery } from '../../../../store/features/appConfiguration/appConfigurationSlice';

type Ceil = {
    value: string;
};

type WelcomeMessage = {
    flight: Ceil;
    message: Ceil;
    onEdit?: (item: WelcomeMessage) => void;
    onDelete?: (item: WelcomeMessage) => void;
};

const EditIconBundle = bundleIcon(Edit24Filled, Edit24Regular);

const columnSizingOptions: TableColumnSizingOptions = {
    flight: {
        minWidth: 80,
    },
    proposedMessage: {
        minWidth: 230,
    },
    actions: {
        minWidth: 80,
    },
};

const getColumns: (
    t: TFunction<'translation', undefined>
) => TableColumnDefinition<WelcomeMessage>[] = (t) => [
    createTableColumn<WelcomeMessage>({
        columnId: 'flight',
        compare: (a, b) => {
            return a.flight.value.localeCompare(b.flight.value);
        },
        renderHeaderCell: () => {
            return t('TableHeader_Aircraft');
        },
        renderCell: (item) => {
            return item.flight.value;
        },
    }),
    createTableColumn<WelcomeMessage>({
        columnId: 'proposedMessage',
        compare: (a, b) => {
            return a.message.value.localeCompare(b.message.value);
        },
        renderHeaderCell: () => {
            return t('TableHeader_ProposedMessage');
        },
        renderCell: (item) => {
            return item.message.value;
        },
    }),
    createTableColumn<WelcomeMessage>({
        columnId: 'actions',
        renderHeaderCell: () => {
            return <></>;
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>
                    <Button
                        appearance="transparent"
                        icon={<EditIconBundle />}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (item.onEdit) {
                                item.onEdit(item);
                            }
                        }}
                    />
                    <ConfirmDeletionDialogAction
                        flight={item.flight.value}
                        onConfirm={() => {
                            if (item.onDelete) {
                                item.onDelete(item);
                            }
                        }}
                    />
                </TableCellLayout>
            );
        },
    }),
];

const convertWelcomeMessageEntityToWelcomeMessages = (
    entity: WelcomeMessageEntity,
    onEdit?: (item: WelcomeMessage) => void,
    onDelete?: (item: WelcomeMessage) => void
): WelcomeMessage => ({
    flight: {
        value: entity.rowKey,
    },
    message: {
        value: entity.value,
    },
    onEdit: onEdit,
    onDelete: onDelete,
});

const WelcomeMessagesPanel = () => {
    const { t } = useTranslation();
    const styles = useWelcomeMessagesPanelStyles();
    const dataGridRef = useRef(null);

    const dispatch = useAppDispatch();
    const { items, loadStatus, nextPageToken, pagination } = useSelector(
        selectWelcomeMessagesPanel
    );
    const searchQuery = useSelector(selectAppConfigurationSearchQuery);

    const columns = useMemo<TableColumnDefinition<WelcomeMessage>[]>(
        () => getColumns(t),
        [t]
    );

    const [open, setOpen] = useState(false);
    const [flightMessageDialogMode, setFlightMessageDialogMode] =
        useState<WelcomeMessageDialogMode>();
    const [selectedFlight, setSelectedFlight] = useState<string>('');
    const [selectedFlightWelcomeMessage, setSelectedFlightWelcomeMessage] =
        useState<string>('');

    const resetDialogState = useCallback((open: boolean) => {
        setOpen(open);
        setSelectedFlight('');
        setSelectedFlightWelcomeMessage('');
    }, []);

    const onScrollCallback = useCallback(
        (ev: Event) => {
            const target = ev.target as HTMLDivElement;
            if (
                nextPageToken &&
                target.scrollTop + target.clientHeight * 6 > target.scrollHeight
            ) {
                dispatch(loadWelcomeMessages());
            }
        },
        [dispatch, nextPageToken]
    );

    const handleScrollDebounced = useDebouncedCallback(onScrollCallback, 400);

    useEffect(() => {
        dispatch(loadWelcomeMessages());
    }, [dispatch]);

    useEffect(() => {
        dispatch(searchWelcomeMessages(searchQuery));
    }, [dispatch, searchQuery]);

    useEffect(() => {
        const sectionElement = dataGridRef.current as HTMLDivElement | null;
        if (sectionElement) {
            sectionElement.addEventListener('scroll', handleScrollDebounced);
        }
        return () => {
            if (sectionElement) {
                sectionElement.removeEventListener(
                    'scroll',
                    handleScrollDebounced
                );
            }
        };
    }, [dispatch, handleScrollDebounced]);

    const onItemEditCallback = useCallback((item: WelcomeMessage) => {
        setFlightMessageDialogMode(WelcomeMessageDialogMode.Edit);
        setSelectedFlight(item.flight.value);
        setSelectedFlightWelcomeMessage(item.message.value);
        setOpen(true);
    }, []);

    const onDeleteCallback = useCallback(
        (item: WelcomeMessage) => {
            resetDialogState(false);
            dispatch(deleteWelcomeMessages(item.flight.value));
        },
        [dispatch, resetDialogState]
    );

    const welcomeMessages = items.map((item) =>
        convertWelcomeMessageEntityToWelcomeMessages(
            item,
            onItemEditCallback,
            onDeleteCallback
        )
    );

    const { getRows, columnSizing_unstable: columnSizing } = useTableFeatures(
        {
            getRowId: (item) => item.flight.value,
            columns,
            items: welcomeMessages ?? [],
        },
        [
            useTableColumnSizing_unstable({
                columnSizingOptions,
            }),
        ]
    );

    const rows = getRows();

    return (
        <div className={styles.root}>
            <Table
                className={styles.table}
                size="small"
                role="grid"
                aria-label="broadcastMessages"
                noNativeElements
            >
                <TableHeader>
                    <TableRow className={styles.tableRow}>
                        {columns.map((column) => (
                            <TableHeaderCell
                                key={column.columnId}
                                style={
                                    columnSizing.getTableHeaderCellProps(
                                        column.columnId
                                    )?.style
                                }
                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map(({ item }) => (
                        <TableRow
                            className={styles.tableRow}
                            key={item.flight.value}
                        >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.columnId}
                                    tabIndex={0}
                                    role="gridcell"
                                    style={{
                                        ...columnSizing.getTableCellProps(
                                            column.columnId
                                        )?.style,
                                    }}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination
                currentPage={pagination.currentPage}
                pageSize={pagination.pageSize}
                totalItems={pagination.totalCount}
                actions={
                    <ToolbarButton
                        onClick={() => {
                            setFlightMessageDialogMode(
                                WelcomeMessageDialogMode.New
                            );
                            setOpen(true);
                        }}
                        icon={<Add24Regular />}
                    >
                        {t('ToolbarButton_Add')}
                    </ToolbarButton>
                }
            />
            <CreateOrUpdateMessageDialogAction
                open={open}
                mode={flightMessageDialogMode ?? WelcomeMessageDialogMode.New}
                flight={selectedFlight}
                welcomeMessage={selectedFlightWelcomeMessage}
                onOpenChange={(_, data) => {
                    resetDialogState(data.open);
                }}
                onSaveBtnClick={(e, { flight, welcomeMessage }) => {
                    resetDialogState(false);
                    if (
                        flightMessageDialogMode ===
                        WelcomeMessageDialogMode.Edit
                    ) {
                        dispatch(
                            updateWelcomeMessages({
                                rowKey: flight,
                                value: welcomeMessage,
                            })
                        );
                    } else {
                        dispatch(
                            createWelcomeMessages({
                                rowKey: flight,
                                value: welcomeMessage,
                            })
                        );
                    }
                }}
            />
            {loadStatus === 'loading' && (
                <Spinner className={styles.spiner} appearance="primary" />
            )}
        </div>
    );
};

export default WelcomeMessagesPanel;
