import React, { useCallback, useEffect } from 'react';
import { useMishandledBagageOnFlightPageStyles } from './FlightBaggageStatusPage.styles';
import { BaggageStatus, ISorting, Passenger } from '../../model';
import { app } from '@microsoft/teams-js';
import FlightBaggageStatusTable from './FlightBaggageStatusTable/FlightBaggageStatusTable';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { useSelector } from 'react-redux';
import {
    loadFlightBaggageStatus,
    flightBaggageStatusState,
    toggleSort,
    FilterBaggageStatusFiltersState,
    setFilters,
    setQueryText,
    initOnFlightBaggageStatusPageLoad,
    loadCustomerBaggageInfoDialogData,
    selectPassenger,
    clearPassengerBaggageInfo,
    setCurrentPage,
    setFlightTeamId,
    notifyCustomer,
} from '../../store/features/flightBaggageStatus/flightBaggageStatusSlice';
import FlightBaggageStatusToolbar from './FlightBaggageToolbar/FlightBaggageToolbar';
import { useDebouncedCallback } from 'use-debounce';
import BaggageInfoDialogAction from './BaggageInfoDialog/BaggageInfoDialog';
import Pagination from '../Pagination';
import { DialogOpenChangeData, Spinner } from '@fluentui/react-components';
import { loadConfig } from '../../store/features/config/configSlice';

interface FlightBaggageStatusPageProps {
    subEntityId?: string;
}

const FlightBaggageStatusPage = ({
    subEntityId,
}: FlightBaggageStatusPageProps) => {
    const styles = useMishandledBagageOnFlightPageStyles();

    const dispatch = useAppDispatch();
    const { flightTeamId: flightTeamIdParam } = useParams();
    const flightTeamId = subEntityId ?? flightTeamIdParam;

    const {
        items,
        sorting,
        loyalties,
        cabins,
        customerInfo,
        pagination,
        loadStatus,
    } = useSelector(flightBaggageStatusState);

    const dialogOpenActionRef = React.useRef<HTMLButtonElement>(null);

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);


    useEffect(() => {
        app.notifySuccess();
        if (flightTeamId) {
            dispatch(setFlightTeamId(flightTeamId));
            dispatch(toggleSort('seatNumber')); // default sorting when flightTeamId is provided (tab is opened in flight team)
        }
        dispatch(initOnFlightBaggageStatusPageLoad());
        dispatch(loadConfig());
    }, [dispatch, flightTeamId]);

    useEffect(() => {
        if (
            isDialogOpen &&
            customerInfo?.flightTeamId &&
            customerInfo?.cabin &&
            customerInfo?.seatNumber
        ) {
            dispatch(loadCustomerBaggageInfoDialogData());
        }
    }, [
        dispatch,
        customerInfo?.flightTeamId,
        customerInfo?.cabin,
        customerInfo?.seatNumber,
        isDialogOpen,
    ]);

    const onQueryParamsUpdateCallback = useCallback(() => {
        dispatch(loadFlightBaggageStatus());
    }, [dispatch]);

    const onFilterUpdateCallback = useCallback(
        (filter: FilterBaggageStatusFiltersState) => {
            dispatch(setFilters(filter));
            onQueryParamsUpdateCallback();
        },
        [dispatch, onQueryParamsUpdateCallback]
    );

    const onSearchQueryUpdateCallback = useDebouncedCallback(
        (query: string) => {
            dispatch(setQueryText(query));
            onQueryParamsUpdateCallback();
        },
        300
    );

    const onSortingChangeCallback = useCallback(
        (sorting: ISorting) => {
            dispatch(toggleSort(sorting.sortBy));
            onQueryParamsUpdateCallback();
        },
        [dispatch, onQueryParamsUpdateCallback]
    );

    const onSelectPassengerCallback = useCallback(
        (passenger: Passenger) => {
            dispatch(
                selectPassenger({
                    ...passenger,
                })
            );
        },
        [dispatch]
    );

    const onPassengerSelectCallback = useCallback(
        (passenger: Passenger) => {
            onSelectPassengerCallback(passenger);
            dialogOpenActionRef.current?.click();
        },
        [onSelectPassengerCallback]
    );

    const onOpenChangeCallback = useDebouncedCallback(
        (_: unknown, data: DialogOpenChangeData) => {
            setIsDialogOpen(data.open);
            if (!data.open) {
                dispatch(clearPassengerBaggageInfo());
            }
        },
        200
    );

    const onNotifyClickCallback = useCallback(
        ({
            flightTeamId,
            cabin,
            seatNumber,
        }: {
            flightTeamId?: string;
            cabin?: string;
            seatNumber?: string;
        }) => {
            if (
                customerInfo?.flightTeamId &&
                customerInfo?.cabin &&
                customerInfo?.seatNumber
            ) {
                dispatch(
                    notifyCustomer({ flightTeamId, cabin, seatNumber })
                ).then(() => dispatch(loadFlightBaggageStatus()));
            }
        },
        [
            dispatch,
            customerInfo?.flightTeamId,
            customerInfo?.cabin,
            customerInfo?.seatNumber,
        ]
    );

    return (
        <div className={styles.root}>
            <FlightBaggageStatusToolbar
                cabins={cabins}
                loyalties={loyalties}
                baggageStatuses={Object.keys(BaggageStatus)}
                onFilterUpdate={onFilterUpdateCallback}
                onSearchQueryUpdate={onSearchQueryUpdateCallback}
            />
            <FlightBaggageStatusTable
                isFlightTeamBoard={!!flightTeamId}
                items={items}
                sorting={sorting}
                onSortingChange={onSortingChangeCallback}
                onPassengerSelect={onPassengerSelectCallback}
            />
            <Pagination
                currentPage={pagination.currentPage}
                pageSize={pagination.pageSize}
                totalItems={pagination.totalCount}
                onChange={(page) => {
                    onSearchQueryUpdateCallback.cancel();
                    dispatch(setCurrentPage(page));
                    dispatch(loadFlightBaggageStatus());
                }}
            />
            <BaggageInfoDialogAction
                isFlightTeamBoard={!!flightTeamId}
                onSelectPassenger={onSelectPassengerCallback}
                onOpenChange={onOpenChangeCallback}
                openActionRef={dialogOpenActionRef}
                onNotifyClick={onNotifyClickCallback}
            />
            {loadStatus === 'loading' && (
                <Spinner className={styles.spiner} appearance="primary" />
            )}
        </div>
    );
};

export default FlightBaggageStatusPage;
