import { makeStyles, shorthands } from '@fluentui/react-components';

export const useAppConfigurationPageStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    panels: {
        //...shorthands.padding(0, '10px'),
        '& th': {
            textAlign: 'left',
            ...shorthands.padding(0, '30px', 0, 0),
        },
        width: '100%',
        height: '100%',
    },
    tabList: {
        height: '100%',
    },
    tab: {
        '&::after,&::before': {
            left: '0',
            right: '0',
            bottom: '-8px',
        }
    }
});
