import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useConfigureTabPageStyles = makeStyles({
	root: {
		display: 'flex',
		...shorthands.flex(1),
		flexDirection:'column'
	},
	field: {
		display: "grid",
		gridRowGap: tokens.spacingVerticalS,
	},
});