import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    BroadcastMessageWithStatistic,
    IPagination,
    ISorting,
    ODataResult,
} from '../../../model';
import { RootState } from '../..';
import { convertToOdataOptions } from '../../../utils/odata';
import buildQuery, { Filter } from 'odata-query';
import { fetchBroadcastMessagesApiCall } from './broadcastMessageReportAPI';

export interface BroadcastMessageReportFilterState {
    queryText: string;
}

export interface BroadcastMessageReportState {
    items: BroadcastMessageWithStatistic[];
    pagination: IPagination;
    sorting: ISorting;

    loadStatus: 'idle' | 'loading' | 'failed';
    filter: BroadcastMessageReportFilterState;
}

const initialState: BroadcastMessageReportState = {
    loadStatus: 'loading',
    items: [],
    pagination: {
        currentPage: 1,
        pageSize: 10,
        totalCount: 0,
    },
    filter: {
        queryText: '',
    },
    sorting: {
        sortBy: 'createdOnUtc',
        sortByDescending: true,
    },
};

const getBroadcastMessagesWithStatistic = (state: RootState) => {
    const {
        sorting,
        pagination,
        filter: { queryText },
    } = state.broadcastMessageReport;

    const options = convertToOdataOptions<BroadcastMessageWithStatistic>({
        count: pagination.currentPage === 1 && pagination.pageSize > 0,
        pagination: pagination,
        sorting: sorting,
    });

    const andParts: Partial<Filter<BroadcastMessageWithStatistic>>[] = [];

    if (queryText) {
        andParts.push({
            or: [
                { title: { contains: queryText } },
                { content: { contains: queryText } },
            ],
        });
    }

    options.filter = {
        and: [...andParts],
    };

    const query = buildQuery(options);
    return query;
};

export const loadBroadcastMessages = createAsyncThunk<
    ODataResult<BroadcastMessageWithStatistic[]>,
    void,
    { state: RootState }
>('broadcastMessageReport/loadBroadcastMessages', async (_, { getState }) => {
    return await fetchBroadcastMessagesApiCall(
        getBroadcastMessagesWithStatistic(getState())
    );
});

export const broadcastMessageReportSlice = createSlice({
    name: 'broadcastMessageReport',
    initialState,
    reducers: {
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage =
                action.payload < 1 ? 1 : action.payload;
        },
        setSorting: (state, action: PayloadAction<string>) => {
            if (state.sorting.sortBy === action.payload) {
                state.sorting.sortByDescending =
                    !state.sorting.sortByDescending;
            } else {
                state.sorting.sortBy = action.payload;
                state.sorting.sortByDescending = false;
            }
        },
        setLoadStatus: (state, action) => {
            state.loadStatus = action.payload;
        },
        setFilter: (state, action: PayloadAction<BroadcastMessageReportFilterState>) => {
            state.filter = { ...state.filter, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadBroadcastMessages.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(loadBroadcastMessages.fulfilled, (state, action) => {
                state.loadStatus = 'idle';
                state.items = action.payload.value;

                if (action.payload['@odata.count'] !== undefined) {
                    state.pagination.totalCount =
                        action.payload['@odata.count'] ?? 0;
                }
            })
            .addCase(loadBroadcastMessages.rejected, (state) => {
                state.loadStatus = 'failed';
            });
    }
});

export const { setCurrentPage, setSorting, setLoadStatus, setFilter } =
    broadcastMessageReportSlice.actions;

export const broadcastMessageReport = (state: RootState) =>
    state.broadcastMessageReport;
export const broadcastMessageReportFilter = (state: RootState) =>
    state.broadcastMessageReport.filter;

export default broadcastMessageReportSlice.reducer;
