import { makeResetStyles, tokens } from "@fluentui/react-components";


export const useScrollBarStyle = makeResetStyles({
    '::-webkit-scrollbar': {
        height: '1.6rem',
        width: '1.6rem'
    },

    '::-webkit-scrollbar:disabled': {
        display: 'none'
    },
    '::-webkit-scrollbar-thumb': {
        borderBottomRightRadius: '0.9rem',
        borderBottomLeftRadius: '0.9rem',
        borderTopRightRadius: '0.9rem',
        borderTopLeftRadius: '0.9rem',
        borderTopWidth: '0.4rem',
        borderBottomWidth: '0.4rem',
        borderLeftWidth: '0.4rem',
        borderRightWidth: '0.4rem',
        borderTopStyle: 'solid',
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        backgroundClip: 'content-box',
        backgroundColor: tokens.colorScrollbarOverlay
    },
    '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: tokens.colorScrollbarOverlay,
        borderTopWidth: '0.2rem',
        borderBottomWidth: '0.2rem',
        borderLeftWidth: '0.2rem',
        borderRightWidth: '0.2rem',
        borderTopStyle: 'solid',
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent'
    },
    '::-webkit-scrollbar-track': {
        backgroundColor: 'transparent'
    },
    '::-webkit-scrollbar-corner': {
        backgroundColor: 'transparent'
    }
});
