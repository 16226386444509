import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const baggageInfoDialogWidthBreakpoint = 470;

export const useBaggageInfoDialogStyles = makeStyles({
    root: {},
    groupBookingHeader: {
        '& > button': {
            ...shorthands.padding('0px'),
        },
        '& > button[aria-expanded="false"]': {
            ...shorthands.borderBottom(
                '1px',
                'solid',
                tokens.colorNeutralStroke1
            ),
        },
    },
    dialogSurface: {
        maxWidth: 'min(600px, calc(100% - 20px))',
        maxHeight: 'calc(100vh - 100px)',
    },
    dialogContent: {
        ...shorthands.padding('0px', '12px'),
        ...shorthands.margin('0px', '-12px'),
    },
    dialogBody: {
        maxHeight: 'calc(100vh - 148px)',
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        ...shorthands.gap('10px'),
    },
    dialogTitleIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        ...shorthands.borderRadius('3px'),
        backgroundColor: tokens.colorBrandBackgroundStatic,
    },
    dialogTitleCustomerName: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: tokens.fontSizeBase300,
    },
    dialogTitleCustomerInfo: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: tokens.fontSizeBase300,
        ...shorthands.gap('10px'),
    },
    actions: {
        ...shorthands.padding('10px', 0, 0),
        ...shorthands.margin('0px', '0px', '0px'),
    },
});

export const useBaggageInfoContentSkeletonStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('20px'),
    },
    groupBooking: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        ...shorthands.gap('3px'),
    },
    groupBookingBorder: {
        height: '1px',
        gridRowStart: '2',
        gridColumnStart: '1',
        gridColumnEnd: 'span 2',
    },
    bagList: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: '10px',
    },
    bagListTitle: {
        gridColumnStart: '1',
        gridColumnEnd: 'span 2',
    },
    bagReFlightInfo: {
        height: 'auto',
        gridRowStart: '2',
        gridRowEnd: 'span 10',
        gridColumnStart: '2',
        gridColumnEnd: 'span 2',
    },
    bagListItem1: {
        gridRowStart: '2',
        gridColumnStart: '1',
    },
    bagListItem2: {
        gridRowStart: '3',
        gridColumnStart: '1',
    },
});

export const useBagListStyles = makeStyles({
    root: {},
    title: {
        display: 'inline-flex',
        minWidth: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tabListRoot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...shorthands.gap('10px'),
    },
    tabList: {},
    tab: {
        '&.fui-Tab[aria-selected=true], &.fui-Tab[aria-selected=true]:hover, &.fui-Tab[aria-selected=true]:active':
            {
                backgroundColor: tokens.colorBrandBackground,
                '& span.fui-Text': {
                    color: tokens.colorNeutralForegroundOnBrand,
                },
            },
        '&.fui-Tab:hover, &.fui-Tab:active': {
            backgroundColor: tokens.colorTransparentBackground,
            '& span.fui-Text': {
                color: tokens.colorNeutralForeground2,
            },

            '&::before, &::after': {
                content: 'none !important',
            },
        },
        '&::before, &::after': {
            content: 'none !important',
        },
    },
    panel: {
        ...shorthands.flex(1, 1, 'max-content'),
    },
    accordionHeader: {
        '& > button[aria-expanded="true"]': {
            backgroundColor: tokens.colorBrandBackground,
            color: tokens.colorNeutralForegroundOnBrand,
            ...shorthands.borderRadius('8px', '8px', '0', '0'),
        },
    },
    hideExpandIcon: {
        '& .fui-AccordionHeader__expandIcon': {
            visibility: 'hidden',
        },
    },
    bagTag: {
        ...shorthands.padding('0', '30px'),
    },
    accordionPannel: {
        ...shorthands.margin('0'),
        ...shorthands.gap('8px'),
        ...shorthands.borderRadius('0', '0', '8px', '8px'),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.padding('8px', '16px'),
    },
    tabListBagInfo: {
        ...shorthands.borderRadius('8px'),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.padding('8px', '16px'),
        ...shorthands.margin('0', '0', '10px'),
    }
});

export const useBagInfoStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('10px'),        
    },
    dataRow: {
        alignItems: 'end',
    },
    dataRowLabel: {
        maxWidth: '110px',
    },
    dataRowLabelBold: {
        fontWeight: 'bold',
    },
    flight: {
        '&::before': {
            content: 'attr(data-title)',
            fontSize: '10px',
            fontWeight: tokens.fontWeightSemibold,
            color: tokens.colorNeutralForeground3,
        },
        '&:not(:last-child)': {
            ...shorthands.padding(0, 0, '8px'),
            ...shorthands.borderBottom(
                '1px',
                'dashed',
                tokens.colorNeutralStroke1
            ),
        },
    },
});

export const useReflightInfoStyles = makeStyles({
    dataRow: {
        alignItems: 'end',
    },
    dataRowLabel: {
        maxWidth: '130px',
    },
});

export const useDataRowStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...shorthands.gap('10px'),
    },
    dataRowLabel: {},
    dataRowValue: {
        fontWeight: 'bold',
    },
});

export const useGroupBookingTableStyles = makeStyles({
    root: {},
    customerDisplayName: {
        color: tokens.colorBrandForegroundLink,
    },
    ceilContent: {
        display: 'inline-flex',
        flexDirection: 'row',
        ...shorthands.gap('2px'),
    },
    tableRow: {
        cursor: 'pointer',
    },
});
