import { makeResetStyles, tokens } from '@fluentui/react-components';

export const useScrollBarStyle = makeResetStyles({
    '& *::-webkit-scrollbar': {
        width: '1.2rem',
        height: '1.2rem'
    },

    '& *::-webkit-scrollbar:disabled': {
        display: 'none'
    },
    '& *::-webkit-scrollbar-thumb': {
        borderBottomRightRadius: '0.6rem',
        borderBottomLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
        borderTopLeftRadius: '0.6rem',
        borderTopWidth: '0.4rem',
        borderBottomWidth: '0.4rem',
        borderLeftWidth: '0.4rem',
        borderRightWidth: '0.4rem',
        borderTopStyle: 'solid',
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        backgroundClip: 'content-box',
        backgroundColor: tokens.colorScrollbarOverlay
    },
    '& *::-webkit-scrollbar-thumb:hover': {
        backgroundColor: tokens.colorScrollbarOverlay,
        borderTopWidth: '0.35rem',
        borderBottomWidth: '0.35rem',
        borderLeftWidth: '0.35rem',
        borderRightWidth: '0.35rem',
        borderTopStyle: 'solid',
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent'
    },
    '& *::-webkit-scrollbar-track': {
        backgroundColor: 'transparent'
    },
    '& *::-webkit-scrollbar-corner': {
        backgroundColor: 'transparent'
    }
});
