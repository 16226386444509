import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { memo } from 'react';
import {
    columnSizingOptions,
    useFlightBaggageStatusTableStyles,
} from './FlightBaggageStatusTable.styles';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableColumnId,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableRowId,
    Text,
    Tooltip,
    createTableColumn,
    useTableColumnSizing_unstable,
    useTableFeatures,
    useTableSort,
} from '@fluentui/react-components';
import { TFunction } from 'i18next';
import { FlightTeam, ISorting, Passenger } from '../../../model';
import {
    AirplaneRegular,
    AirplaneTakeOffRegular,
    bundleIcon,
    CalendarCheckmarkRegular,
    CaretDownFilled,
    Chat24Filled,
    Chat24Regular,
    NumberRow16Regular,
    NumberSymbolSquareRegular,
    PeopleRegular,
    RibbonStarRegular,
} from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { BaggageSuccessIcon } from '../../Icons/BaggageSuccessIcon';
import { BaggageWarningIcon } from '../../Icons/BaggageWarningIcon';
import {
    formatFlightTeamName,
    formatFlightTime,
} from '../../../utils/formatters';
import { getBaseUrl } from '../../../configVariables';
import { dialog } from '@microsoft/teams-js';
import { useSelector } from '../../../store/hooks';
import { selectConfig } from '../../../store/features/config/configSlice';

interface FlightBaggageStatusTableProps {
    isFlightTeamBoard: boolean;
    items: Passenger[];
    sorting: ISorting;
    onSortingChange: (sorting: ISorting) => void;
    onPassengerSelect?: (passenger: Passenger) => void;
}

const SendMessageIconBundle = bundleIcon(Chat24Filled, Chat24Regular);

const flightTeamBoardColumns = [
    'firstName,lastName',
    'cabin',
    'seatNumber',
    'frequentFlyerTier',
    'actions',
];

const MishandledBaggageTableSection = memo(
    function MishandledBaggageTableSection({
        sectionName,
        headerColSpan,
        rows,
        columns,
        columnSizing,
    }: {
        sectionName: string;
        headerColSpan: number;
        rows: {
            appearance: 'none';
            item: Passenger;
            rowId: TableRowId;
        }[];
        columns: {
            columnId: TableColumnId;
            renderCell: (item: Passenger) => React.ReactNode;
            renderHeaderCell: (data?: unknown) => React.ReactNode;
            compare: (a: Passenger, b: Passenger) => number;
        }[];
        columnSizing: any;
    }) {
        const styles = useFlightBaggageStatusTableStyles();
        const [expanded, setExpanded] = useState(true);
        const caretDownFilledIconRef = useRef<SVGSVGElement>(null);

        const onExpandCallBack = useCallback(() => {
            setExpanded((prev) => !prev);
        }, []);

        useEffect(() => {
            if (caretDownFilledIconRef.current)
                caretDownFilledIconRef.current.style.transform = expanded
                    ? 'rotate(0deg)'
                    : 'rotate(-90deg)';
        }, [expanded]);

        return (
            <>
                <TableRow>
                    <TableCell
                        className={styles.cabinRow}
                        colSpan={headerColSpan}
                        onClick={onExpandCallBack}
                    >
                        <span className={styles.sectionTitle}>
                            <CaretDownFilled
                                fontSize={16}
                                ref={caretDownFilledIconRef}
                            />
                            <Text size={200} weight='semibold'>Cabin:</Text>
                            <Text size={400} weight="bold">
                                {sectionName}
                            </Text>
                        </span>
                    </TableCell>
                </TableRow>
                {rows.map(({ item, appearance, rowId }, index) => (
                    <TableRow
                        key={rowId}
                        appearance={appearance}
                        style={{ display: expanded ? 'flex' : 'none' }}
                    >
                        {columns.map((column) => (
                            <TableCell
                                key={`${item.id}-${column.columnId}`}
                                tabIndex={0}
                                role="gridcell"
                                style={{
                                    ...columnSizing.getTableCellProps(
                                        column.columnId
                                    )?.style,
                                    userSelect: 'none',
                                    maxWidth: 'none'
                                }}
                            >
                                {column.renderCell(item)}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </>
        );
    }
);

const MishandledBaggageTableRows = memo(function MishandledBaggageTableRows({
    rows,
    columns,
    columnSizing,
}: {
    rows: {
        appearance: 'none';
        item: Passenger;
        rowId: TableRowId;
    }[];
    columns: {
        columnId: TableColumnId;
        renderCell: (item: Passenger) => React.ReactNode;
        renderHeaderCell: (data?: unknown) => React.ReactNode;
        compare: (a: Passenger, b: Passenger) => number;
    }[];
    columnSizing: any;
}) {
    const styles = useFlightBaggageStatusTableStyles();

    return <>
        {rows.map(({ item, appearance, rowId }) => (
            <TableRow className={styles.tableRow} key={rowId} appearance={appearance}>
                {columns.map((column) => (
                    <TableCell
                        key={column.columnId}
                        tabIndex={0}
                        role="gridcell"
                        style={{
                            ...columnSizing.getTableCellProps(column.columnId)
                                ?.style,
                            userSelect: 'none',
                            maxWidth: 'none'
                        }}
                    >
                        {column.renderCell(item)}
                    </TableCell>
                ))}
            </TableRow>
        ))}
    </>
});

export const FlightBaggageStatusTable = memo(function FlightBaggageStatusTable({
    isFlightTeamBoard,
    items,
    sorting,
    onSortingChange,
    onPassengerSelect,
}: FlightBaggageStatusTableProps) {
    const { t } = useTranslation();
    const styles = useFlightBaggageStatusTableStyles();
    const { botId } = useSelector(selectConfig);

    const openHelpDialog = useCallback(
        (item: FlightTeam) => {
            const baseUrl = getBaseUrl();

            dialog.url.bot.open({
                size: {
                    width: 500,
                    height: 320,
                },
                title: formatFlightTeamName(item),
                url: `${baseUrl}/help`,
                completionBotId: botId,
            });
        },
        [botId]
    );

    const opendSendMessageToTeamDialog = useCallback(
        (item: FlightTeam) => {
            const baseUrl = getBaseUrl();
        
            dialog.url.bot.open({
                size: {
                    width: 500,
                    height: 250,
                },
                title: formatFlightTeamName(item),
                url: `${baseUrl}/sendMessageToTeam/${item.id}`,
                completionBotId: botId,
            });
        },
        [botId]
    );

    const columns = useMemo(() => {
        const columns = getMishandledBagageTableColumnsConfig(
            t,
            onPassengerSelect ?? (() => { }),
            isFlightTeamBoard ? openHelpDialog : opendSendMessageToTeamDialog
        );

        return isFlightTeamBoard
            ? columns.filter((column) =>
                flightTeamBoardColumns.includes(column.columnId.toString())
            )
            : columns;
    }, [isFlightTeamBoard, onPassengerSelect, openHelpDialog, opendSendMessageToTeamDialog, t]);

    const {
        getRows,
        sort: { getSortDirection },
        columnSizing_unstable: columnSizing,
    } = useTableFeatures(
        {
            getRowId: (row) => row.id,
            columns,
            items,
        },
        [
            useTableSort({
                sortState: {
                    sortColumn: sorting.sortBy,
                    sortDirection: sorting.sortByDescending
                        ? 'ascending'
                        : 'descending',
                },
            }),
            useTableColumnSizing_unstable({
                columnSizingOptions,
            }),
        ]
    );

    const headerSortProps = useCallback(
        (columnId: TableColumnId) => ({
            onClick: () => {
                if (columnId.toString().includes('action')) {
                    return;
                }
                onSortingChange({ ...sorting, sortBy: columnId.toString() });
            },
            sortDirection: columnId.toString().includes('action')
                ? undefined
                : getSortDirection(columnId),
        }),
        [getSortDirection, onSortingChange, sorting]
    );

    const rows = getRows((row) => {
        return {
            ...row,
            appearance: 'none' as const,
        };
    });

    const groupedByCabinDataRows = isFlightTeamBoard
        ? groupRowsByCabin(rows)
        : undefined;

    return (
        <Table
            className={styles.table}
            size="small"
            role="grid"
            aria-label="FlightBaggageStatusTable"
            noNativeElements
            sortable
        >
            <TableHeader>
                <TableRow className={styles.tableRow}>
                    {columns.map((column) => (
                        <TableHeaderCell
                            key={column.columnId}
                            className={
                                column.columnId.toString().endsWith('action')
                                    ? styles.actionCell
                                    : ''
                            }
                            style={{
                                ...columnSizing.getTableHeaderCellProps(
                                    column.columnId
                                )?.style,
                                userSelect: 'none',
                                maxWidth: 'none'
                            }}
                            {...headerSortProps(column.columnId)}
                        >
                            {column.renderHeaderCell()}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {isFlightTeamBoard ? (
                    groupedByCabinDataRows &&
                    groupedByCabinDataRows.map(([cabin, rows]) => (
                        <MishandledBaggageTableSection
                            key={cabin}
                            sectionName={cabin}
                            rows={rows}
                            headerColSpan={columns.length}
                            columns={columns}
                            columnSizing={columnSizing}
                        />
                    ))
                ) : (
                    <MishandledBaggageTableRows
                        rows={rows}
                        columns={columns}
                        columnSizing={columnSizing}
                    />
                )}
            </TableBody>
        </Table>
    );
});

export default FlightBaggageStatusTable;


function groupRowsByCabin(
    rows: {
        appearance: 'none';
        item: Passenger;
        rowId: TableRowId;
    }[],
    sortDirection: 'ascending' | 'descending' = 'ascending'
): [string, typeof rows][] {
    const groupedRows = rows.reduce((acc, row) => {
        const cabin = row.item.cabin;
        if (!acc[cabin]) {
            acc[cabin] = [];
        }
        acc[cabin].push(row);
        return acc;
    }, {} as Record<string, typeof rows>);

    if (sortDirection === 'descending') {
        return Object.entries(groupedRows).sort(([a], [b]) =>
            a > b ? -1 : a < b ? 1 : 0
        );
    }

    return Object.entries(groupedRows).sort(([a], [b]) =>
        a < b ? -1 : a > b ? 1 : 0
    );
}

function getMishandledBagageTableColumnsConfig(
    t: TFunction<'translation', undefined>,
    onPassengerSelected: (passenger: Passenger) => void,
    opendSendMessageToTeamDialog: (
        item: FlightTeam,
        useSingleKey: boolean
    ) => void
) {
    return [
        createTableColumn<Passenger>({
            columnId: 'firstName,lastName',
            renderHeaderCell: () => {
                return (
                    <TableCellLayout media={<PeopleRegular />}>
                        {t('TableHeader_Customer_DisplayName')}
                    </TableCellLayout>
                );
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>{`${item.firstName} ${item.lastName}`}</TableCellLayout>
                );
            },
        }),
        createTableColumn<Passenger>({
            columnId: 'flightTeam/iataFlightIdentifier',
            renderHeaderCell: () => {
                return (
                    <>
                        <NumberRow16Regular />
                        {t('TableHeader_Flight')}
                    </>
                );
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.flightTeam?.iataFlightIdentifier}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Passenger>({
            columnId:
                'flightTeam/departureAirportIata,flightTeam/arrivalAirportIata',
            renderHeaderCell: () => {
                return (
                    <>
                        <AirplaneRegular />
                        {t('TableHeader_Airport')}
                    </>
                );
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>{`${item.flightTeam?.departureAirportIata} - ${item.flightTeam?.arrivalAirportIata}`}</TableCellLayout>
                );
            },
        }),
        createTableColumn<Passenger>({
            columnId: 'flightTeam/scheduledDepartureTimeUtc',
            renderHeaderCell: () => {
                return (
                    <>
                        <AirplaneTakeOffRegular />
                        {t('TableHeader_DepartureTime')}
                    </>
                );
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {`${
                            item.flightTeam?.scheduledDepartureTime
                                ? formatFlightTime(
                                      item.flightTeam.scheduledDepartureTime
                                  )
                                : formatFlightTime(
                                      item.flightTeam
                                          ?.scheduledDepartureTimeUtc,
                                      true
                                  )
                        }`}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Passenger>({
            columnId: 'flightTeam/scheduledArrivalTimeUtc',
            renderHeaderCell: () => {
                return (
                    <>
                        <CalendarCheckmarkRegular />
                        {t('TableHeader_ArrivalTime')}
                    </>
                );
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>{`${
                        item.flightTeam?.scheduledArrivalTime
                            ? formatFlightTime(
                                  item.flightTeam?.scheduledArrivalTime
                              )
                            : formatFlightTime(
                                  item.flightTeam?.scheduledArrivalTimeUtc,
                                  true
                              )
                    }`}</TableCellLayout>
                );
            },
        }),
        createTableColumn<Passenger>({
            columnId: 'seatNumber',
            renderHeaderCell: () => {
                return (
                    <TableCellLayout media={<NumberSymbolSquareRegular />}>
                        {t('TableHeader_Passenger_SeatNumber')}
                    </TableCellLayout>
                );
            },
            renderCell: (item) => {
                return <TableCellLayout>{item.seatNumber}</TableCellLayout>;
            },
        }),
        createTableColumn<Passenger>({
            columnId: 'frequentFlyerTier',
            renderHeaderCell: () => {
                return (
                    <TableCellLayout media={<RibbonStarRegular />}>
                        {t('TableHeader_Passenger_LoyaltyLevel')}
                    </TableCellLayout>
                );
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>{item.frequentFlyerTier}</TableCellLayout>
                );
            },
        }),
        createTableColumn<Passenger>({
            columnId: 'actions',
            renderHeaderCell: () => {
                return <></>;
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <Tooltip
                            content={t('Button_ViewDetails_Tooltip')!}
                            relationship="label"
                            withArrow
                        >
                            <Button
                                style={{ marginRight: '20px' }}
                                appearance="transparent"
                                icon={
                                    item.bags?.every(
                                        (b) => !!b.notifiedOnUtc
                                    ) ? (
                                        <BaggageSuccessIcon interactive />
                                    ) : (
                                        <BaggageWarningIcon interactive />
                                    )
                                }
                                onClick={() => onPassengerSelected(item)}
                            />
                        </Tooltip>
                        <Tooltip
                            content={t('Button_SendMessageToTeam_Tooltip')!}
                            relationship="label"
                            withArrow
                        >
                            <Button
                                appearance="transparent"
                                icon={<SendMessageIconBundle />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    opendSendMessageToTeamDialog(
                                        item.flightTeam!,
                                        true
                                    );
                                }}
                            />
                        </Tooltip>
                    </TableCellLayout>
                );
            },
        }),
    ];
}
