import React, { useEffect, useMemo } from "react";
import { app, authentication } from "@microsoft/teams-js";

import { Dropdown, Textarea, Button, Option, Label, Spinner } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useSelector } from "../../store/hooks";
import {
  getConnectToTeams,
  selectHelp,
  selectTeam,
  setIsSubmitting,
  updateMessage,
} from "../../store/features/help/helpSlice";

import { submitDialog } from "../../utils/teams";
import { useHelpPageStyles } from "./HelpPage.styles";
import { IBotSubmitPayload } from "../../model/IBotSubmitPayload";
import IBrokerConnectionHelpData from "../../model/brokerConnections/IBrokerConnectionFlightHelpData";
import BotSubmitAction from "../../model/BotSubmitAction";

const HelpPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const styles = useHelpPageStyles();

  const {
    connectToTeamsStatus,
    connectToTeams,
    selectedConnectToTeam,
    message,
    isSubmitting
  } = useSelector(selectHelp);


  useEffect(() => {
    dispatch(getConnectToTeams());
  }, [dispatch]);

  useEffect(() => {
    if (connectToTeamsStatus === 'idle') {
      app.notifySuccess();
    }
  }, [connectToTeamsStatus]);

  const isSubmitDisable = useMemo(() => {
    const disable = isSubmitting ||
      !selectedConnectToTeam;
    if (disable) {
      return true;
    }
    const text = message?.trim() || '';
    return text.length === 0 || text.length > 4000
  }, [isSubmitting, selectedConnectToTeam, message]);


  return <div className={styles.root}>
    <div className={styles.formField}>
      <Label weight="semibold" required>{t('ConnectTo')}</Label>
      <Dropdown
        value={selectedConnectToTeam?.title || ''}
        defaultValue={connectToTeams[0]?.key}
        disabled={connectToTeamsStatus !== 'idle'}
        placeholder={t('SelectTeamPlaceholder')!}
        onOptionSelect={(_, data) => {
          dispatch(selectTeam(data.optionValue));
        }}
      >
        {connectToTeams.map((i, index) => {
          return <Option key={i.key} value={i.key} text={i.title}>
            {i.title}
          </Option>
        })}
      </Dropdown>
    </div>
    <div className={styles.formField}>
      <Label weight="semibold" required>{t('Message')}</Label>
      <Textarea
        rows={5}
        className={styles.textArea}
        value={message}
        onChange={(_, data) => {
          dispatch(updateMessage(data?.value));
        }}
      >
      </Textarea>
    </div>
    
    <div className={styles.actions}>
      <Button
        iconPosition="before"
        icon={isSubmitting ? <Spinner size="tiny" /> : undefined}
        appearance="primary"
        disabled={isSubmitDisable || isSubmitting}
        onClick={async () => {
          if (isSubmitting) {
            return;
          }
          const token = await authentication.getAuthToken();
          dispatch(setIsSubmitting(true));
          const text = message?.trim() || '';
          const submitRequest: IBotSubmitPayload<IBrokerConnectionHelpData> = {
            data: {
              connectTo: selectedConnectToTeam!,
              message: text!,
            },
            appUserAccessToken: token,
            action: BotSubmitAction.BrokerConnectionFlightHelp,
          };

          submitDialog(submitRequest);

        }}
      >
        {t('Submit')}
      </Button>
    </div>
  </div>;
};



export default HelpPage;