import { app } from '@microsoft/teams-js';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useBroadcastMessageReportPageStyles } from './BroadcastMessageReportPage.styles';
import {
    Button,
    createTableColumn,
    Input,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarGroup,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableColumnDefinition,
    TableColumnId,
    TableColumnSizingOptions,
    TableHeader,
    TableHeaderCell,
    TableRow,
    Toolbar,
    ToolbarGroup,
    Tooltip,
    useTableColumnSizing_unstable,
    useTableFeatures,
    useTableSort,
} from '@fluentui/react-components';
import { useToolbarStyles } from '../../controls';
import {
    ArrowDownload24Filled,
    ArrowDownload24Regular,
    ArrowSyncCircle24Regular,
    bundleIcon,
    CalendarCheckmarkRegular,
    ChatRegular,
    CheckmarkCircle24Regular,
    Dismiss24Regular,
    DismissCircle24Regular,
    DismissRegular,
    Eye24Regular,
    Search24Regular,
    TextTRegular,
    TextUnderlineDoubleRegular,
} from '@fluentui/react-icons';
import { t } from 'i18next';
import { BroadcastMessageWithStatistic, ReportType } from '../../model';
import { useSelector } from 'react-redux';
import {
    broadcastMessageReport,
    broadcastMessageReportFilter,
    loadBroadcastMessages,
    setCurrentPage,
    setFilter,
    setSorting,
} from '../../store/features/broadcastMessageReport/broadcastMessageReportSlice';
import { useAppDispatch } from '../../store/hooks';
import { useDebouncedCallback } from 'use-debounce';
import Pagination from '../Pagination';
import { formatDateUtc } from '../../i18n';
import { createReport } from '../../store/features/reporting/reportingAPI';

const columnSizingOptions: TableColumnSizingOptions = {
    title: {
        minWidth: 120,
    },
    content: {
        minWidth: 230,
        autoFitColumns: true,
    },
    createdOnUtc: {
        minWidth: 100,
    },
    flightsBroadcast: {
        minWidth: 300,
        autoFitColumns: true,
    },
};

const ArrowDownloadIconBundle = bundleIcon(
    ArrowDownload24Filled,
    ArrowDownload24Regular
);

const BroadcastMessageReportPage = () => {
    const dispatch = useAppDispatch();
    const styles = useBroadcastMessageReportPageStyles();
    const toolbarStyles = useToolbarStyles();

    const { items, pagination, sorting, loadStatus } = useSelector(
        broadcastMessageReport
    );
    const { queryText } = useSelector(broadcastMessageReportFilter);

    const [searchQuery, setSearchQuery] = React.useState<string>('');

    const [
        sentBroadcastMessageReportRequests,
        setSentBroadcastMessageReportRequests,
    ] = React.useState<string[]>([]);

    const onSearchQueryUpdateCallback = useDebouncedCallback(
        (query: string) => {
            dispatch(
                setFilter({
                    queryText: query,
                })
            );
            dispatch(loadBroadcastMessages());
        },
        300
    );

    const onClearSearchTextClick = useCallback(() => {
        if (!!queryText) {
            onSearchQueryUpdateCallback.cancel();
            dispatch(
                setFilter({
                    queryText: '',
                })
            );
            dispatch(setCurrentPage(1));
            dispatch(loadBroadcastMessages());
        }
    }, [dispatch, onSearchQueryUpdateCallback, queryText]);

    useEffect(() => {
        app.notifySuccess();
    }, []);

    useEffect(() => {
        dispatch(loadBroadcastMessages());
    }, [dispatch]);

    useEffect(() => {
        onSearchQueryUpdateCallback(searchQuery);
    }, [searchQuery, onSearchQueryUpdateCallback]);

    const dismissMessage = (broadcastMessageId: string) =>
        setSentBroadcastMessageReportRequests((prev) =>
            prev.filter((x) => x !== broadcastMessageId)
        );

    const generateReportCallback = useCallback(
        async (broadcastMessageId: string) => {
            await createReport({
                type: ReportType.BroadcastMessagingReport,
                broadcastMessageId,
            });

            setSentBroadcastMessageReportRequests((prev) => [
                ...prev,
                broadcastMessageId,
            ]);

            setTimeout(() => {
                dismissMessage(broadcastMessageId);
            }, 3000);
        },
        []
    );

    const columns = useMemo(
        () =>
            [
                createTableColumn({
                    columnId: 'title',
                    renderHeaderCell: () => {
                        return (
                            <TableCellLayout media={<TextTRegular />}>
                                Title
                            </TableCellLayout>
                        );
                    },
                    renderCell: (item) => {
                        return <TableCellLayout>{item.title}</TableCellLayout>;
                    },
                }),
                createTableColumn({
                    columnId: 'content',
                    renderHeaderCell: () => {
                        return (
                            <TableCellLayout
                                media={<TextUnderlineDoubleRegular />}
                            >
                                Description
                            </TableCellLayout>
                        );
                    },
                    renderCell: (item) => {
                        return (
                            <TableCellLayout>{item.content}</TableCellLayout>
                        );
                    },
                }),
                createTableColumn({
                    columnId: 'createdOnUtc',
                    renderHeaderCell: () => {
                        return (
                            <TableCellLayout
                                media={<CalendarCheckmarkRegular />}
                            >
                                Date/Time
                            </TableCellLayout>
                        );
                    },
                    renderCell: (item) => {
                        return (
                            <TableCellLayout>
                                {formatDateUtc(
                                    item.createdOnUtc,
                                    'DD/MM/YY HH:mm'
                                )}
                            </TableCellLayout>
                        );
                    },
                }),
                createTableColumn({
                    columnId: 'flightsBroadcast',
                    renderHeaderCell: () => {
                        return (
                            <TableCellLayout media={<ChatRegular />}>
                                Flights Broadcast
                            </TableCellLayout>
                        );
                    },
                    renderCell: (item) => {
                        return (
                            <div className={styles.flightsBroadcast}>
                                <Tooltip
                                    content={'Pending sending'}
                                    withArrow
                                    relationship="description"
                                >
                                    <TableCellLayout
                                        media={
                                            <ArrowSyncCircle24Regular
                                                className={
                                                    styles.pendingSendingIcon
                                                }
                                            />
                                        }
                                    >
                                        {item.pendingSendingCount.toString()}
                                    </TableCellLayout>
                                </Tooltip>
                                <Tooltip
                                    content={'Successfully delivered'}
                                    withArrow
                                    relationship="description"
                                >
                                    <TableCellLayout
                                        media={
                                            <CheckmarkCircle24Regular
                                                className={
                                                    styles.successfullySentIcon
                                                }
                                            />
                                        }
                                    >
                                        {item.successfullySentCount.toString()}
                                    </TableCellLayout>
                                </Tooltip>
                                <Tooltip
                                    content={'Failed'}
                                    withArrow
                                    relationship="description"
                                >
                                    <TableCellLayout
                                        media={
                                            <DismissCircle24Regular
                                                className={
                                                    styles.sendFailedIcon
                                                }
                                            />
                                        }
                                    >
                                        {item.sendFailedCount.toString()}
                                    </TableCellLayout>
                                </Tooltip>
                                <Tooltip
                                    content={'Already seen'}
                                    withArrow
                                    relationship="description"
                                >
                                    <TableCellLayout
                                        media={
                                            <Eye24Regular
                                                className={
                                                    styles.acknowledgedIcon
                                                }
                                            />
                                        }
                                    >
                                        {item.acknowledgedCount.toString()}
                                    </TableCellLayout>
                                </Tooltip>
                            </div>
                        );
                    },
                }),
                createTableColumn({
                    columnId: 'actions',
                    renderHeaderCell: () => {
                        return <></>;
                    },
                    renderCell: (item) => {
                        return (
                            <TableCellLayout>
                                <Tooltip
                                    content={'Generate report'}
                                    relationship="label"
                                    withArrow
                                >
                                    <Button
                                        appearance="transparent"
                                        icon={<ArrowDownloadIconBundle />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            generateReportCallback(item.id);
                                        }}
                                    />
                                </Tooltip>
                            </TableCellLayout>
                        );
                    },
                }),
            ] as TableColumnDefinition<BroadcastMessageWithStatistic>[],
        [
            generateReportCallback,
            styles.acknowledgedIcon,
            styles.flightsBroadcast,
            styles.pendingSendingIcon,
            styles.sendFailedIcon,
            styles.successfullySentIcon,
        ]
    );

    const {
        getRows,
        sort: { getSortDirection },
        columnSizing_unstable: columnSizing,
    } = useTableFeatures(
        {
            getRowId: (item) => item.id,
            columns,
            items: items ?? [],
        },
        [
            useTableSort({
                sortState: {
                    sortColumn: sorting.sortBy,
                    sortDirection: sorting.sortByDescending
                        ? 'ascending'
                        : 'descending',
                },
            }),
            useTableColumnSizing_unstable({
                columnSizingOptions,
            }),
        ]
    );

    const headerSortProps = useCallback(
        (columnId: TableColumnId) => ({
            onClick: () => {
                if (columnId === 'actions') {
                    return;
                }
                dispatch(setSorting(columnId as string));
                dispatch(loadBroadcastMessages());
            },
            sortDirection: getSortDirection(columnId),
        }),
        [getSortDirection, dispatch]
    );

    const rows = getRows();

    return (
        <div className={styles.root}>
            <Toolbar aria-label="Flights" className={toolbarStyles.toolbar}>
                <ToolbarGroup role="presentation"></ToolbarGroup>
                <ToolbarGroup role="presentation">
                    <Input
                        className={toolbarStyles.searchBar}
                        placeholder={t('SearchFlightsInputPlaceholder')!}
                        size="small"
                        contentAfter={
                            <Button
                                appearance="transparent"
                                onClick={onClearSearchTextClick}
                                icon={
                                    !queryText ? (
                                        <Search24Regular />
                                    ) : (
                                        <Dismiss24Regular />
                                    )
                                }
                            />
                        }
                        onChange={(_, data) => {
                            setSearchQuery(data.value ?? '');
                        }}
                        value={searchQuery}
                    />
                </ToolbarGroup>
            </Toolbar>
            <MessageBarGroup
                animate="both"
                className={styles.messageGroup}
            >
                {sentBroadcastMessageReportRequests.map(
                    (broadcastMessageId) => (
                        <MessageBar key={broadcastMessageId} intent="info" layout='multiline'>
                            <MessageBarBody>
                                {t('NotificationMessage_OnBroadcastMessageReportRequest')}
                            </MessageBarBody>
                            <MessageBarActions
                                containerAction={
                                    <Button
                                        onClick={() =>
                                            dismissMessage(broadcastMessageId)
                                        }
                                        aria-label="dismiss"
                                        appearance="transparent"
                                        icon={<DismissRegular />}
                                    />
                                }
                            />
                        </MessageBar>
                    )
                )}
            </MessageBarGroup>
            <Table
                className={styles.table}
                size="small"
                role="grid"
                aria-label="broadcastMessages"
                noNativeElements
                sortable
            >
                <TableHeader>
                    <TableRow className={styles.tableRow}>
                        {columns.map((column) => (
                            <TableHeaderCell
                                key={column.columnId}
                                style={
                                    columnSizing.getTableHeaderCellProps(
                                        column.columnId
                                    )?.style
                                }
                                {...headerSortProps(column.columnId)}
                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map(({ item }) => (
                        <TableRow className={styles.tableRow} key={item.id}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.columnId}
                                    tabIndex={0}
                                    role="gridcell"
                                    style={{
                                        ...columnSizing.getTableCellProps(
                                            column.columnId
                                        )?.style,
                                        justifyContent: 'center',
                                    }}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination
                currentPage={pagination.currentPage}
                pageSize={pagination.pageSize}
                totalItems={pagination.totalCount}
                onChange={(page) => {
                    onSearchQueryUpdateCallback.cancel();
                    dispatch(setCurrentPage(page));
                    dispatch(loadBroadcastMessages());
                }}
            />
            {loadStatus === 'loading' && (
                <Spinner className={styles.spiner} appearance="primary" />
            )}
        </div>
    );
};

export default BroadcastMessageReportPage;
