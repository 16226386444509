import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import {
    IPagination,
    ISorting,
    FlightTeam,
    FlightDepartureStatus,
} from '../../../model';
import { fetchDepartureBoardFlights } from './departureBoardAPI';
import buildQuery, { Filter } from 'odata-query';
import { ODataResult } from '../../../model/odata';
import { convertToOdataOptions } from '../../../utils/odata';

export enum FlightCheckedInStatus {
    Yes = 'Yes',
    No = 'No',
}

export enum DelayedBaggageStatus {
    NotAvailable = 'NotAvailable',
    AllNotified = 'AllNotified',
    OutstandingActions = 'OutstandingActions',
}

export interface DepartureBoardFilterState {
    open: boolean;
    queryText: string;
    departureStatuses: { [k: string | FlightDepartureStatus]: boolean };
    arrivalAirports: { [k: string]: boolean };
    departureAirports: { [k: string]: boolean };
    flightsCheckedInStatus: FlightCheckedInStatus | '';
    delayedBaggageStatus: DelayedBaggageStatus | '';
}

export interface DepartureBoardState {
    items: FlightTeam[];
    pagination: IPagination;
    sorting: ISorting;

    loadStatus: 'idle' | 'loading' | 'failed';
    filter: DepartureBoardFilterState;
}

const initialState: DepartureBoardState = {
    loadStatus: 'loading',
    items: [],
    pagination: {
        currentPage: 1,
        pageSize: 0,
        totalCount: 0,
    },
    filter: {
        queryText: '',
        open: false,
        departureStatuses: {},
        arrivalAirports: {},
        departureAirports: {},
        flightsCheckedInStatus: '',
        delayedBaggageStatus: '',
    },
    sorting: {
        sortBy: 'scheduledDepartureTimeUtc',
        sortByDescending: false,
    },
};

export const loadDepartureBoardFlights = createAsyncThunk<
    ODataResult<FlightTeam[]>,
    void,
    { state: RootState }
>('departureBoard/loadFlights', async (_, { getState }) => {
    const { departureBoard } = getState();

    const options = convertToOdataOptions<FlightTeam>({
        count:
            departureBoard.pagination.currentPage === 1 &&
            departureBoard.pagination.pageSize > 0,
        pagination: departureBoard.pagination,
        sorting: departureBoard.sorting,
    });

    options.expand = 'checkIns,bags($select=id,notifiedOnUtc)';

    const andParts:Partial<Filter<FlightTeam>>[] = [];

    if (departureBoard.filter.flightsCheckedInStatus === 'No') {
        andParts.push({ 'checkIns/$count': { le: 0 } });
    } else if (departureBoard.filter.flightsCheckedInStatus === 'Yes') {
        andParts.push({ 'checkIns/$count': { gt: 0 } });
    }

    if (
        departureBoard.filter.delayedBaggageStatus ===
        DelayedBaggageStatus.AllNotified
    ) {
        andParts.push({
            bags: {
                all: {
                    notifiedOnUtc: {
                        ne: null,
                    },
                },
            },
        });
    } else if (
        departureBoard.filter.delayedBaggageStatus ===
        DelayedBaggageStatus.NotAvailable
    ) {
        andParts.push({
            not: {
                bags: {
                    any: {},
                }
            }
        });
    } else if (
        departureBoard.filter.delayedBaggageStatus ===
        DelayedBaggageStatus.OutstandingActions
    ) {
        andParts.push({
            bags: {
                any: {
                    notifiedOnUtc: {
                        eq: null,
                    },
                },
            },
        });
    }

    if (!!departureBoard.filter.queryText) {
        andParts.push({
            or: [
                {
                    iataFlightIdentifier: {
                        contains: departureBoard.filter.queryText,
                    },
                },
                {
                    departureAirportIata: {
                        contains: departureBoard.filter.queryText,
                    },
                },
                {
                    arrivalAirportIata: {
                        contains: departureBoard.filter.queryText,
                    },
                },
            ],
        });
    }

    const departureStatuses = Object.keys(
        departureBoard.filter.departureStatuses
    ).filter((k) => departureBoard.filter.departureStatuses[k]);
    if (departureStatuses.length) {
        andParts.push({
            or: [
                ...departureStatuses.map((status) => {
                    return { departureStatus: status };
                }),
            ],
        });
    }

    const departureAirports = Object.keys(
        departureBoard.filter.departureAirports
    ).filter((k) => departureBoard.filter.departureAirports[k]);
    if (departureAirports.length) {
        andParts.push({
            or: [
                ...departureAirports.map((airport) => {
                    return { departureAirportIata: airport };
                }),
            ],
        });
    }

    const arrivalAirports = Object.keys(
        departureBoard.filter.arrivalAirports
    ).filter((k) => departureBoard.filter.arrivalAirports[k]);
    if (arrivalAirports.length) {
        andParts.push({
            or: [
                ...arrivalAirports.map((airport) => {
                    return { arrivalAirportIata: airport };
                }),
            ],
        });
    }

    options.filter = {
        and: [...andParts],
    };
    const query = buildQuery(options);
    return await fetchDepartureBoardFlights(query);
});

export const loadDepartureBoardDepartureAirportsFilter = createAsyncThunk<
    string[],
    void,
    { state: RootState }
>('departureBoard/loadDepartureAirportsFilter', async () => {
    const result = await fetchDepartureBoardFlights(
        '?$apply=groupby((DepartureAirportIata))'
    );
    return result.value.map((v) => v.departureAirportIata);
});

export const loadDepartureBoardArrivalAirportsFilter = createAsyncThunk<
    string[],
    void,
    { state: RootState }
>('departureBoard/loadArrivalAirportsFilter', async () => {
    const result = await fetchDepartureBoardFlights(
        '?$apply=groupby((ArrivalAirportIata))'
    );
    return result.value.map((v) => v.arrivalAirportIata);
});

export const departureBoardSlice = createSlice({
    name: 'departureBoard',
    initialState,
    reducers: {
        toggleSort: (state, action: PayloadAction<string>) => {
            if (state.sorting.sortBy === action.payload) {
                state.sorting.sortByDescending =
                    !state.sorting.sortByDescending;
            } else {
                state.sorting.sortBy = action.payload;
                state.sorting.sortByDescending = false;
            }
        },
        showHideFitler: (state, action: PayloadAction<boolean>) => {
            state.filter.open = action.payload;
        },
        setDepartureStatusFilter: (
            state,
            action: PayloadAction<{
                value: FlightDepartureStatus;
                checked: boolean;
            }>
        ) => {
            state.filter.departureStatuses[action.payload.value] =
                action.payload.checked;
        },
        setDepartureAirportFilter: (
            state,
            action: PayloadAction<{ value: string; checked: boolean }>
        ) => {
            state.filter.departureAirports[action.payload.value] =
                action.payload.checked;
        },
        setArrivalAirportFilter: (
            state,
            action: PayloadAction<{ value: string; checked: boolean }>
        ) => {
            state.filter.arrivalAirports[action.payload.value] =
                action.payload.checked;
        },
        setCheckInStatusFilter: (
            state,
            action: PayloadAction<FlightCheckedInStatus>
        ) => {
            state.filter.flightsCheckedInStatus = action.payload;
        },
        setDelayedBaggageStatusFilter: (
            state,
            action: PayloadAction<DelayedBaggageStatus>
        ) => {
            state.filter.delayedBaggageStatus = action.payload;
        },
        setQueryText: (state, action: PayloadAction<string>) => {
            state.filter.queryText = action.payload ?? '';
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage =
                action.payload < 1 ? 1 : action.payload;
        },
        resetFilters: (state) => {
            Object.keys(state.filter.departureStatuses).forEach((key) => {
                state.filter.departureStatuses[key] = false;
            });

            Object.keys(state.filter.departureAirports).forEach((key) => {
                state.filter.departureAirports[key] = false;
            });

            Object.keys(state.filter.arrivalAirports).forEach((key) => {
                state.filter.arrivalAirports[key] = false;
            });

            state.filter.flightsCheckedInStatus = '';
            state.filter.delayedBaggageStatus = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadDepartureBoardFlights.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(loadDepartureBoardFlights.fulfilled, (state, action) => {
                state.loadStatus = 'idle';
                state.items = action.payload.value;
                if (action.payload['@odata.count'] !== undefined) {
                    state.pagination.totalCount =
                        action.payload['@odata.count'];
                }
            })
            .addCase(loadDepartureBoardFlights.rejected, (state) => {
                state.loadStatus = 'failed';
            })
            .addCase(
                loadDepartureBoardArrivalAirportsFilter.fulfilled,
                (state, action) => {
                    action.payload.forEach((airport) => {
                        if (
                            !state.filter.arrivalAirports.hasOwnProperty(
                                airport
                            )
                        ) {
                            state.filter.arrivalAirports[airport] = false;
                        }
                    });
                }
            )
            .addCase(
                loadDepartureBoardDepartureAirportsFilter.fulfilled,
                (state, action) => {
                    action.payload.forEach((airport) => {
                        if (
                            !state.filter.departureAirports.hasOwnProperty(
                                airport
                            )
                        ) {
                            state.filter.departureAirports[airport] = false;
                        }
                    });
                }
            );
    },
});

export const {
    toggleSort,
    setQueryText,
    showHideFitler,
    setDepartureStatusFilter,
    setArrivalAirportFilter,
    setDepartureAirportFilter,
    setCheckInStatusFilter,
    setDelayedBaggageStatusFilter,
    setCurrentPage,
    resetFilters,
} = departureBoardSlice.actions;

export const selectDepartureBoard = (state: RootState) => state.departureBoard;
export const selectDepartureBoardFilter = (state: RootState) =>
    state.departureBoard.filter;

export default departureBoardSlice.reducer;
