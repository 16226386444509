import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { app, authentication, dialog } from "@microsoft/teams-js";

import {
  Textarea,
  Button,
  Label,
  Spinner,
  Caption1Strong
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useSelector } from "../../store/hooks";

import { submitDialog } from "../../utils/teams";
import { useSendPassengersMessageToTeamPageStyles } from "./SendPassengersMessageToTeamPage.styles";
import { IBotSubmitPayload } from "../../model/IBotSubmitPayload";
import BotSubmitAction from "../../model/BotSubmitAction";
import { useParams, useSearchParams } from "react-router-dom";
import {
  selectSendPassengersMessageToTeam,
  setIsSubmitting,
  updateMessage,
  loadPassengers
} from "../../store/features/sendPassengersMessageToTeam/sendPassengersMessageToTeamSlice";
import PassengersList from "../../controls/passengersList";
import useElementSize from "../../hooks/useElementSize";
import { IBrokerConnectionSendPassengersMessageToFlightTeamData } from "../../model/brokerConnections/IBrokerConnectionSendPassengersMessageToFlightTeamData";

const SendPassengersMessageToTeamPage = () => {
  const { flightTeamId } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const styles = useSendPassengersMessageToTeamPageStyles();

  const {
    message,
    isSubmitting,
    passengers
  } = useSelector(selectSendPassengersMessageToTeam);

  useEffect(() => {
    const key = searchParams.get("key");
    if (!!key) {
      dispatch(loadPassengers(key));
    }
    app.notifySuccess();
  }, [dispatch, searchParams]);

  const isSubmitDisable = useMemo(() => {
    const disable = isSubmitting;
    if (disable) {
      return true;
    }
    const text = message?.trim() || '';
    return passengers.length === 0
      || passengers.length > 10
      || text.length === 0
      || text.length > 4000
  }, [isSubmitting, message, passengers]);

  const { height = 0, ref } = useElementSize<HTMLDivElement>();

  useLayoutEffect(() => {
    if (app.getFrameContext() && app.getFrameContext() !== 'settings') {
      const heightToSet = height > 228 ? Math.ceil(height + 32) : 250;
      const widthToSet = 500;
      dialog.update.resize({
        height: heightToSet,
        width: widthToSet,
      });
    }
  }, [height]);


  return <div className={styles.root} ref={ref}>
    <PassengersList className={styles.formField} passengers={passengers} />
    <div className={styles.formField}>
      <Label required>{t('AskOrTellTeamAboutPassengers')}</Label>
      <Textarea
        rows={5}
        className={styles.textArea}
        value={message}
        onChange={(_, data) => {
          dispatch(updateMessage(data?.value));
        }}
      >
      </Textarea>
      <Label size="small" className={styles.warning}>{t('AskOrTellTeamAboutPassengersWarning')}</Label>
    </div>
    <div className={styles.actions}>
      {passengers.length > 10 && <Caption1Strong className={styles.error}>{t('Passengers_Limit_ErrorMessage')}</Caption1Strong>}
      <Button
        iconPosition="before"
        icon={isSubmitting ? <Spinner size="tiny" /> : undefined}
        appearance="primary"
        disabled={isSubmitDisable || isSubmitting}
        onClick={async () => {
          if (isSubmitting) {
            return;
          }
          dispatch(setIsSubmitting(true));
          const token = await authentication.getAuthToken();

          const text = message?.trim() || '';
          const submitRequest: IBotSubmitPayload<IBrokerConnectionSendPassengersMessageToFlightTeamData> = {
            data: {
              flightTeamId: flightTeamId!,
              message: text!,
              passengers: passengers
            },
            appUserAccessToken: token,
            action: BotSubmitAction.BrokerConnectionSendPassengersMessageToFlightTeam,
          };
          submitDialog(submitRequest);
          dialog.url.parentCommunication.sendMessageToParentFromDialog('Submitted');
        }}
      >
        {t('Submit')}
      </Button>
    </div>
  </div >;
};



export default SendPassengersMessageToTeamPage;