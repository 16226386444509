import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBroadcastMessagingDialogPageStyles } from './BroadcastMessagingDialogPage.styles';
import { app, authentication } from '@microsoft/teams-js';
import BotSubmitAction from '../../model/BotSubmitAction';
import { IBotSubmitPayload } from '../../model/IBotSubmitPayload';
import { submitDialog } from '../../utils/teams';
import {
    Button,
    Input,
    Label,
    Spinner,
    Textarea,
} from '@fluentui/react-components';
import { BroadcastMessageData, BroadcastRequestSource } from '../../model';

const BroadcastMessagingDialogPage = () => {
    const { dataKey } = useParams();

    const { t } = useTranslation();
    const styles = useBroadcastMessagingDialogPageStyles();

    const [ids, setIds] = useState('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [messageTitle, setMessageTitle] = React.useState('');
    const [message, setMessage] = React.useState('');

    useEffect(() => {
        if (dataKey) {
            const appData = localStorage.getItem(dataKey);

            if (appData) {
                setIds(appData);
            }
        }

        return () => {
            if (dataKey) {
                localStorage.removeItem(dataKey);
            }
        }
    }, [dataKey]);

    useEffect(() => {
        app.notifySuccess();
    }, [dataKey]);

    const isSubmitDisable = useMemo(() => {
        const disable = isSubmitting;
        if (disable) {
            return true;
        }
        const text = message?.trim() || '';
        return text.length === 0 || text.length > 4000;
    }, [isSubmitting, message]);

    const submitCallback = useCallback(async () => {
        if (isSubmitDisable) {
            return;
        }
        setIsSubmitting(true);

        const token = await authentication.getAuthToken();
        const text = message?.trim() || '';

        const submitRequest: IBotSubmitPayload<BroadcastMessageData> = {
            data: {
                flightTeamIds: ids?.split(',') || [],
                title: messageTitle!,
                content: text!,
                requestSource: BroadcastRequestSource.DepartureBoard,
            },
            appUserAccessToken: token,
            action: BotSubmitAction.BroadcastMessaging,
        };
        submitDialog(submitRequest);
    }, [ids, isSubmitDisable, message, messageTitle]);

    return (
        <div className={styles.root}>
            <div className={styles.formField}>
                <Label weight='semibold' required>Title</Label>
                <Input
                    placeholder="Broadcast message Title"
                    onChange={(_, data) => {
                        setMessageTitle(data?.value);
                    }}
                />
            </div>
            <div className={styles.formField}>
                <Label weight='semibold' required>{t('Message')}</Label>
                <Textarea
                    rows={5}
                    value={message}
                    onChange={(_, data) => {
                        setMessage(data?.value);
                    }}
                ></Textarea>
            </div>
            <div className={styles.actions}>
                <Button
                    iconPosition="before"
                    icon={isSubmitting ? <Spinner size="tiny" /> : undefined}
                    appearance="primary"
                    disabled={isSubmitDisable || isSubmitting}
                    onClick={submitCallback}
                >
                    {t('Broadcast')}
                </Button>
            </div>
        </div>
    );
};

export default BroadcastMessagingDialogPage;
