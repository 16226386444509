import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import IConnectToTeam from '../../../model/IConnectToTeam';
import { FlightPassenger } from '../../../model/FlightPassenger';
import { fetchConnectToTeams, fetchCanSearchPassengers, fetchFlightPassengers } from './findFlightPassengersAPI';


export enum FindPassengersMode {
  Find = "Find",
  Submit = "Submit"
}

export interface FindFlightPassengersState {
  mode: FindPassengersMode;
  isInited: boolean;
  userHasRequiredPermission?: boolean;
  connectToTeamsStatus: 'idle' | 'loading' | 'failed';
  connectToTeams: IConnectToTeam[],
  selectedConnectToTeam?: IConnectToTeam;
  message: string;
  isSubmitting?: boolean;
  flightTeamId: string;
  selectedPassengers: FlightPassenger[];
  passengersSearchQueryText: string;
  passengersSearhResult: FlightPassenger[];
  passengersSearhResultErorrMessage: string;
}

const initialState: FindFlightPassengersState = {
  isInited: false,
  mode: FindPassengersMode.Find,
  connectToTeamsStatus: 'loading',
  message: '',
  connectToTeams: [],
  selectedPassengers: [],
  passengersSearchQueryText: '',
  passengersSearhResult: [],
  flightTeamId: '',
  passengersSearhResultErorrMessage: ''
};

export const canSearchPassengers = createAsyncThunk(
  'findFlightPassengers/canSearchPassengers',
  async () => {
    const response = await fetchCanSearchPassengers();
    return response.data;
  }
);


export const getConnectToTeams = createAsyncThunk(
  'findFlightPassengers/fetchConnectToTeams',
  async () => {
    const response = await fetchConnectToTeams();
    return response.data;

  }
);


export const searchFlightPassengers = createAsyncThunk<FlightPassenger[], void, { state: RootState }>(
  'findFlightPassengers/searchFlightPassengers',
  async (_, { getState, rejectWithValue }) => {
    const { findFlightPassengers: {
      passengersSearchQueryText,
      flightTeamId
    } } = getState();
    if (!passengersSearchQueryText || passengersSearchQueryText.length < 3) {
      return [];
    }
    try {
      const response = await fetchFlightPassengers(flightTeamId, passengersSearchQueryText);
      return response.data.value;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const findPassengersSlice = createSlice({
  name: 'findPassengers',
  initialState,
  reducers: {
    addSelectedFlightPassenger: (state, action: PayloadAction<FlightPassenger>) => {
      const alreadySelected = state.selectedPassengers.find(x => x.id === action.payload.id);
      if (alreadySelected) {
        alreadySelected.displayName = action.payload.displayName;
        alreadySelected.seatNumber = action.payload.seatNumber;
        alreadySelected.loyalty = action.payload.loyalty;
      }
      else {
        state.selectedPassengers.push(action.payload);
      }
    },
    removeSelectedFlightPassenger: (state, action: PayloadAction<string>) => {
      const index = state.selectedPassengers.findIndex(x => x.id === action.payload);
      if (index > -1) {
        state.selectedPassengers.splice(index, 1);
      }
    },
    selectTeam: (state, action: PayloadAction<string | undefined>) => {
      if (!action.payload) {
        state.selectedConnectToTeam = undefined;
      }
      else {
        const team = state.connectToTeams.find(x => x.key === action.payload);
        state.selectedConnectToTeam = team;
      }
    },
    updateMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setIsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
    setPassengersSearchQueryText: (state, action: PayloadAction<string>) => {
      state.passengersSearchQueryText = action.payload ?? '';
    },
    setFlightTeamId: (state, action: PayloadAction<string>) => {
      state.flightTeamId = action.payload ?? '';
    },
    clearFlightPassengersSearchResults: (state, action: PayloadAction) => {
      state.passengersSearhResult = [];
    },

    setMode: (state, action: PayloadAction<FindPassengersMode>) => {
      state.mode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(canSearchPassengers.fulfilled, (state, action) => {
        state.isInited = true;
        state.userHasRequiredPermission = action.payload;
      })
      .addCase(getConnectToTeams.pending, (state) => {
        state.connectToTeamsStatus = 'loading';
      })
      .addCase(getConnectToTeams.fulfilled, (state, action) => {
        state.connectToTeamsStatus = 'idle';
        state.connectToTeams = action.payload;
      })
      .addCase(getConnectToTeams.rejected, (state) => {
        state.connectToTeamsStatus = 'failed';
      })
      .addCase(searchFlightPassengers.fulfilled, (state, action) => {
        state.passengersSearhResult = action.payload;
        state.passengersSearhResultErorrMessage = '';
      })
      .addCase(searchFlightPassengers.rejected, (state, action) => {
        state.passengersSearhResult = [];
        const payload:any = action.payload;
        state.passengersSearhResultErorrMessage = payload.title ?? `Passengers not available for the flight`
      });
  }
});

export const {
  addSelectedFlightPassenger,
  removeSelectedFlightPassenger,
  updateMessage,
  setPassengersSearchQueryText,
  setIsSubmitting,
  setFlightTeamId,
  clearFlightPassengersSearchResults,
  selectTeam,
  setMode
} = findPassengersSlice.actions;

export const selectFindFlightPassengers = (state: RootState) => state.findFlightPassengers;

export default findPassengersSlice.reducer;
