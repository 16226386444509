import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useSelector } from '../../store/hooks';
import {
    loadDepartureBoardFlights,
    selectDepartureBoard,
    toggleSort,
    setQueryText,
    showHideFitler,
    selectDepartureBoardFilter,
    setDepartureStatusFilter,
    setCurrentPage,
    resetFilters,
    setArrivalAirportFilter,
    loadDepartureBoardArrivalAirportsFilter,
    loadDepartureBoardDepartureAirportsFilter,
    setDepartureAirportFilter,
    setCheckInStatusFilter,
    FlightCheckedInStatus,
    DelayedBaggageStatus,
    setDelayedBaggageStatusFilter,
} from '../../store/features/departureBoard/departureBoardSlice';
import { app, dialog } from '@microsoft/teams-js';
import { useDepartureBoardPageStyles } from './DepartureBoardPage.styles';
import {
    Button,
    Dropdown,
    Option,
    Input,
    InputOnChangeData,
    Label,
    mergeClasses,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    Toolbar,
    ToolbarButton,
    ToolbarGroup,
    Tooltip,
    Link,
    createTableColumn,
    TableCellLayout,
    TableHeaderCell,
    Table,
    TableHeader,
    TableRow,
    TableBody,
    TableCell,
    useTableFeatures,
    useTableSort,
    TableColumnId,
    useTableColumnSizing_unstable,
    TableColumnSizingOptions,
    TableColumnDefinition,
    useTableSelection,
    TableSelectionCell,
    Text,
} from '@fluentui/react-components';
import {
    Filter24Regular,
    Search24Regular,
    CalendarCheckmarkRegular,
    NumberRow16Regular,
    AirplaneRegular,
    AirplaneTakeOffRegular,
    StarRegular,
    Chat24Regular,
    Dismiss24Regular,
    LuggageRegular,
    MegaphoneRegular,
    Chat24Filled,
    bundleIcon,
} from '@fluentui/react-icons';
import { getBaseUrl } from '../../configVariables';
import {
    loadConfig,
    selectConfig,
} from '../../store/features/config/configSlice';
import { FlightDepartureStatus, FlightTeam } from '../../model';
import { formatFlightTeamName, formatFlightTime } from '../../utils/formatters';
import { useDebouncedCallback } from 'use-debounce';
import Pagination from '../Pagination';
import { v4 as uuidv4 } from 'uuid';
import { useToolbarStyles } from '../../controls';

const SendMessageIconBundle = bundleIcon(Chat24Filled, Chat24Regular);

const DepartureBoardPage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const styles = useDepartureBoardPageStyles();
    const toolbarStyles = useToolbarStyles();
    const departureBoardState = useSelector(selectDepartureBoard);
    const departureBoardFilterState = useSelector(selectDepartureBoardFilter);

    const { botId } = useSelector(selectConfig);

    const loadFlightsDebounced = useDebouncedCallback(
        useCallback(
            (page?: number) => {
                if (page) {
                    dispatch(setCurrentPage(page));
                }
                dispatch(loadDepartureBoardFlights());
            },
            [dispatch]
        ),
        600
    );

    useEffect(() => {
        dispatch(loadDepartureBoardFlights());
        dispatch(loadDepartureBoardDepartureAirportsFilter());
        dispatch(loadDepartureBoardArrivalAirportsFilter());
        dispatch(loadConfig());
    }, [dispatch]);

    useEffect(() => {
        if (departureBoardState.loadStatus === 'idle') {
            app.notifySuccess();
        }
    }, [departureBoardState.loadStatus]);

    useEffect(() => {
        return () => {
            loadFlightsDebounced.cancel();
        };
    }, [loadFlightsDebounced]);

    const renderDepartureStatus = useCallback(
        (item: FlightTeam) => {
            const key = `DepartureStatus_${item.departureStatus}`;
            const element = (
                <div className={styles.departureStatus}>
                    <div
                        className={mergeClasses(
                            styles.departureStatusIndicator,
                            item.departureStatus
                        )}
                    ></div>
                    {t(key)}
                </div>
            );

            let tooltipContent: string | undefined = undefined;
            if (
                item.departureStatus === FlightDepartureStatus.Delayed &&
                (item.estimatedDepartureTime || item.estimatedDepartureTimeUtc)
            ) {
                tooltipContent = `Estimated: ${item.estimatedDepartureTime
                    ? formatFlightTime(item.estimatedDepartureTime)
                    : formatFlightTime(item.estimatedDepartureTimeUtc, true)
                    }`;
            } else if (
                item.departureStatus === FlightDepartureStatus.Departed &&
                (item.actualDepartureTime || item.actualDepartureTimeUtc)
            ) {
                tooltipContent = `Departed: ${item.actualDepartureTime
                    ? formatFlightTime(item.actualDepartureTime)
                    : formatFlightTime(item.actualDepartureTimeUtc, true)
                    }`;
            }

            if (tooltipContent) {
                return (
                    <Tooltip
                        content={tooltipContent}
                        relationship="label"
                        withArrow
                    >
                        {element}
                    </Tooltip>
                );
            }

            return element;
        },
        [t, styles]
    );

    const renderCheckInStatus = useCallback(
        (item: FlightTeam) => {
            const status = item.checkIns.length > 0 ? 'Yes' : 'No';
            const statusDisplayName = t(`CheckInStatus_${status}`);

            return (
                <div className={styles.checkInStatus}>
                    <div
                        className={mergeClasses(styles.statusIndicator, status)}
                    ></div>
                    {statusDisplayName}
                </div>
            );
        },
        [t, styles]
    );

    const renderBaggageStatus = useCallback(
        (item: FlightTeam) => {
            const status = !item.bags?.length
                ? 'NotAvailable'
                : item.bags.every((b) => !!b.notifiedOnUtc)
                    ? 'AllNotified'
                    : 'NotNotified';
            const statusDisplayName = t(`BaggageStatus_${status}`);

            return (
                <div className={styles.checkInStatus}>
                    <div
                        className={mergeClasses(styles.statusIndicator, status)}
                    ></div>
                    {statusDisplayName}
                </div>
            );
        },
        [styles, t]
    );

    const opendSendMessageToTeamDialog = useCallback(
        (item: FlightTeam) => {
            const baseUrl = getBaseUrl();

            dialog.url.bot.open({
                size: {
                    width: 500,
                    height: 250,
                },
                title: formatFlightTeamName(item),
                url: `${baseUrl}/sendMessageToTeam/${item.id}`,
                completionBotId: botId,
            });
        },
        [botId]
    );

    const columns = useMemo<
        TableColumnDefinition<FlightTeam & { selected: boolean }>[]
    >(
        () => [
            createTableColumn({
                columnId: 'iataFlightIdentifier',
                renderHeaderCell: () => {
                    return (
                        <TableCellLayout media={<NumberRow16Regular />}>
                            {t('TableHeader_Flight')}
                        </TableCellLayout>
                    );
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout>
                            {item.iataFlightIdentifier}
                        </TableCellLayout>
                    );
                },
            }),
            createTableColumn({
                columnId: 'departureAirportIata,arrivalAirportIata',
                renderHeaderCell: () => {
                    return (
                        <TableCellLayout media={<AirplaneRegular />}>
                            {t('TableHeader_Airport')}
                        </TableCellLayout>
                    );
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout>{`${item.departureAirportIata} - ${item.arrivalAirportIata}`}</TableCellLayout>
                    );
                },
            }),
            createTableColumn({
                columnId: 'scheduledDepartureTimeUtc',
                renderHeaderCell: () => {
                    return (
                        <TableCellLayout media={<AirplaneTakeOffRegular />}>
                            {t('TableHeader_DepartureTime')}
                        </TableCellLayout>
                    );
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout>
                            {`${item.scheduledDepartureTime
                                ? formatFlightTime(
                                    item.scheduledDepartureTime
                                )
                                : formatFlightTime(
                                    item.scheduledDepartureTimeUtc,
                                    true
                                )
                                }`}
                        </TableCellLayout>
                    );
                },
            }),
            createTableColumn({
                columnId: 'scheduledArrivalTimeUtc',
                renderHeaderCell: () => {
                    return (
                        <TableCellLayout media={<CalendarCheckmarkRegular />}>
                            {t('TableHeader_ArrivalTime')}
                        </TableCellLayout>
                    );
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout>{`${item.scheduledArrivalTime
                            ? formatFlightTime(item.scheduledArrivalTime)
                            : formatFlightTime(
                                item.scheduledArrivalTimeUtc,
                                true
                            )
                            }`}</TableCellLayout>
                    );
                },
            }),
            createTableColumn({
                columnId: 'departureStatus',
                renderHeaderCell: () => {
                    return (
                        <TableCellLayout media={<StarRegular />}>
                            {t('TableHeader_DepartureStatus')}
                        </TableCellLayout>
                    );
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout>
                            {renderDepartureStatus(item)}
                        </TableCellLayout>
                    );
                },
            }),
            createTableColumn({
                columnId: 'checkIns/$count',
                renderHeaderCell: () => {
                    return (
                        <TableCellLayout>
                            {t('TableHeader_CheckInStatus')}
                        </TableCellLayout>
                    );
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout>
                            {renderCheckInStatus(item)}
                        </TableCellLayout>
                    );
                },
            }),
            createTableColumn({
                columnId: 'bags/$count',
                renderHeaderCell: () => {
                    return (
                        <TableCellLayout media={<LuggageRegular />}>
                            {t('TableHeader_DelayedBaggage')}
                        </TableCellLayout>
                    );
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout>
                            {renderBaggageStatus(item)}
                        </TableCellLayout>
                    );
                },
            }),
            createTableColumn({
                columnId: 'actions',
                renderHeaderCell: () => {
                    return <></>;
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout>
                            <Tooltip
                                content={t('Button_SendMessageToTeam_Tooltip')!}
                                relationship="label"
                                withArrow
                            >
                                <Button
                                    appearance="transparent"
                                    icon={<SendMessageIconBundle />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        opendSendMessageToTeamDialog(item);
                                    }}
                                />
                            </Tooltip>
                        </TableCellLayout>
                    );
                },
            }),
        ],
        [
            t,
            renderDepartureStatus,
            renderCheckInStatus,
            renderBaggageStatus,
            opendSendMessageToTeamDialog,
        ]
    );

    const columnSizingOptions = useMemo<TableColumnSizingOptions>(() => {
        return {
            iataFlightIdentifier: {
                minWidth: 90,
            },
            'departureAirportIata,arrivalAirportIata': {
                minWidth: 80,
            },
            scheduledDepartureTimeUtc: {
                minWidth: 130,
            },
            scheduledArrivalTimeUtc: {
                minWidth: 130,
            },
            departureStatus: {
                minWidth: 80,
            },
            'checkIns/$count': {
                minWidth: 75,
            },
            'bags/$count': {
                minWidth: 140,
            },
            actions: {
                minWidth: 40,
            },
        };
    }, []);

    const {
        getRows,
        selection: {
            allRowsSelected,
            someRowsSelected,
            toggleAllRows,
            toggleRow,
            isRowSelected,
            selectedRows,
            clearRows,
        },
        sort: { getSortDirection },
        columnSizing_unstable: columnSizing,
    } = useTableFeatures(
        {
            getRowId: (item) => item.id,
            columns,
            items: departureBoardState.items.map((x) => ({
                ...x,
                selected: false,
            })),
        },
        [
            useTableSort({
                sortState: {
                    sortColumn: departureBoardState.sorting.sortBy,
                    sortDirection: departureBoardState.sorting.sortByDescending
                        ? 'ascending'
                        : 'descending',
                },
            }),
            useTableColumnSizing_unstable({
                columnSizingOptions,
            }),
            useTableSelection({
                selectionMode: 'multiselect',
            }),
        ]
    );

    const rows = getRows((row) => {
        const selected = isRowSelected(row.rowId);
        return {
            ...row,
            onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
            selected,
            appearance: selected ? ('brand' as const) : ('none' as const),
        };
    });

    const opendBroadcastMessagingDialog = useCallback(() => {
        const baseUrl = getBaseUrl();

        const dataKey = uuidv4();
        localStorage.setItem(dataKey, Array.from(selectedRows).join(','));

        dialog.url.bot.open(
            {
                size: {
                    width: 500,
                    height: 300,
                },
                title: `Broadcast to ${selectedRows?.size} selected flights`,
                url: `${baseUrl}/broadcastMessaging/${dataKey}`,
                completionBotId: botId,
            },
            (result) => {
                console.log(result);
                clearRows({} as React.MouseEvent);
            }
        );
    }, [botId, clearRows, selectedRows]);

    const headerSortProps = useCallback(
        (columnId: TableColumnId) => ({
            onClick: () => {
                if (columnId === 'actions') {
                    return;
                }
                dispatch(toggleSort(columnId as string));
                dispatch(loadDepartureBoardFlights());
            },
            sortDirection: getSortDirection(columnId),
        }),
        [getSortDirection, dispatch]
    );

    const onQueryTextChange = useCallback(
        (_: unknown, data: InputOnChangeData) => {
            dispatch(setQueryText(data.value));
            loadFlightsDebounced(1);
        },
        [dispatch, loadFlightsDebounced]
    );

    const onClearSearchTextClick = useCallback(() => {
        if (!!departureBoardFilterState.queryText) {
            loadFlightsDebounced.cancel();
            dispatch(setQueryText(''));
            dispatch(setCurrentPage(1));
            dispatch(loadDepartureBoardFlights());
        }
    }, [departureBoardFilterState.queryText, dispatch, loadFlightsDebounced]);

    const onFilterClick = useCallback(() => {
        dispatch(showHideFitler(!departureBoardFilterState.open));
    }, [departureBoardFilterState.open, dispatch]);

    const onClearFiltersClick = useCallback(() => {
        loadFlightsDebounced.cancel();
        dispatch(resetFilters());
        dispatch(setCurrentPage(1));
        dispatch(loadDepartureBoardFlights());
    }, [dispatch, loadFlightsDebounced]);

    const onDpartureStatusFilterChange = useCallback(
        (status: FlightDepartureStatus, checked: boolean) => {
            dispatch(
                setDepartureStatusFilter({
                    value: status,
                    checked: checked,
                })
            );
            loadFlightsDebounced(1);
        },
        [dispatch, loadFlightsDebounced]
    );

    const onDepartureAirportFilterChange = useCallback(
        (airport: string, checked: boolean) => {
            dispatch(
                setDepartureAirportFilter({
                    value: airport,
                    checked: checked,
                })
            );
            loadFlightsDebounced(1);
        },
        [dispatch, loadFlightsDebounced]
    );

    const onArrivalAirportFilterChange = useCallback(
        (airport: string, checked: boolean) => {
            dispatch(
                setArrivalAirportFilter({
                    value: airport,
                    checked: checked,
                })
            );
            loadFlightsDebounced(1);
        },
        [dispatch, loadFlightsDebounced]
    );

    const onCheckInStatusChange = useCallback(
        (status: FlightCheckedInStatus) => {
            dispatch(setCheckInStatusFilter(status));
            loadFlightsDebounced(1);
        },
        [dispatch, loadFlightsDebounced]
    );

    const onDelayedBaggageStatusChange = useCallback(
        (status: DelayedBaggageStatus) => {
            dispatch(setDelayedBaggageStatusFilter(status));
            loadFlightsDebounced(1);
        },
        [dispatch, loadFlightsDebounced]
    );

    const selectedDepartureStatuses = useMemo(() => {
        return Object.keys(departureBoardFilterState.departureStatuses).filter(
            (k) => departureBoardFilterState.departureStatuses[k]
        );
    }, [departureBoardFilterState.departureStatuses]);

    const selectedArrivalAirports = useMemo(() => {
        return Object.keys(departureBoardFilterState.arrivalAirports).filter(
            (k) => departureBoardFilterState.arrivalAirports[k]
        );
    }, [departureBoardFilterState.arrivalAirports]);

    const selectedDepartureAirports = useMemo(() => {
        return Object.keys(departureBoardFilterState.departureAirports).filter(
            (k) => departureBoardFilterState.departureAirports[k]
        );
    }, [departureBoardFilterState.departureAirports]);

    const applyedFiltesCount = useMemo(() => {
        return (
            selectedDepartureStatuses.length +
            selectedArrivalAirports.length +
            selectedDepartureAirports.length +
            (!!departureBoardFilterState.flightsCheckedInStatus ? 1 : 0) +
            (!!departureBoardFilterState.delayedBaggageStatus ? 1 : 0)
        );
    }, [
        selectedDepartureStatuses.length,
        selectedArrivalAirports.length,
        selectedDepartureAirports.length,
        departureBoardFilterState.flightsCheckedInStatus,
        departureBoardFilterState.delayedBaggageStatus,
    ]);

    const buttonFilterLabel = useMemo(() => {
        let label = t('Button_Filter');
        if (applyedFiltesCount > 0) {
            label = `${label} (${applyedFiltesCount})`;
        }
        return label;
    }, [applyedFiltesCount, t]);

    const selectedRowsButton = useMemo(() => {
        return !!selectedRows.size ? (
            <div className={styles.selectedRowsButton}>
                <ToolbarButton
                    appearance="subtle"
                    icon={<Dismiss24Regular />}
                    onClick={clearRows}
                ></ToolbarButton>
                <Text weight="semibold">
                    {t('Selected_Rows', { count: selectedRows.size })}
                </Text>
            </div>
        ) : (
            <></>
        );
    }, [clearRows, selectedRows.size, styles.selectedRowsButton, t]);

    return (
        <div className={styles.root}>
            <Toolbar aria-label="Flights" className={toolbarStyles.toolbar}>
                <ToolbarGroup
                    className={toolbarStyles.toolbarGroup}
                    role="presentation"
                >
                    {selectedRowsButton}
                </ToolbarGroup>
                <ToolbarGroup
                    role="presentation"
                    className={mergeClasses(
                        toolbarStyles.toolbarGroup,
                        toolbarStyles.toolbarRightGroup
                    )}
                >
                    <ToolbarButton
                        disabled={selectedRows.size === 0}
                        appearance="subtle"
                        icon={<MegaphoneRegular />}
                        onClick={opendBroadcastMessagingDialog}
                    >
                        Broadcast
                    </ToolbarButton>
                    <Popover
                        open={departureBoardFilterState.open}
                        onOpenChange={(_, data) => {
                            if (!data.open && departureBoardFilterState.open) {
                                dispatch(showHideFitler(false));
                            }
                        }}
                    >
                        <PopoverTrigger disableButtonEnhancement>
                            <ToolbarButton
                                aria-label={t('Button_Filter')!}
                                appearance="subtle"
                                icon={<Filter24Regular />}
                                onClick={onFilterClick}
                            >
                                {buttonFilterLabel}
                            </ToolbarButton>
                        </PopoverTrigger>
                        <PopoverSurface>
                            <div className={styles.filtersContainer}>
                                <div className={styles.filtersHeader}>
                                    <Link
                                        appearance="subtle"
                                        disabled={applyedFiltesCount === 0}
                                        onClick={onClearFiltersClick}
                                    >
                                        {t('Button_Filter_ClearAll')}
                                    </Link>
                                </div>
                                <div className={styles.filters}>
                                    <div className={styles.filter}>
                                        <Label>
                                            {t('Filter_DepartureStatus')}
                                        </Label>
                                        <Dropdown
                                            multiselect
                                            selectedOptions={
                                                selectedDepartureStatuses
                                            }
                                            value={selectedDepartureStatuses.map(status => t(
                                                `DepartureStatus_${status}`
                                            )).join(
                                                ', '
                                            )}
                                            onOptionSelect={(_, data) => {
                                                const checked =
                                                    data.selectedOptions.indexOf(
                                                        data.optionValue!
                                                    ) >= 0;
                                                onDpartureStatusFilterChange(
                                                    data.optionValue as FlightDepartureStatus,
                                                    checked
                                                );
                                            }}
                                        >
                                            {Object.values(
                                                FlightDepartureStatus
                                            ).map((status) => (
                                                <Option
                                                    key={`option_${status}`}
                                                    text={
                                                        t(
                                                            `DepartureStatus_${status}`
                                                        )!
                                                    }
                                                    value={status}
                                                >
                                                    {
                                                        t(
                                                            `DepartureStatus_${status}`
                                                        )!
                                                    }
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    <div className={styles.filter}>
                                        <Label>
                                            {t('Filter_DepartureAirport')}
                                        </Label>

                                        <Dropdown
                                            multiselect
                                            selectedOptions={
                                                selectedDepartureAirports
                                            }
                                            value={selectedDepartureAirports.join(
                                                ', '
                                            )}
                                            onOptionSelect={(_, data) => {
                                                const checked =
                                                    data.selectedOptions.indexOf(
                                                        data.optionValue!
                                                    ) >= 0;
                                                onDepartureAirportFilterChange(
                                                    data.optionValue!,
                                                    checked
                                                );
                                            }}
                                        >
                                            {Object.keys(
                                                departureBoardFilterState.departureAirports
                                            ).map((airport) => (
                                                <Option
                                                    key={`option_${airport}`}
                                                    text={airport}
                                                    value={airport}
                                                >
                                                    {airport}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    <div className={styles.filter}>
                                        <Label>
                                            {t('Filter_ArrivalAirport')}
                                        </Label>

                                        <Dropdown
                                            multiselect
                                            selectedOptions={
                                                selectedArrivalAirports
                                            }
                                            value={selectedArrivalAirports.join(
                                                ', '
                                            )}
                                            onOptionSelect={(_, data) => {
                                                const checked =
                                                    data.selectedOptions.indexOf(
                                                        data.optionValue!
                                                    ) >= 0;
                                                onArrivalAirportFilterChange(
                                                    data.optionValue!,
                                                    checked
                                                );
                                            }}
                                        >
                                            {Object.keys(
                                                departureBoardFilterState.arrivalAirports
                                            ).map((airport) => (
                                                <Option
                                                    key={`option_${airport}`}
                                                    text={airport}
                                                    value={airport}
                                                >
                                                    {airport}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    <div className={styles.filter}>
                                        <Label>
                                            {t('Filter_CheckInStatus')}
                                        </Label>
                                        <Dropdown
                                            selectedOptions={[
                                                departureBoardFilterState.flightsCheckedInStatus,
                                            ]}

                                            value={
                                                departureBoardFilterState.flightsCheckedInStatus ?
                                                    t(
                                                        `CheckInStatus_${departureBoardFilterState.flightsCheckedInStatus}`
                                                    ) : undefined
                                            }
                                            onOptionSelect={(_, data) => {
                                                onCheckInStatusChange(
                                                    data.optionValue as FlightCheckedInStatus
                                                );
                                            }}
                                        >
                                            <Option
                                                key={`option_yes`}
                                                text={
                                                    t('CheckInStatus_Yes')!
                                                }
                                                value={
                                                    FlightCheckedInStatus.Yes
                                                }
                                            >
                                                {t('CheckInStatus_Yes')}
                                            </Option>
                                            <Option
                                                key={`option_no`}
                                                text={
                                                    t('CheckInStatus_No')!
                                                }
                                                value={FlightCheckedInStatus.No}
                                            >
                                                {t('CheckInStatus_No')}
                                            </Option>
                                        </Dropdown>
                                    </div>
                                    <div className={styles.filter}>
                                        <Label>
                                            {t('Filter_DelayedBaggage')}
                                        </Label>
                                        <Dropdown
                                            selectedOptions={[
                                                departureBoardFilterState.delayedBaggageStatus,
                                            ]}
                                            value={
                                                departureBoardFilterState.delayedBaggageStatus ?
                                                t(
                                                    `DelayedBaggage_${departureBoardFilterState.delayedBaggageStatus}`
                                                ) : undefined
                                            }
                                            onOptionSelect={(_, data) => {
                                                onDelayedBaggageStatusChange(
                                                    data.optionValue as DelayedBaggageStatus
                                                );
                                            }}
                                        >
                                            <Option
                                                key={`option_not_available`}
                                                text={
                                                    t(
                                                        'DelayedBaggage_NotAvailable'
                                                    )!
                                                }
                                                value={
                                                    DelayedBaggageStatus.NotAvailable
                                                }
                                            >
                                                {t(
                                                    'DelayedBaggage_NotAvailable'
                                                )}
                                            </Option>
                                            <Option
                                                key={`option_outstanding_actions`}
                                                text={
                                                    t(
                                                        'DelayedBaggage_OutstandingActions'
                                                    )!
                                                }
                                                value={
                                                    DelayedBaggageStatus.OutstandingActions
                                                }
                                            >
                                                {t(
                                                    'DelayedBaggage_OutstandingActions'
                                                )}
                                            </Option>
                                            <Option
                                                key={`option_all_notified`}
                                                text={
                                                    t(
                                                        'DelayedBaggage_AllNotified'
                                                    )!
                                                }
                                                value={
                                                    DelayedBaggageStatus.AllNotified
                                                }
                                            >
                                                {t(
                                                    'DelayedBaggage_AllNotified'
                                                )}
                                            </Option>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </PopoverSurface>
                    </Popover>
                    <Input
                        className={toolbarStyles.searchBar}
                        placeholder={t('SearchFlightsInputPlaceholder')!}
                        size="small"
                        contentAfter={
                            <Button
                                appearance="transparent"
                                onClick={onClearSearchTextClick}
                                icon={
                                    !departureBoardFilterState.queryText ? (
                                        <Search24Regular />
                                    ) : (
                                        <Dismiss24Regular />
                                    )
                                }
                            />
                        }
                        onChange={onQueryTextChange}
                        value={departureBoardFilterState.queryText}
                    />
                </ToolbarGroup>
            </Toolbar>
            <Table
                className={styles.table}
                size="small"
                role="grid"
                aria-label="Flights"
                noNativeElements
                sortable
            >
                <TableHeader>
                    <TableRow className={styles.tableRow}>
                        <TableSelectionCell
                            className={styles.tableSelectionCell}
                            checked={
                                allRowsSelected
                                    ? true
                                    : someRowsSelected
                                        ? 'mixed'
                                        : false
                            }
                            onClick={toggleAllRows}
                            checkboxIndicator={{
                                'aria-label': 'Select all rows ',
                            }}
                        />
                        {columns.map((column) => (
                            <TableHeaderCell
                                key={column.columnId}
                                style={
                                    columnSizing.getTableHeaderCellProps(
                                        column.columnId
                                    )?.style
                                }
                                {...headerSortProps(column.columnId)}
                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map(({ item, selected, appearance, onClick }) => (
                        <TableRow
                            key={item.id}
                            className={styles.tableRow}
                            onClick={onClick}
                            aria-selected={selected}
                            appearance={appearance}
                        >
                            <TableSelectionCell
                                className={styles.tableSelectionCell}
                                checked={selected}
                                checkboxIndicator={{
                                    'aria-label': 'Select row',
                                }}
                            />
                            {columns.map((column) => (
                                <TableCell
                                    key={column.columnId}
                                    tabIndex={0}
                                    role="gridcell"
                                    style={{
                                        ...columnSizing.getTableCellProps(
                                            column.columnId
                                        )?.style,
                                        justifyContent: 'center',
                                    }}
                                >
                                    {column.renderCell({ ...item, selected })}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination
                currentPage={departureBoardState.pagination.currentPage}
                pageSize={departureBoardState.pagination.pageSize}
                totalItems={departureBoardState.pagination.totalCount}
                onChange={(page) => {
                    loadFlightsDebounced.cancel();
                    dispatch(setCurrentPage(page));
                    dispatch(loadDepartureBoardFlights());
                }}
            />
        </div>
    );
};

export default DepartureBoardPage;
