import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";


export interface AppConfigurationState {
    searchQuery: string;
}

const initialState: AppConfigurationState = {
    searchQuery: ''
};

export const appConfigurationSlice = createSlice({
    name: 'appConfiguration',
    initialState,
    reducers: {
        setSearchQuery: (state, action: PayloadAction<string>) => {
            state.searchQuery = action.payload;
        }
    }
});

export const { setSearchQuery } = appConfigurationSlice.actions;

export const selectAppConfigurationSearchQuery = (state: RootState) => state.appConfiguration.searchQuery;

export default appConfigurationSlice.reducer;
