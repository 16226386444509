import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useMishandledBagageToolbarStyles = makeStyles({
    toolbar: {
        justifyContent: 'space-between',
        boxShadow: tokens.shadow16,
        position: 'sticky',
        top: 0,
        left: 0,
        backgroundColor: tokens.colorNeutralBackground1,
        zIndex: 1,
        ...shorthands.padding(tokens.spacingHorizontalXS, tokens.spacingHorizontalXL)
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '250px',
    },
    filtersHeader: {
        alignSelf: 'flex-end',
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalS,
    },
    filter: {
        display: 'grid',
        gridRowGap: tokens.spacingHorizontalS,
    },
});
