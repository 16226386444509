import { QueryOptions } from "odata-query";
import { IPagination, ISorting } from "../model";

export const convertToOdataOptions = <T>({ sorting, pagination, count }
  : { sorting?: ISorting, pagination?: IPagination, count?: boolean })
  : Partial<QueryOptions<T>> => {
  const options: Partial<QueryOptions<T>> = {};

  if (sorting) {
    const sortableProperties = sorting.sortBy.split(',');
    options.orderBy = sortableProperties.map(prop => `${prop}${sorting.sortByDescending ? ' desc' : ''}`).join();
  }

  if (pagination && pagination.pageSize > 0) {
    const page = pagination.currentPage;
    const perPage = pagination.pageSize;
    options.top = perPage;
    options.skip = perPage * (page - 1);
  }
  options.count = count;
  return options;
}

export const replaceSpecialCharacters = (attribute: string): string => {
  // replace the single quotes
  attribute = attribute.replace(/'/g, "''");

  attribute = attribute.replace(/%/g, "%25");
  attribute = attribute.replace(/\+/g, "%2B");
  attribute = attribute.replace(/\//g, "%2F");
  attribute = attribute.replace(/\?/g, "%3F");

  attribute = attribute.replace(/#/g, "%23");
  attribute = attribute.replace(/&/g, "%26");
  return attribute;
}