export interface TableContinuationToken {
    nextPartitionKey: string;
    nextRowKey: string;
    nextTableName: string;
    targetLocation: StorageLocation;
}

export enum StorageLocation {
    Primary = "Primary",
    Secondary = "Secondary"
}