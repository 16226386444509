import { BagReFlightLeg, FlightTeam, Passenger } from ".";


export type Bag = {
    readonly id?: string;
    readonly bagTag: string;
    readonly passengerNameRecord: string;
    readonly seatNumber: string;
    readonly cabin: string;
    readonly missingBagReportNumber: string;
    readonly passengerId?: string;
    readonly flightTeamId?: string;
    readonly notifiedOnUtc?: Date | null;
    readonly passenger?: Passenger;
    readonly flightTeam?: FlightTeam;
    readonly lastUpdateOnUtc?: string;
    readonly baggageReFlightLegs?: BagReFlightLeg[];
}

export enum BaggageStatus {
    None = 'None',
    Notified = 'Notified',
}