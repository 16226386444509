
import React, { useEffect, useMemo } from 'react';
import { Label, Radio, RadioGroup, useId } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { app, pages } from "@microsoft/teams-js";
import { getBaseUrl } from '../../configVariables';
import { useConfigureTabPageStyles } from './ConfigureTabPage.styles';
import { loadConfig } from '../../store/features/config/configSlice';
import { useAppDispatch } from '../../store/hooks';

interface ITabInfo {
  suggestedDisplayName: string,
  contentUrl: string;
  entityId: string;
}

type AvaiableTabs = { [n: string]: ITabInfo };

const ConfigureTabPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const availableTabs = useMemo<AvaiableTabs>(() => {
    const tabs: { [key: string]: any } = {
      'BA_Derarture_Board': {
        entityId: 'BA_Derarture_Board',
        suggestedDisplayName: t('Configuration_Tab_BA_Derarture_Board_DisplayName'),
        contentUrl: getBaseUrl() + "/departureBoard?locale={locale}",
      },
      'BA_App_Configuration_Board': {
        entityId: 'BA_App_Configuration_Board',
        suggestedDisplayName: t('Configuration_Tab_BA_App_Configuration_Board_DisplayName'),
        contentUrl: getBaseUrl() + "/appConfiguration?locale={locale}",
      },
      'BA_Reporting': {
        entityId: 'BA_Reporting',
        suggestedDisplayName: t('Configuration_Tab_BA_Reporting_DisplayName'),
        contentUrl: getBaseUrl() + "/reporting?locale={locale}",
      },
      'BA_Broadcast_Message_Report': {
        entityId: 'BA_Broadcast_Message_Report',
        suggestedDisplayName: t('Configuration_Tab_BA_Broadcast_Message_Report_DisplayName'),
        contentUrl: getBaseUrl() + "/broadcastMessageReport?locale={locale}",
      }
    };

    // if (config.featureFlags.indexOf('PassengersBoard') > -1) {
    //   tabs['BA_PIL_Board'] = {
    //     entityId: 'BA_PIL_Board',
    //     suggestedDisplayName: t('Configuration_Tab_BA_PIL_Board_DisplayName'),
    //     contentUrl: getBaseUrl() + "/flightsPassengersBoard?locale={locale}",
    //   }
    // }

    tabs['BA_Flight_Baggage_Status'] = {
      entityId: 'BA_Flight_Baggage_Status',
      suggestedDisplayName: t('Configuration_BA_Flight_Baggage_Status_DisplayName'),
      contentUrl: getBaseUrl() + "/flightBaggageStatus?locale={locale}",
    }

    tabs['BA_Flight_Baggage_Status'] = {
      entityId: 'BA_Flight_Baggage_Status',
      suggestedDisplayName: t('Configuration_BA_Flight_Baggage_Status_DisplayName'),
      contentUrl: getBaseUrl() + "/flightBaggageStatus?locale={locale}",
    }

    return tabs;
  }, [t]);

  const [value, setValue] = React.useState('');
  const labelId = useId("label");
  const styles = useConfigureTabPageStyles();

  useEffect(() => {
    dispatch(loadConfig());
    const init = async () => {
      const config = await pages.getConfig();
      if (config && config.entityId) {
        setValue(config.entityId);
      }
    };

    init().then(() => {
      app.notifySuccess();
    });
  }, [dispatch]);

  useEffect(() => {
    if (value) {
      const tab = availableTabs[value];
      if (tab) {
        pages.config.registerOnSaveHandler((saveEvent) => {
          pages.config.setConfig({
            entityId: tab.entityId,
            contentUrl: tab.contentUrl,
            suggestedDisplayName: tab.suggestedDisplayName
          });
          saveEvent.notifySuccess();
        });
        pages.config.setValidityState(true);
        return;
      }
    }
    pages.config.setValidityState(false);

  }, [value, availableTabs]);


  return (
    <div className={styles.root} >
      <div className={styles.field}>
        <Label id={labelId}>{t("Configuration_Tabs_Label")}</Label>
        <RadioGroup
          value={value}
          onChange={(_, data) => setValue(data.value)}
          aria-labelledby={labelId}
        >
          {Object.keys(availableTabs).map((k) => <Radio key={k} value={k} label={availableTabs[k].suggestedDisplayName} />)}

        </RadioGroup>
      </div>
    </div>
  );
};

export default ConfigureTabPage;