import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Field,
    Input,
    Textarea,
} from '@fluentui/react-components';
import { DialogOpenChangeEventHandler } from '@fluentui/react-dialog';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { useScrollBarStyle } from './scrollbar.styles';

export enum WelcomeMessageDialogMode {
    New = 'New',
    Edit = 'Edit',
}

interface CreateOrUpdateMessageDialogActionProps {
    open: boolean;
    mode: WelcomeMessageDialogMode;
    flight: string;
    welcomeMessage: string;
    onSaveBtnClick: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        data: {
            flight: string;
            welcomeMessage: string;
        }
    ) => void;
    onOpenChange?: DialogOpenChangeEventHandler;
}

const isDataValid = (
    flight: string,
    welcomeMessage: string,
    newFlight: string,
    newWelcomeMessage: string
): boolean => {
    if (!newFlight || !newWelcomeMessage) {
        return false;
    }

    if (welcomeMessage === newWelcomeMessage) {
        return false;
    }

    return true;
};

const CreateOrUpdateMessageDialogAction = ({
    open,
    mode,
    flight,
    welcomeMessage,
    onSaveBtnClick,
    onOpenChange,
}: CreateOrUpdateMessageDialogActionProps) => {
    const { t } = useTranslation();

    const [newFlightValue, setNewFlightValue] = useState<string>('');
    const [newWelcomeMessageValue, setNewWelcomeMessageValue] =
        useState<string>('');

    const scrollBarStyle = useScrollBarStyle();

    useEffect(() => {
        setNewFlightValue(flight);
        setNewWelcomeMessageValue(welcomeMessage);
    }, [flight, welcomeMessage]);

    useEffect(() => {
        if (!open) {
            setNewFlightValue('');
            setNewWelcomeMessageValue('');
        }
    }, [open]);

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        {t('CreateOrUpdateMessageDialog_Title').replace(
                            '{mode}',
                            mode
                        )}
                    </DialogTitle>
                    <DialogContent className={scrollBarStyle}>
                        <Field
                            label={t(
                                'CreateOrUpdateMessageDialog_AircraftRegistrationNumber_Label'
                            )}
                            required
                        >
                            <Input
                                type="text"
                                placeholder={
                                    t(
                                        'CreateOrUpdateMessageDialog_FlightField_Placeholder'
                                    ) ?? ''
                                }
                                disabled={
                                    mode === WelcomeMessageDialogMode.Edit
                                }
                                value={newFlightValue}
                                onChange={(_, data) => {
                                    setNewFlightValue(data.value);
                                }}
                            />
                        </Field>
                        <Field
                            label={t(
                                'CreateOrUpdateMessageDialog_WelcomeMessage_Label'
                            )}
                            required
                        >
                            <Textarea
                                appearance="outline"
                                resize="none"
                                value={newWelcomeMessageValue}
                                onChange={(_, data) => {
                                    setNewWelcomeMessageValue(data.value);
                                }}
                                rows={5}
                            />
                        </Field>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            appearance="primary"
                            disabled={
                                !isDataValid(
                                    flight,
                                    welcomeMessage,
                                    newFlightValue,
                                    newWelcomeMessageValue
                                )
                            }
                            onClick={(e) => {
                                onSaveBtnClick(e, {
                                    flight: newFlightValue,
                                    welcomeMessage: newWelcomeMessageValue,
                                });
                            }}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default CreateOrUpdateMessageDialogAction;
