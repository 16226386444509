import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import helpReducer from './features/help/helpSlice';
import findFlightPassengersReducer from './features/findFlightPassengers/findFlightPassengersSlice';
import depatureBoardReducer from './features/departureBoard/departureBoardSlice';
import flightsPassengersBoardReducer from './features/flightsPassengersBoard/flightsPassengersBoardSlice';
import configReducer from './features/config/configSlice';
import sendMessageToTeamReducer from './features/sendMessageToTeam/sendMessageToTeamSlice';
import sendPassengersMessageToTeamSlice from './features/sendPassengersMessageToTeam/sendPassengersMessageToTeamSlice';
import welcomeMessagesPanelReducer from './features/welcomeMessages/welcomeMessagesSlice';
import reportingBoardReducer from './features/reporting/reportingSlice';
import flightBaggageStatusReducer from './features/flightBaggageStatus/flightBaggageStatusSlice';
import broadcastMessageReportSlice from './features/broadcastMessageReport/broadcastMessageReportSlice';
import appConfigurationReducer from './features/appConfiguration/appConfigurationSlice';

export const store = configureStore({
  reducer: {
    appConfiguration: appConfigurationReducer,
    config: configReducer,
    help: helpReducer,
    departureBoard: depatureBoardReducer,
    sendMessageToTeam: sendMessageToTeamReducer,
    findFlightPassengers: findFlightPassengersReducer,
    flightBaggageStatus: flightBaggageStatusReducer,
    flightsPassengersBoard: flightsPassengersBoardReducer,
    sendPassengersMessageToTeam: sendPassengersMessageToTeamSlice,
    reportingBoard: reportingBoardReducer,
    broadcastMessageReport: broadcastMessageReportSlice,
    welcomeMessagesPanel: welcomeMessagesPanelReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;