import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogOpenChangeData,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    SelectTabData,
    SelectTabEvent,
    Skeleton,
    SkeletonItem,
    SkeletonProps,
    Tab,
    TabList,
    TabValue,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableColumnSizingOptions,
    TableRow,
    Text,
    createTableColumn,
    mergeClasses,
    useTableColumnSizing_unstable,
    useTableFeatures,
} from '@fluentui/react-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { flightBaggageStatusState } from '../../../store/features/flightBaggageStatus/flightBaggageStatusSlice';
import { Dismiss24Regular } from '@fluentui/react-icons';
import {
    baggageInfoDialogWidthBreakpoint,
    useBagInfoStyles,
    useBagListStyles,
    useBaggageInfoContentSkeletonStyles,
    useBaggageInfoDialogStyles,
    useDataRowStyles,
    useGroupBookingTableStyles,
    useReflightInfoStyles,
} from './BaggageInfoDialog.styles';
import { PassengerIcon } from '../../Icons/PassengerIcon';
import { BaggageSuccessIcon } from '../../Icons/BaggageSuccessIcon';
import { BaggageWarningIcon } from '../../Icons/BaggageWarningIcon';
import { Bag, BagReFlightLeg, Passenger } from '../../../model';
import { useSelector } from '../../../store/hooks';
import { TFunction } from 'i18next';
import useElementSize from '../../../hooks/useElementSize';

interface CustomerBaggageInfoDialogProps {
    isFlightTeamBoard: boolean;
    openActionRef?: React.RefObject<HTMLButtonElement>;
    onOpenChange?: (_: unknown, data: DialogOpenChangeData) => void;
    onSelectPassenger?: (customer: Passenger) => void;
    onNotifyClick?: ({
        flightTeamId,
        cabin,
        seatNumber,
    }: {
        flightTeamId?: string;
        cabin?: string;
        seatNumber?: string;
    }) => void;
}

function convertDateTime(dateString: string, timeString?: string): string {
    // Use default time '00:00:00' if timeString is undefined
    const defaultTime = '00:00:00';
    const isoString = timeString
        ? `${dateString}T${timeString}`
        : `${dateString}T${defaultTime}`;

    // Create a new Date object
    const date = new Date(isoString);

    // Extract day, month, hour, and minute
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Format the result as dd/mm hh:mm
    return `${day}/${month} ${hours}:${minutes}`;
}

function groupBookingTableConfig(
    styles: Record<string, string>,
    t: TFunction<'translation', undefined>
) {
    return [
        createTableColumn<Passenger>({
            columnId: 'firstName,lastName',
            renderHeaderCell: () => <></>,
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <span className={styles.customerDisplayName}>
                            <b>{`${item.firstName} ${item.lastName}`}</b>
                        </span>
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Passenger>({
            columnId: 'seatNumber',
            renderHeaderCell: () => <></>,
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <span className={styles.ceilContent}>
                            <span>
                                {t('TableHeader_Passenger_SeatNumber')}:
                            </span>
                            <b>{item.seatNumber}</b>
                        </span>
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Passenger>({
            columnId: 'cabin',
            renderHeaderCell: () => <></>,
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <span className={styles.ceilContent}>
                            <span>{t('TableHeader_Passenger_Cabin')}:</span>
                            <b>{item.cabin}</b>
                        </span>
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Passenger>({
            columnId: 'frequentFlyerTier',
            renderHeaderCell: () => <></>,
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <span className={styles.ceilContent}>
                            <span>
                                {t('TableHeader_Passenger_LoyaltyLevel')}:
                            </span>
                            <b>{item.frequentFlyerTier}</b>
                        </span>
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Passenger>({
            columnId: 'status',
            renderHeaderCell: () => <></>,
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.bags?.every((b) => !!b.notifiedOnUtc) ? (
                            <BaggageSuccessIcon width={16} height={16} />
                        ) : (
                            <BaggageWarningIcon width={16} height={16} />
                        )}
                    </TableCellLayout>
                );
            },
        }),
    ];
}

const columnSizingOptions: TableColumnSizingOptions = {
    'firstName,lastName': {
        minWidth: 28,
    },
    seatNumber: {
        minWidth: 20,
    },
    cabin: {
        minWidth: 25,
    },
    frequentFlyerTier: {
        minWidth: 26,
    },
    status: {
        minWidth: 1,
    },
};

const GroupBookingTable = React.memo(function GroupBookingTable({
    items,
    onSelect = () => {},
}: {
    items: Passenger[];
    onSelect?: (item: Passenger) => void;
}) {
    const { t } = useTranslation();

    const styles = useGroupBookingTableStyles();

    const columns = useMemo(
        () => groupBookingTableConfig(styles, t),
        [styles, t]
    );

    const { getRows, columnSizing_unstable: columnSizing } = useTableFeatures(
        {
            getRowId: (row) => row.id,
            columns,
            items,
        },
        [
            useTableColumnSizing_unstable({
                columnSizingOptions,
            }),
        ]
    );

    const rows = getRows((row) => {
        return {
            ...row,
            appearance: 'none' as const,
        };
    });

    return (
        <Table className={styles.root}>
            <TableBody>
                {rows &&
                    rows.map(({ item, appearance, rowId }) => (
                        <TableRow
                            className={styles.tableRow}
                            key={rowId}
                            appearance={appearance}
                            onClick={() => onSelect(item)}
                        >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.columnId}
                                    tabIndex={0}
                                    role="gridcell"
                                    style={{
                                        ...columnSizing.getTableCellProps(
                                            column.columnId
                                        )?.style,
                                        userSelect: 'none',
                                    }}
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
});

const DataRow = React.memo(function DataRow({
    label,
    value,
    rootClassName,
    labelClassName,
    valueClassName,
}: {
    label: string;
    value: string | number | JSX.Element | null | undefined;
    rootClassName?: string;
    labelClassName?: string;
    valueClassName?: string;
}) {
    const styles = useDataRowStyles();

    return (
        <div className={mergeClasses(styles.root, rootClassName)}>
            <Text className={mergeClasses(styles.dataRowLabel, labelClassName)}>
                {label}
            </Text>
            <Text className={mergeClasses(styles.dataRowValue, valueClassName)}>
                {value}
            </Text>
        </div>
    );
});

const ReflightInfo = React.memo(function ReflightInfo({
    bagReFlightLeg,
    className,
    reflightTitle,
}: {
    bagReFlightLeg: BagReFlightLeg;
    className?: string;
    reflightTitle?: string;
}) {
    const { t } = useTranslation();
    const styles = useReflightInfoStyles();
    return (
        <div className={className} data-title={reflightTitle}>
            <DataRow
                label={`${t(
                    'FlightBaggageStatus_BaggageInfoDialog_FlightNo'
                )}:`}
                value={bagReFlightLeg.flightNumber}
                rootClassName={styles.dataRow}
                labelClassName={styles.dataRowLabel}
            />
            <DataRow
                label={`${t('FlightBaggageStatus_BaggageInfoDialog_Route')}:`}
                value={`${bagReFlightLeg.departureAirportIata} - ${bagReFlightLeg.arrivalAirportIata}`}
                rootClassName={styles.dataRow}
                labelClassName={styles.dataRowLabel}
            />
            <DataRow
                label={`${t(
                    'FlightBaggageStatus_BaggageInfoDialog_DepartureDateTime'
                )}:`}
                value={convertDateTime(
                    bagReFlightLeg.scheduledDepartureDateString,
                    bagReFlightLeg.scheduledDepartureTimeString
                )}
                rootClassName={styles.dataRow}
                labelClassName={styles.dataRowLabel}
            />
            {!!bagReFlightLeg.scheduledArrivalDateString && (
                <DataRow
                    label={`${t(
                        'FlightBaggageStatus_BaggageInfoDialog_ArrivalDateTime'
                    )}:`}
                    value={convertDateTime(
                        bagReFlightLeg.scheduledArrivalDateString,
                        bagReFlightLeg.scheduledArrivalTimeString
                    )}
                    rootClassName={styles.dataRow}
                    labelClassName={styles.dataRowLabel}
                />
            )}
        </div>
    );
});

const BagInfo = React.memo(function BagInfo({
    bag,
    showNotificationSTatus,
    className,
}: {
    bag: Bag;
    className?: string;
    showNotificationSTatus?: boolean;
}) {
    const { t } = useTranslation();
    const styles = useBagInfoStyles();

    const icon = bag.notifiedOnUtc ? (
        <BaggageSuccessIcon width={16} height={16} />
    ) : (
        <BaggageWarningIcon width={16} height={16} />
    );

    return (
        <div className={mergeClasses(styles.root, className)}>
            <DataRow
                label={t('FlightBaggageStatus_BaggageInfoDialog_ReflightInfo')}
                value={showNotificationSTatus ? icon : ''}
                rootClassName={styles.dataRow}
                labelClassName={mergeClasses(
                    styles.dataRowLabel,
                    styles.dataRowLabelBold
                )}
            />
            <DataRow
                label={`${t(
                    'FlightBaggageStatus_BaggageInfoDialog_MissingBagReportNumber'
                )}:`}
                value={bag.missingBagReportNumber}
                rootClassName={styles.dataRow}
                labelClassName={styles.dataRowLabel}
            />
            {bag.baggageReFlightLegs &&
                bag.baggageReFlightLegs.map((b, i) => (
                    <ReflightInfo
                        className={styles.flight}
                        key={b.id}
                        bagReFlightLeg={b}
                        reflightTitle={`${t(
                            'FlightBaggageStatus_BaggageInfoDialog_Flight'
                        )} ${i + 1}`}
                    />
                ))}
        </div>
    );
});

const BagList = React.memo(function BagList({ bags }: { bags: Bag[] }) {
    const { t } = useTranslation();
    const styles = useBagListStyles();
    const ref = React.useRef<HTMLDivElement>(null);
    const { width = 0 } = useElementSize(ref);

    const [selectedBagId, setSelectedBagId] = React.useState<TabValue>(
        bags.length > 1 ? '' : bags[0]?.id
    );
    const selectedBag = bags.filter((b) => b.id === selectedBagId)[0];

    React.useEffect(() => {
        setSelectedBagId(bags[0]?.id);
    }, [bags, bags.length]);

    React.useEffect(() => {}, [width]);

    const onTabSelect = React.useCallback(
        (event: SelectTabEvent, data: SelectTabData) => {
            setSelectedBagId(data.value);
        },
        []
    );

    const isMobile = width < baggageInfoDialogWidthBreakpoint;
    const singleBaggage = bags.length === 1;

    const content = !isMobile ? (
        <div className={styles.tabListRoot}>
            <TabList
                className={styles.tabList}
                defaultSelectedValue={selectedBagId}
                vertical
                onTabSelect={onTabSelect}
            >
                {bags.map((bag) => (
                    <Tab
                        className={styles.tab}
                        value={bag.id}
                        as="button"
                        key={bag.id}
                    >
                        <Text>Bag tagging No: </Text>
                        <Text weight="semibold">{bag.bagTag}</Text>
                    </Tab>
                ))}
            </TabList>
            <div className={styles.panel}>
                {!!selectedBag && (
                    <BagInfo
                        className={styles.tabListBagInfo}
                        bag={selectedBag}
                    />
                )}
            </div>
        </div>
    ) : (
        !!bags && (
            <Accordion
                collapsible={bags.length > 1}
                onToggle={onTabSelect}
                defaultOpenItems={selectedBagId ?? []}
            >
                {bags.map((bag, i) => (
                    <AccordionItem key={bag.id} value={bag.id}>
                        <AccordionHeader
                            className={mergeClasses(
                                styles.accordionHeader,
                                bags.length > 1 ? '' : styles.hideExpandIcon
                            )}
                            expandIconPosition="end"
                        >
                            <Text as="h3" size={300}>
                                {`${t(
                                    'FlightBaggageStatus_BaggageInfoDialog_BagTaggingNo'
                                )}:`}
                            </Text>
                            <Text
                                className={styles.bagTag}
                                size={300}
                                weight="medium"
                            >
                                {bag.bagTag}
                            </Text>
                        </AccordionHeader>
                        <AccordionPanel className={styles.accordionPannel}>
                            {!!selectedBag && (
                                <BagInfo
                                    bag={selectedBag}
                                    showNotificationSTatus={singleBaggage}
                                />
                            )}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        )
    );

    return (
        <div className={styles.root} ref={ref}>
            <div className={styles.title}>
                <Text as="h3" size={300} weight="bold">
                    Bag List
                </Text>
                {(!singleBaggage || !isMobile) &&
                    (bags?.every((b) => !!b.notifiedOnUtc) ? (
                        <BaggageSuccessIcon width={16} height={16} />
                    ) : (
                        <BaggageWarningIcon width={16} height={16} />
                    ))}
            </div>
            {content}
        </div>
    );
});

const BaggageInfoContentSkeleton = React.memo(
    function BaggageInfoContentSkeleton(props: Partial<SkeletonProps>) {
        const styles = useBaggageInfoContentSkeletonStyles();
        return (
            <Skeleton className={styles.root} {...props}>
                <div className={styles.groupBooking}>
                    <SkeletonItem shape="rectangle" size={36} />
                </div>
                <div className={styles.bagList}>
                    <SkeletonItem
                        className={styles.bagListTitle}
                        shape="rectangle"
                        size={36}
                    />
                    <SkeletonItem
                        className={styles.bagListItem1}
                        shape="rectangle"
                        size={32}
                    />
                    <SkeletonItem
                        className={styles.bagListItem2}
                        shape="rectangle"
                        size={32}
                    />
                    <SkeletonItem
                        className={styles.bagReFlightInfo}
                        shape="rectangle"
                    />
                </div>
            </Skeleton>
        );
    }
);

const BaggageInfoDialogAction = React.memo(
    function CustomerBaggageInfoDialogAction({
        isFlightTeamBoard,
        openActionRef,
        onOpenChange,
        onSelectPassenger,
        onNotifyClick,
    }: CustomerBaggageInfoDialogProps) {
        const { t } = useTranslation();
        const styles = useBaggageInfoDialogStyles();

        const { customerInfo } = useSelector(flightBaggageStatusState);

        const onSelectPassengerCallback = React.useCallback(
            (passenger: Passenger) => {
                if (onSelectPassenger) {
                    onSelectPassenger(passenger);
                }
            },
            [onSelectPassenger]
        );

        const onOpenChangeCallback = React.useCallback(
            (_: unknown, data: DialogOpenChangeData) => {
                if (onOpenChange) {
                    onOpenChange(_, data);
                }
            },
            [onOpenChange]
        );

        const onNotifyClickCallback = React.useCallback(() => {
            if (customerInfo && onNotifyClick) {
                onNotifyClick({
                    flightTeamId: customerInfo.flightTeamId,
                    cabin: customerInfo.cabin,
                    seatNumber: customerInfo.seatNumber,
                });
            }
        }, [customerInfo, onNotifyClick]);

        const groupBooking = useMemo(
            () =>
                customerInfo?.groupBooking?.filter(
                    (p) => p.seatNumber !== customerInfo.seatNumber
                ),
            [customerInfo?.groupBooking, customerInfo?.seatNumber]
        );

        const selectedCustomerBags =
            customerInfo?.allBagsOnPNR?.filter(
                (b) =>
                    b.flightTeamId === customerInfo?.flightTeamId &&
                    b.cabin === customerInfo.cabin &&
                    b.seatNumber === customerInfo?.seatNumber
            ) ?? [];

        return (
            <Dialog modalType="non-modal" onOpenChange={onOpenChangeCallback}>
                <DialogTrigger action="open" disableButtonEnhancement>
                    <Button ref={openActionRef} style={{ display: 'none' }} />
                </DialogTrigger>
                <DialogSurface className={styles.dialogSurface}>
                    <DialogBody className={styles.dialogBody}>
                        <DialogTitle
                            className={styles.dialogTitle}
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label="close"
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <div className={styles.dialogTitleIcon}>
                                <PassengerIcon />
                            </div>
                            <div>
                                <div className={styles.dialogTitleCustomerName}>
                                    {customerInfo?.firstName}{' '}
                                    {customerInfo?.lastName}
                                </div>
                                <div className={styles.dialogTitleCustomerInfo}>
                                    <span>
                                        {`${t(
                                            'FlightBaggageStatus_BaggageInfoDialog_Seat'
                                        )}: `}
                                        <b>{customerInfo?.seatNumber}</b>
                                    </span>
                                    <span>
                                        {`${t(
                                            'FlightBaggageStatus_BaggageInfoDialog_Cabin'
                                        )}: `}
                                        <b>{customerInfo?.cabin}</b>
                                    </span>
                                    <span>
                                        {`${t(
                                            'FlightBaggageStatus_BaggageInfoDialog_Tier'
                                        )}: `}
                                        <b>{customerInfo?.frequentFlyerTier}</b>
                                    </span>
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent className={styles.dialogContent}>
                            {customerInfo?.loadStatus === 'idle' ? (
                                <>
                                    {!!groupBooking?.length && (
                                        <Accordion collapsible>
                                            <AccordionItem value="GroupBooking">
                                                <AccordionHeader
                                                    className={
                                                        styles.groupBookingHeader
                                                    }
                                                    expandIconPosition="end"
                                                >
                                                    <Text
                                                        as="h3"
                                                        size={300}
                                                        weight="bold"
                                                    >
                                                        {t(
                                                            'FlightBaggageStatus_GroupBooking'
                                                        )}
                                                    </Text>
                                                </AccordionHeader>
                                                <AccordionPanel
                                                    style={{ margin: 0 }}
                                                >
                                                    <GroupBookingTable
                                                        items={groupBooking}
                                                        onSelect={
                                                            onSelectPassengerCallback
                                                        }
                                                    />
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    )}
                                    <BagList bags={selectedCustomerBags} />
                                </>
                            ) : (
                                <BaggageInfoContentSkeleton />
                            )}
                        </DialogContent>
                        <DialogActions
                            position="end"
                            className={styles.actions}
                        >
                            <DialogTrigger
                                action="close"
                                disableButtonEnhancement
                            >
                                <Button appearance="secondary">
                                    {t(
                                        'FlightBaggageStatus_BaggageInfoDialog_CloseButton'
                                    )}
                                </Button>
                            </DialogTrigger>
                            {isFlightTeamBoard && (
                                <DialogTrigger
                                    action="close"
                                    disableButtonEnhancement
                                >
                                    <Button
                                        appearance="primary"
                                        disabled={selectedCustomerBags.every(
                                            (x) => !!x.notifiedOnUtc
                                        )}
                                        onClick={onNotifyClickCallback}
                                    >
                                        {t(
                                            'FlightBaggageStatus_BaggageInfoDialog_CustomerNotifiedButton'
                                        )}
                                    </Button>
                                </DialogTrigger>
                            )}
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    }
);

export default BaggageInfoDialogAction;
