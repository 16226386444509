
export enum BroadcastRequestSource {
    DepartureBoard,
}

export interface BroadcastMessageData {
    flightTeamIds: string[];
    title: string;
    content: string;
    requestSource: BroadcastRequestSource;
}