import { Bag } from ".";
import { Airport } from "./Airport";
import { FlightTeamCheckin } from "./FlightTeamCheckin";
import { Passenger } from "./Passanger";

export enum FlightDepartureStatus {
  OnTime = 'OnTime',
  Delayed = 'Delayed',
  Departed = 'Departed',
  Cancelled = 'Cancelled'
}

export enum FlightTeamStatus {
  New = 'New',
  Active = 'Active',
  Archived = 'Archived',
  Expired = 'Expired',
  Reused = 'Reused'
}

export type FlightTeam = {
  readonly id: string;
  readonly airlineIata: string;
  readonly flightNumber: string;
  readonly iataFlightIdentifier: string;
  readonly departureStatus: FlightDepartureStatus;
  readonly departureAirportIata: string;
  readonly departureAirport: Airport;
  readonly scheduledDepartureTime?: string;
  readonly scheduledDepartureTimeUtc?: string;

  readonly estimatedDepartureTime?: string;
  readonly estimatedDepartureTimeUtc?: string;

  readonly actualDepartureTime?: string;
  readonly actualDepartureTimeUtc?: string;

  readonly departureGate?: string;

  readonly arrivalAirportIata: string;
  readonly arrivalAirport: Airport;
  readonly scheduledArrivalTime?: string;
  readonly scheduledArrivalTimeUtc?: string;
  readonly estimatedArrivalTime?: string;
  readonly estimatedArrivalTimeUtc?: string;
  readonly actualArrivalTime?: string;
  readonly actualArrivalTimeUtc?: string;

  readonly teamId?: string;
  readonly teamConversationId?: string;
  readonly welcomeMessageId?: string;

  readonly lastUpdateOnUtc: string;

  readonly status: FlightTeamStatus;

  readonly checkIns: FlightTeamCheckin[];
  readonly passengers: Passenger[];
  readonly bags: Bag[];
}