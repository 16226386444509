import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DialogOpenChangeData,
    DialogOpenChangeEvent,
    DialogOpenChangeEventHandler,
} from '@fluentui/react-dialog';
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Dropdown,
    Field,
    Option,
} from '@fluentui/react-components';
import {
    DatePicker,
    DatePickerValidationResultData,
    defaultDatePickerErrorStrings,
} from '@fluentui/react-datepicker-compat';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { useNewReportDialogActionStyles } from './NewReportDialogAction.styles';
import { addDays, addMonths } from '@fluentui/react-calendar-compat';
import { ReportParametersInputModel, ReportType } from '../../../model';

interface NewReportDialogActionProps {
    open: boolean;
    onOpenChange?: DialogOpenChangeEventHandler;
    onSubmit: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        data: ReportParametersInputModel
    ) => void;
}

const today = new Date();
const fromMinDate = addDays(addMonths(today, -6), 1);
const fromMaxDate = addDays(today, 1);
const toMaxDate = addDays(today, 1);

const onFormatDate = (date?: Date): string => {
    return !date
        ? ''
        : `${date?.getMonth() + 1}/${date?.getDate()}/${date?.getFullYear()}`;
};

const excludedReportTypes = new Set<ReportType>([ReportType.BroadcastMessagingReport]);
const reportTypes = Object.keys(ReportType).filter(x => !excludedReportTypes.has(ReportType[x as keyof typeof ReportType]));

const NewReportDialogAction = ({
    open,
    onOpenChange,
    onSubmit,
}: NewReportDialogActionProps) => {
    const { t } = useTranslation();
    const styles = useNewReportDialogActionStyles();

    const [reportType, setReportType] = useState<
        ReportType | undefined | null
    >();
    const [fromDate, setFromDate] = useState<Date | undefined | null>();
    const [toDate, setToDate] = useState<Date | undefined | null>();

    const [startDateError, setStartDateError] =
        React.useState<DatePickerValidationResultData['error']>(undefined);

    const [endDateError, setEndDateError] =
        React.useState<DatePickerValidationResultData['error']>(undefined);

    const isReportDataValid = useCallback(() => {
        return (
            reportType && fromDate && toDate && !startDateError && !endDateError
        );
    }, [endDateError, fromDate, reportType, startDateError, toDate]);

    const resetDialogState = useCallback(() => {
        setReportType(undefined);
        setFromDate(undefined);
        setToDate(undefined);
        setStartDateError(undefined);
        setEndDateError(undefined);
    }, []);

    return (
        <Dialog
            open={open}
            onOpenChange={(
                event: DialogOpenChangeEvent,
                data: DialogOpenChangeData
            ) => {
                resetDialogState();
                if (!!onOpenChange) {
                    onOpenChange(event, data);
                }
            }}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        {t('NewReportDialog_Title')}
                    </DialogTitle>
                    <DialogContent>
                        <Field
                            required
                            label={t('NewReportDialog_ReportType')}
                            className={styles.control}
                        >
                            <Dropdown
                                onOptionSelect={(_, data) => {
                                    setReportType(
                                        ReportType[
                                            data.optionValue as keyof typeof ReportType
                                        ]
                                    );
                                }}
                            >
                                {reportTypes.map((key) => (
                                    <Option
                                        key={`option_${key}`}
                                        text={t(`ReportType_${key}`) ?? ''}
                                        value={key}
                                    >
                                        {t(`ReportType_${key}`)}
                                    </Option>
                                ))}
                            </Dropdown>
                        </Field>
                        <Field
                            required
                            label={t('NewReportDialog_ReportStartDate')}
                            validationMessage={
                                startDateError &&
                                defaultDatePickerErrorStrings[startDateError]
                            }
                            className={styles.control}
                        >
                            <DatePicker
                                minDate={fromMinDate}
                                maxDate={fromMaxDate}
                                formatDate={onFormatDate}
                                placeholder="Select a report start date"
                                allowTextInput
                                onChange={(_, data) => {
                                    setFromDate(new Date(data.value));
                                }}
                                onSelectDate={(date) => {
                                    setFromDate(date);
                                }}
                                onValidationResult={(data) =>
                                    setStartDateError(data.error)
                                }
                            />
                        </Field>
                        <Field
                            required
                            label={t('NewReportDialog_ReportEndDate')}
                            validationMessage={
                                endDateError &&
                                defaultDatePickerErrorStrings[endDateError]
                            }
                            className={styles.control}
                        >
                            <DatePicker
                                minDate={fromDate ?? fromMinDate}
                                maxDate={toMaxDate}
                                formatDate={onFormatDate}
                                placeholder="Select a report end date"
                                allowTextInput
                                onChange={(_, data) => {
                                    setToDate(addDays(new Date(data.value), 1));
                                }}
                                onSelectDate={(date) => {
                                    if(date) {
                                        date = addDays(date, 1)
                                    }
                                    setToDate(date);
                                }}
                                onValidationResult={(data) =>
                                    setEndDateError(data.error)
                                }
                            />
                        </Field>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                disabled={!isReportDataValid()}
                                appearance="primary"
                                onClick={(e) => {
                                    onSubmit(e, {
                                        type: reportType!,
                                        from: fromDate!,
                                        to: toDate!,
                                    });
                                }}
                            >
                                {t('NewReportDialog_ActionCreate')}
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default NewReportDialogAction;
