
import apiClient from './apiClient';
import { BaseApiUrl } from '../configVariables';

export const getAuthenticationConsentMetadata = async (windowLocationOriginDomain: string, login_hint: string, state: string): Promise<any> => {
  const url = `${BaseApiUrl}/authenticationMetadata/consentUrl`
    + `?windowLocationOriginDomain=${decodeURIComponent(windowLocationOriginDomain)}`
    + `&loginhint=${login_hint}&state=${state}`;
  return await apiClient.get(url, false, false);
}
