import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import { unregister } from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';

// Get the appnlication-wide store instance, prepopulating with state from the server where available.

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<Provider store={store}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
</Provider>);

unregister(); // service worker is not needed
