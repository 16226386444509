import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useBroadcastMessagingDialogPageStyles = makeStyles({
    root: {
        ...shorthands.padding('1rem', tokens.spacingHorizontalXXXL),
        ...shorthands.gap(tokens.spacingVerticalM),
        alignSelf: 'flex-start',
        justifyItems: 'start',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
    },
    actions: {
        marginTop: 'auto',
        alignSelf: 'end',
    },
    formField: {
        display: 'flex',
        flexDirection: 'column',
        '& > label': {
            marginBottom: tokens.spacingVerticalXS,
        },
    },
    warning: {
        fontStyle: 'italic',
    },
});