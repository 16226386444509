import { Button, mergeClasses } from '@fluentui/react-components';
import {
    ArrowNext24Regular,
    ArrowPrevious24Regular,
    MoreHorizontal24Regular,
} from '@fluentui/react-icons';
import React, { useCallback } from 'react';
import { useMemo } from 'react';
import usePagination from '../../hooks/usePagination';
import { usePaginationStyles } from './Pagination.styles';

export interface IPaginationProps {
    className?: string;
    totalItems: number;
    pageSize: number;
    currentPage: number;
    onChange?: (page: number) => void;
    actions?: React.ReactNode;
}

const Pagination = (props: IPaginationProps) => {
    const { className, totalItems, pageSize, currentPage, onChange, actions } =
        props;

    const styles = usePaginationStyles();

    const pagesCount = useMemo(
        () => (pageSize > 0 ? Math.ceil(totalItems / pageSize) : 1),
        [totalItems, pageSize]
    );

    const onChangeCallback = useCallback(
        (_: any, page: number) => {
            if (onChange && page !== currentPage) {
                onChange(page);
            }
        },
        [onChange, currentPage]
    );

    var { items } = usePagination({
        count: pagesCount,
        page: currentPage,
        hideNextButton: true,
        hidePrevButton: true,
        onChange: onChangeCallback,
    });

    if (pagesCount <= 1 && !actions) {
        return null;
    }

    return (
        <div className={mergeClasses(styles.root, className)}>
            <div className={styles.group}>{actions}</div>
            <div className={styles.group}>
                {pagesCount > 1 &&
                    items.map(({ page, type, selected, ...item }, index) => {
                        let children: React.ReactElement | null = null;
                        let itemClassName: string | undefined = undefined;
                        if (
                            type === 'start-ellipsis' ||
                            type === 'end-ellipsis'
                        ) {
                            // Ellipsis for when we have too many pages to show
                            children = <MoreHorizontal24Regular />;
                            itemClassName = styles.ellipsis;
                        } else if (type === 'page') {
                            children = (
                                // Page components (1, 2, 3, ...)
                                <Button
                                    appearance={
                                        selected ? 'primary' : 'secondary'
                                    }
                                    {...item}
                                >
                                    {page}
                                </Button>
                            );
                        } else {
                            // Previous/Next buttons
                            const icon =
                                type === 'previous' ? (
                                    <ArrowPrevious24Regular />
                                ) : (
                                    <ArrowNext24Regular />
                                );
                            children = <Button {...item} icon={icon} />;
                        }

                        // Individual component wrapper
                        return (
                            <div
                                key={index}
                                className={mergeClasses(
                                    styles.item,
                                    itemClassName
                                )}
                            >
                                {children}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default Pagination;
