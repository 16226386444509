import { makeStyles, tokens } from '@fluentui/react-components';

export const useMishandledBagageOnFlightPageStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    toolbar: {
        justifyContent: 'space-between',
        boxShadow: tokens.shadow16,
    },
    table: {
        marginTop: tokens.spacingVerticalM,
        overflow: 'auto',
        flexGrow: 1,
        height: 'fit-content',
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '250px',
    },
    filtersHeader: {
        alignSelf: 'flex-end',
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalS,
    },
    filter: {
        display: 'grid',
        gridRowGap: tokens.spacingHorizontalS,
    },
    spiner: {
        position: 'absolute',
        minWidth: '100%',
        top: '50%',
    }
});
