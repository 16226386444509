import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const usePaginationStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        columnGap: tokens.spacingHorizontalMNudge,
        boxShadow: tokens.shadow16,
        ...shorthands.padding(
            tokens.spacingVerticalM,
            tokens.spacingHorizontalXL
        ),
    },
    group: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
		...shorthands.gap(tokens.spacingHorizontalM),
    },
    item: {
        '& > button': {
            minWidth: '32px',
        },
    },
    ellipsis: {
        display: 'flex',
        alignItems: 'center',
    },
});
