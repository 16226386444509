export enum ReportType {
    ConversationReport = 'ConversationReport',
    BagMessagesReport = 'BagMessagesReport',
    BroadcastMessagingReport = 'BroadcastMessagingReport',
}

export enum ReportStatus {
    Pending = 'Pending',
    ReportGeneration = 'ReportGeneration',
    AwaitingUploadContext = 'AwaitingUploadContext',
    Rejected = 'Rejected',
    Completed = 'Completed',
    Failed = 'Failed',
}

export interface ReportParameters {
    from: Date;
    to: Date;
}

export interface ReportModel {
    id: string;
    aadUserId: string;
    type: ReportType;
    status: ReportStatus;
    fileName: string;
    parameters: ReportParameters;
    createdTimeUtc: Date;
}
