import {
    Button,
    Dropdown,
    Input,
    InputOnChangeData,
    Label,
    Link,
    Option,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    Toolbar,
    ToolbarButton,
    ToolbarGroup,
    useId,
} from '@fluentui/react-components';
import React, { useCallback, useMemo } from 'react';
import { useMishandledBagageToolbarStyles } from './FlightBaggageToolbar.styles';
import {
    Dismiss24Regular,
    Filter24Regular,
    Search24Regular,
} from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { FilterBaggageStatusFiltersState } from '../../../store/features/flightBaggageStatus/flightBaggageStatusSlice';

interface FlightBaggageToolbarProps {
    cabins: string[];
    loyalties: string[];
    baggageStatuses: string[];
    onFilterUpdate: (filter: FilterBaggageStatusFiltersState) => void;
    onSearchQueryUpdate: (query: string) => void;
}

const FlightBaggageStatusToolbar = React.memo(
    function FlightBaggageStatusToolbar({
        cabins,
        loyalties,
        baggageStatuses,
        onFilterUpdate,
        onSearchQueryUpdate,
    }: FlightBaggageToolbarProps) {
        const { t } = useTranslation();
        const styles = useMishandledBagageToolbarStyles();

        const [filters, setFilters] =
            React.useState<FilterBaggageStatusFiltersState>(
                {} as FilterBaggageStatusFiltersState
            );

        const [searchQuery, setSearchQuery] = React.useState<string>('');

        const onClearCallback = useCallback(() => {
            setFilters({});
            onFilterUpdate({});
        }, [onFilterUpdate]);

        const onFilterUpdateCallback = useCallback(
            (filter: FilterBaggageStatusFiltersState) => {
                const updatedFilter = { ...filters, ...filter };
                setFilters(updatedFilter);
                onFilterUpdate(updatedFilter);
            },
            [filters, onFilterUpdate]
        );

        const onQueryTextChange = useCallback(
            (
                _: React.ChangeEvent<HTMLInputElement> | null,
                data: InputOnChangeData
            ) => {
                setSearchQuery(data.value);
                onSearchQueryUpdate(data.value);
            },
            [onSearchQueryUpdate]
        );

        const applyedFiltesCount = useMemo(() => {
            if (!filters) return 0;

            return Object.values(filters).reduce(
                (count, value) => count + value.length,
                0
            );
        }, [filters]);

        const buttonFilterLabel = useMemo(() => {
            let label = t('Button_Filter');
            if (applyedFiltesCount > 0) {
                label = `${label} (${applyedFiltesCount})`;
            }
            return label;
        }, [applyedFiltesCount, t]);

        const statusFilterId = useId();
        const cabinFilterId = useId();
        const loyaltyFilterId = useId();

        return (
            <Toolbar aria-label="Flights" className={styles.toolbar}>
                <ToolbarGroup role="presentation"></ToolbarGroup>
                <ToolbarGroup role="presentation">
                    <Popover>
                        <PopoverTrigger disableButtonEnhancement>
                            <ToolbarButton
                                aria-label={t('Button_Filter')!}
                                appearance="subtle"
                                icon={<Filter24Regular />}
                            >
                                {buttonFilterLabel}
                            </ToolbarButton>
                        </PopoverTrigger>
                        <PopoverSurface>
                            <div className={styles.filtersContainer}>
                                <div className={styles.filtersHeader}>
                                    <Link
                                        appearance="subtle"
                                        disabled={applyedFiltesCount === 0}
                                        onClick={onClearCallback}
                                    >
                                        {t('Button_Filter_ClearAll')}
                                    </Link>
                                </div>

                                <div className={styles.filters}>
                                    <div className={styles.filter}>
                                        <Label htmlFor={cabinFilterId}>
                                            Cabins
                                        </Label>
                                        <Dropdown
                                            aria-labelledby={cabinFilterId}
                                            multiselect
                                            value={
                                                filters.cabins?.join(', ') ?? ''
                                            }
                                            selectedOptions={
                                                filters.cabins ?? []
                                            }
                                            onOptionSelect={(_, data) =>
                                                onFilterUpdateCallback({
                                                    cabins: [
                                                        ...data.selectedOptions,
                                                    ],
                                                })
                                            }
                                        >
                                            {cabins.map((cabin) => (
                                                <Option
                                                    key={cabin}
                                                    value={cabin}
                                                    text={cabin}
                                                >
                                                    {cabin}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    <div className={styles.filter}>
                                        <Label htmlFor={statusFilterId}>
                                            Status
                                        </Label>
                                        <Dropdown
                                            aria-labelledby={statusFilterId}
                                            clearable
                                            value={
                                                filters?.baggageStatuses && filters?.baggageStatuses[0]
                                                    ? t(
                                                        `FlightBaggageStatus_${filters?.baggageStatuses[0]}`
                                                    ) ?? ''
                                                    : ''
                                            }
                                            selectedOptions={
                                                filters?.baggageStatuses ?? []
                                            }
                                            onOptionSelect={(_, data) =>
                                                onFilterUpdateCallback({
                                                    baggageStatuses:
                                                        data.selectedOptions,
                                                })
                                            }
                                        >
                                            {baggageStatuses.map((status) => (
                                                <Option
                                                    key={status}
                                                    value={status}
                                                >
                                                    {t(
                                                        `FlightBaggageStatus_${status}`
                                                    ) ?? ''}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    <div className={styles.filter}>
                                        <Label htmlFor={loyaltyFilterId}>
                                            Loyalty
                                        </Label>
                                        <Dropdown
                                            aria-labelledby={loyaltyFilterId}
                                            multiselect
                                            value={
                                                filters.loyalties?.join(', ') ??
                                                ''
                                            }
                                            selectedOptions={
                                                filters.loyalties ?? []
                                            }
                                            onOptionSelect={(_, data) =>
                                                onFilterUpdateCallback({
                                                    loyalties: [
                                                        ...data.selectedOptions,
                                                    ],
                                                })
                                            }
                                        >
                                            {loyalties.map((loyalty) => (
                                                <Option
                                                    key={loyalty}
                                                    value={loyalty}
                                                >
                                                    {loyalty}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </PopoverSurface>
                    </Popover>
                    <Input
                        placeholder={t('SearchFlightsInputPlaceholder')!}
                        size="small"
                        style={{ maxWidth: '200px' }}
                        contentAfter={
                            <Button
                                appearance="transparent"
                                onClick={() =>
                                    onQueryTextChange(null, { value: '' })
                                }
                                icon={
                                    !searchQuery ? (
                                        <Search24Regular />
                                    ) : (
                                        <Dismiss24Regular />
                                    )
                                }
                            />
                        }
                        onChange={onQueryTextChange}
                        value={searchQuery}
                    />
                </ToolbarGroup>
            </Toolbar>
        );
    }
);

export default FlightBaggageStatusToolbar;
