import { BaseApiUrl } from "../../../configVariables";
import { TableContinuationToken, WelcomeMessageEntity } from "../../../model";
import apiClient from "../../../utils/apiClient";

export interface WelcomeMessagesResponse {
    search: string;
    items: WelcomeMessageEntity[];
    nextPageToken: TableContinuationToken;
}

export const fetchWelcomeMessages = async (query: string = '') : Promise<WelcomeMessagesResponse> => {
    const url = `${BaseApiUrl}/appsettings/welcomemessages${query}`;
    const result = await apiClient.get<WelcomeMessagesResponse>(url);
    return result.data;
}

export const createWelcomeMessage = async (data: WelcomeMessageEntity) : Promise<WelcomeMessageEntity> => {
    const url = `${BaseApiUrl}/appsettings/welcomemessages`;
    const result = await apiClient.post(url, data);
    return result.data;
}

export const updateWelcomeMessage = async (data: WelcomeMessageEntity) : Promise<WelcomeMessageEntity> => {
    const url = `${BaseApiUrl}/appsettings/welcomemessages`;
    const result = await apiClient.put(url, data);
    return result.data;
}

export const deleteWelcomeMessage = async (rowKey: string) : Promise<void> => {
    const url = `${BaseApiUrl}/appsettings/welcomemessages/${rowKey}`;
    await apiClient.delete(url);
}