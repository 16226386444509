import { BaseOdataUrl } from '../../../configVariables';
import { Bag, ODataResult, Passenger } from '../../../model';
import apiClient from '../../../utils/apiClient';

export const fetchPassengersApiCall = async (
    query?: string
): Promise<ODataResult<Passenger[]>> => {
    const url = `${BaseOdataUrl}/passengers${query}`;
    const result = await apiClient.get<ODataResult<Passenger[]>>(url);
    return result.data;
};

export const fetchBagsApiCall = async (
    query?: string
): Promise<ODataResult<Bag[]>> => {
    const url = `${BaseOdataUrl}/bags${query}`;
    const result = await apiClient.get<ODataResult<Bag[]>>(url);
    return result.data;
};

export const postNotifyApiCall = async ({
    flightTeamId,
    cabin,
    seatNumber,
}: {
    flightTeamId?: string;
    cabin?: string;
    seatNumber?: string;
}): Promise<{}> => {
    const url = `${BaseOdataUrl}/passengers/${flightTeamId}/${cabin}/${seatNumber}/notify`;
    const result = await apiClient.post<{}>(url);
    return result.data;
};
