import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    Bag,
    IPagination,
    ISorting,
    ODataResult,
    Passenger,
} from '../../../model';
import { RootState } from '../..';
import { convertToOdataOptions } from '../../../utils/odata';
import buildQuery, { Filter, Select } from 'odata-query';
import {
    fetchBagsApiCall,
    fetchPassengersApiCall,
    postNotifyApiCall,
} from './flightBaggageStatusAPI';

export interface FilterBaggageStatusFiltersState {
    cabins?: string[];
    loyalties?: string[];
    baggageStatuses?: string[];
}

export interface FlightBaggageStatusQueryParamsState {
    queryText?: string;
    filters?: FilterBaggageStatusFiltersState;
}

export interface CustomerInfo {
    loadStatus: 'idle' | 'loading' | 'failed';
    firstName?: string;
    lastName?: string;
    flightTeamId?: string;
    cabin?: string;
    seatNumber?: string;
    frequentFlyerTier?: string;
    pnr?: string;
    groupBooking?: Passenger[];
    allBagsOnPNR?: Bag[];
}

export interface FlightBaggageStatus {
    flightTeamId?: string;
    items: Passenger[];
    cabins: string[];
    loyalties: string[];
    pagination: IPagination;
    sorting: ISorting;
    loadStatus: 'idle' | 'loading' | 'failed';
    queryParams: FlightBaggageStatusQueryParamsState;
    customerInfo?: CustomerInfo;
}

const initialState: FlightBaggageStatus = {
    loadStatus: 'loading',
    items: [],
    cabins: [],
    loyalties: [],
    pagination: {
        currentPage: 1,
        pageSize: 20,
        totalCount: 0,
    },
    sorting: {
        sortBy: 'flightTeam/scheduledDepartureTimeUtc',
        sortByDescending: true,
    },
    queryParams: {},
};

const getFlightBaggageStatusQuery = (state: RootState) => {
    const {
        flightTeamId,
        sorting,
        pagination,
        queryParams: { filters, queryText },
    } = state.flightBaggageStatus;

    const options = convertToOdataOptions<Passenger>({
        count: pagination.currentPage === 1 && pagination.pageSize > 0,
        pagination: pagination,
        sorting: sorting,
    });

    options.expand = {
        bags: [
            {
                select: [
                    'bagTag',
                    'passengerNameRecord',
                    'notifiedOnUtc',
                ],
            },
        ],
        flightTeam: {},
    };

    const andParts: Partial<Filter<Passenger>>[] = [];

    if (flightTeamId) {
        andParts.push({
            flightTeamId: {
                eq: {
                    type: 'guid',
                    value: flightTeamId,
                },
            },
        });
    }

    if (filters?.cabins?.length) {
        andParts.push({
            cabin: {
                in: filters.cabins,
            },
        });
    }

    if (filters?.loyalties?.length) {
        andParts.push({
            frequentFlyerTier: {
                in: filters.loyalties,
            },
        });
    }

    if (filters?.baggageStatuses?.length) {
        if (filters?.baggageStatuses.some((status) => status === 'Notified')) {
            andParts.push({
                bags: {
                    all: {
                        notifiedOnUtc: {
                            ne: null,
                        },
                    },
                },
            });
        }

        if (filters?.baggageStatuses.some((status) => status === 'None')) {
            andParts.push({
                bags: {
                    any: {
                        notifiedOnUtc: {
                            eq: null,
                        },
                    },
                },
            });
        }
    }

    if (queryText) {
        andParts.push({
            or: [
                { firstName: { contains: queryText } },
                { lastName: { contains: queryText } },
                { seatNumber: { contains: queryText } },
                ...(!!flightTeamId
                    ? []
                    : [
                        {
                            'flightTeam/iataFlightIdentifier': {
                                contains: queryText,
                            },
                        },
                    ]),
            ],
        });
    }

    options.filter = {
        and: [...andParts],
    };

    const query = buildQuery(options);
    return query;
};

export const initOnFlightBaggageStatusPageLoad = createAsyncThunk<
    ODataResult<Passenger[]>,
    void,
    { state: RootState }
>(
    '/flightBaggageStatus/initOnFlightBaggageStatusPageLoad',
    async (_, { getState }) => {
        return await fetchPassengersApiCall(
            getFlightBaggageStatusQuery(getState())
        );
    }
);

export const loadFlightBaggageStatus = createAsyncThunk<
    ODataResult<Passenger[]>,
    void,
    { state: RootState }
>('flightBaggageStatus/loadFlightBaggageStatus', async (_, { getState }) => {
    return await fetchPassengersApiCall(
        getFlightBaggageStatusQuery(getState())
    );
});

export const loadCustomerBaggageInfoDialogData = createAsyncThunk<
    ODataResult<Bag[]>,
    void,
    { state: RootState }
>('flightBaggageStatus/loadCustomerBaggageInfo', async (_, { getState }) => {
    const { customerInfo } = getState().flightBaggageStatus;
    const query = buildQuery<Bag>({
        filter: {
            and: [
                {
                    passengerNameRecord: {
                        eq: customerInfo?.pnr,
                    },
                },
                {
                    flightTeamId: {
                        eq: {
                            type: 'guid',
                            value: customerInfo?.flightTeamId,
                        },
                    },
                },
            ],
        },
        expand: {
            baggageReFlightLegs: {},
            passenger: {
                select: [
                    'firstName',
                    'lastName',
                    'flightTeamId',
                    'seatNumber',
                    'cabin',
                    'frequentFlyerTier',
                ] as Select<Passenger | undefined>,
            },
        },
        orderBy: 'bagTag',
    });

    return await fetchBagsApiCall(query);
});

export const notifyCustomer = createAsyncThunk<
    {},
    {
        flightTeamId?: string;
        cabin?: string;
        seatNumber?: string;
    },
    { state: RootState }
>(
    'flightBaggageStatus/notifyCustomer',
    async ({ flightTeamId, cabin, seatNumber }, { getState }) => {
        return await postNotifyApiCall({ flightTeamId, cabin, seatNumber });
    }
);

export const flightBaggageStatusSlice = createSlice({
    name: 'flightBaggageStatus',
    initialState,
    reducers: {
        setFlightTeamId: (state, action: PayloadAction<string>) => {
            state.flightTeamId = action.payload;
            state.pagination.pageSize = 0;
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage =
                action.payload < 1 ? 1 : action.payload;
        },
        toggleSort: (state, action: PayloadAction<string>) => {
            if (state.sorting.sortBy === action.payload) {
                state.sorting.sortByDescending =
                    !state.sorting.sortByDescending;
            } else {
                state.sorting.sortBy = action.payload;
                state.sorting.sortByDescending = false;
            }
        },
        setQueryText: (state, action: PayloadAction<string>) => {
            state.queryParams.queryText = action.payload;
            state.pagination.currentPage = 1;
        },
        setFilters: (
            state,
            action: PayloadAction<FilterBaggageStatusFiltersState>
        ) => {
            state.queryParams.filters = action.payload;
            state.pagination.currentPage = 1;
        },
        selectPassenger: (
            state,
            action: PayloadAction<Passenger & { pnr?: string }>
        ) => {
            const pnr =
                action.payload.pnr ??
                (action.payload.bags && action.payload.bags[0] ? action.payload.bags[0].passengerNameRecord : undefined);
            const passengerBaggageInfo: CustomerInfo = {
                loadStatus: 'loading',
                ...action.payload,
                pnr,
            };
            state.customerInfo = passengerBaggageInfo;
        },
        clearPassengerBaggageInfo: (state) => {
            state.customerInfo = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                loadCustomerBaggageInfoDialogData.pending,
                ({ customerInfo }) => {
                    customerInfo = {
                        ...(customerInfo ?? {}),
                        loadStatus: 'loading',
                    };
                }
            )
            .addCase(
                loadCustomerBaggageInfoDialogData.fulfilled,
                (state, action) => {
                    const groupingByCustomer = new Map<string, Bag[]>(
                        action.payload.value.reduce((acc, bag) => {
                            const key = `${bag.passenger?.flightTeamId}${bag.passenger?.cabin}${bag.passenger?.seatNumber}`;
                            const group = acc.get(key) ?? [];
                            acc.set(key, [...group, bag]);
                            return acc;
                        }, new Map<string, Bag[]>())
                    );

                    state.customerInfo = {
                        ...state.customerInfo,
                        loadStatus: 'idle',
                        groupBooking: Array.from(
                            groupingByCustomer.values()
                        ).map((bags) => ({
                            ...bags[0]?.passenger,
                            bags,
                        })) as Passenger[],
                        allBagsOnPNR: action.payload.value,
                    };
                }
            )
            .addCase(
                loadCustomerBaggageInfoDialogData.rejected,
                ({ customerInfo }) => {
                    customerInfo = {
                        loadStatus: 'failed',
                    };
                }
            )
            .addCase(initOnFlightBaggageStatusPageLoad.pending, (state) => { })
            .addCase(
                initOnFlightBaggageStatusPageLoad.fulfilled,
                (state, action) => {
                    state.loadStatus = 'idle';
                    state.items = action.payload.value;
                    if (action.payload['@odata.count'] !== undefined) {
                        state.pagination.totalCount =
                            action.payload['@odata.count'] ?? 0;
                    }

                    state.cabins = Array.from(
                        new Set<string>(
                            state.items
                                .filter((p) => !!p.cabin)
                                .map((p) => p.cabin)
                        )
                    );

                    state.loyalties = Array.from(
                        new Set<string>(
                            state.items
                                .filter((p) => !!p.frequentFlyerTier)
                                .map((p) => p.frequentFlyerTier ?? '')
                        )
                    );
                }
            )
            .addCase(initOnFlightBaggageStatusPageLoad.rejected, (state) => {
                state.loadStatus = 'failed';
            })
            .addCase(loadFlightBaggageStatus.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(loadFlightBaggageStatus.fulfilled, (state, action) => {
                state.loadStatus = 'idle';
                state.items = action.payload.value;

                if (action.payload['@odata.count'] !== undefined) {
                    state.pagination.totalCount =
                        action.payload['@odata.count'] ?? 0;
                }
            })
            .addCase(loadFlightBaggageStatus.rejected, (state) => {
                state.loadStatus = 'failed';
            });
    },
});

export const {
    toggleSort,
    setFlightTeamId,
    setQueryText,
    setFilters,
    setCurrentPage,
    selectPassenger,
    clearPassengerBaggageInfo,
} = flightBaggageStatusSlice.actions;

export const flightBaggageStatusState = (state: RootState) =>
    state.flightBaggageStatus;

export default flightBaggageStatusSlice.reducer;
