import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { FlightPassenger } from '../../../model';


export interface SendPassengersMessageToTeamState {
  message: string;
  isSubmitting?: boolean;
  passengers: FlightPassenger[];
}

const initialState: SendPassengersMessageToTeamState = {
  message: '',
  passengers: []
};

export const sendPassengersMessageToTeamSlice = createSlice({
  name: 'sendPassengersMessageToTeam',
  initialState,
  reducers: {
    loadPassengers: (state, action: PayloadAction<string>) => {
      const raw = sessionStorage.getItem(action.payload);
      if (!!raw) {
        try {
          state.passengers = JSON.parse(raw);
        } catch (e) {
          console.error(`Can't parse passengers`, e);
        }
      }
    },
    updateMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setIsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    }
  },
  extraReducers: (builder) => {

  }
});

export const { updateMessage, setIsSubmitting, loadPassengers } = sendPassengersMessageToTeamSlice.actions;

export const selectSendPassengersMessageToTeam = (state: RootState) => state.sendPassengersMessageToTeam;

export default sendPassengersMessageToTeamSlice.reducer;
