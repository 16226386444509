import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
    Slice,
} from '@reduxjs/toolkit';
import buildQuery from 'odata-query';
import { RootState } from '../..';
import { createReport, fetchReports } from './reportingAPI';
import {
    IPagination,
    ISorting,
    ODataResult,
    ReportModel,
    ReportParametersInputModel,
} from '../../../model';
import { convertToOdataOptions } from '../../../utils/odata';

export interface ReportingBoardState {
    items: ReportModel[];
    pagination: IPagination;
    sorting: ISorting;
    nextPageToken: string | null;
    loadStatus: 'idle' | 'loading' | 'failed';
}

const initialState: ReportingBoardState = {
    items: [],
    pagination: {
        currentPage: 1,
        pageSize: 20,
        totalCount: 0,
    },
    sorting: {
        sortBy: 'createdTimeUtc',
        sortByDescending: true,
    },
    loadStatus: 'idle',
    nextPageToken: null,
};

const getReportQuery = (state: RootState) => {
    const { reportingBoard } = state;

    const options = convertToOdataOptions<ReportModel>({
        count:
            reportingBoard.pagination.currentPage === 1 &&
            reportingBoard.pagination.pageSize > 0,
        pagination: reportingBoard.pagination,
        sorting: reportingBoard.sorting,
    });

    options.select = [
        'id',
        'aadUserId',
        'fileName',
        'type',
        'parameters',
        'status',
        'createdTimeUtc',
    ];

    const query = buildQuery(options);

    return query;
};

export const loadReports = createAsyncThunk<
    ODataResult<ReportModel[]>,
    void,
    { state: RootState }
>('reportingBoard/loadReports', async (_, { getState }) => {
    const query = getReportQuery(getState());
    return await fetchReports(query);
});

export const createNewReport = createAsyncThunk<
ODataResult<ReportModel[]>,
    ReportParametersInputModel,
    { state: RootState }
>(
    'reportingBoard/createNewReport',
    async (data: ReportParametersInputModel, { getState }) => {
        await createReport(data);

        const query = getReportQuery(getState());
        return await fetchReports(query);
    }
);

export const reportingBoardSlice: Slice<ReportingBoardState> = createSlice({
    name: 'reportingBoard',
    initialState,
    reducers: {
        toggleSort: (state, action: PayloadAction<string>) => {
            if (state.sorting.sortBy === action.payload) {
                state.sorting.sortByDescending =
                    !state.sorting.sortByDescending;
            } else {
                state.sorting.sortBy = action.payload;
                state.sorting.sortByDescending = false;
            }
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage =
                action.payload < 1 ? 1 : action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadReports.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(loadReports.rejected, (state) => {
                state.loadStatus = 'failed';
            })
            .addCase(loadReports.fulfilled, (state, action) => {
                state.loadStatus = 'idle';
                state.items = action.payload.value;
                if (!!action.payload['@odata.count']) {
                    state.pagination.totalCount =
                        action.payload['@odata.count'];
                }
            })
            .addCase(createNewReport.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(createNewReport.rejected, (state) => {
                state.loadStatus = 'failed';
            })
            .addCase(createNewReport.fulfilled, (state, action) => {
                state.loadStatus = 'idle';
                state.items = action.payload.value;
                if (!!action.payload['@odata.count']) {
                    state.pagination.totalCount =
                        action.payload['@odata.count'];
                }
            });
    },
});

export const { toggleSort, setCurrentPage } = reportingBoardSlice.actions;
export const selectReportingBoard = (state: RootState) => state.reportingBoard;

export default reportingBoardSlice.reducer;
