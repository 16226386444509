import { makeStyles, tokens } from "@fluentui/react-components";

export const useBaggageWarningIconStyles = makeStyles({
    root: {
        fill: '#BC4B09',
    },
    interactive: {
        '&:active,&:hover': {
            fill: tokens.colorNeutralForeground2BrandHover,
        }
    }
});