import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components';
import { bundleIcon, Delete24Filled, Delete24Regular } from '@fluentui/react-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmDeletionDialogActionProps {
    flight: string;
    onConfirm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const DeleteIconBundle = bundleIcon(Delete24Filled, Delete24Regular);

const ConfirmDeletionDialogAction = ({
    flight,
    onConfirm,
}: ConfirmDeletionDialogActionProps) => {
    const { t } = useTranslation();

    return (
        <Dialog modalType="alert">
            <DialogTrigger disableButtonEnhancement>
                <Button
                    appearance="transparent"
                    icon={<DeleteIconBundle />}
                />
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>
                        {t('ConfirmDeletionDialog_Title')}
                    </DialogTitle>
                    <DialogContent>
                        {t('ConfirmDeletionDialog_Content').replace(
                            '{flight}',
                            flight
                        )}
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <Button onClick={onConfirm} appearance="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default ConfirmDeletionDialogAction;
