import { makeStyles, tokens, shorthands } from '@fluentui/react-components';

export const useDepartureBoardPageStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    selectedRowsButton: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalXS),
    },
    table: {
        marginTop: tokens.spacingVerticalM,
        overflow: 'auto',
        height: 'fit-content',
    },
    tableRow: {
        justifyContent: 'space-between',
        height: '44px',
        minWidth: 'fit-content',
        ...shorthands.padding(0, tokens.spacingHorizontalXL),
    },
    tableSelectionCell: {
        minWidth: '25px',
        maxWidth: '25px',
    },
    departureStatusIndicator: {
        display: 'inline-block',
        height: tokens.spacingHorizontalXS,
        width: tokens.spacingHorizontalXS,
        marginRight: tokens.spacingHorizontalXS,
        ...shorthands.borderRadius('50%'),
        '&.OnTime': {
            backgroundColor: tokens.colorPaletteLightGreenForeground3,
        },
        '&.Departed': {
            backgroundColor: 'transparent',
        },
        '&.Delayed': {
            backgroundColor: tokens.colorPaletteYellowForeground3,
        },
        '&.Cancelled': {
            backgroundColor: tokens.colorPaletteRedForeground3,
        },
    },
    statusIndicator: {
        display: 'inline-block',
        height: tokens.spacingHorizontalXS,
        width: tokens.spacingHorizontalXS,
        marginRight: tokens.spacingHorizontalXS,
        ...shorthands.borderRadius('50%'),
        '&.Yes': {
            backgroundColor: tokens.colorPaletteLightGreenForeground3,
        },
        '&.No': {
            backgroundColor: tokens.colorPalettePlatinumBackground2,
        },
        '&.NotAvailable' : {
            backgroundColor: tokens.colorPalettePlatinumBackground2,
        },
        '&.AllNotified': {
            backgroundColor: tokens.colorPaletteLightGreenForeground3,
        },
        '&.NotNotified': {
            backgroundColor: tokens.colorPaletteRedForeground3,
        }
    },
    departureStatus: {
        display: 'flex',
        alignItems: 'center',
    },
    checkInStatus: {
        display: 'flex',
        alignItems: 'center',
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '250px',
    },
    filtersHeader: {
        alignSelf: 'flex-end',
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalS,
    },
    filter: {
        display: 'grid',
        gridRowGap: tokens.spacingVerticalXS,
    },
});
