import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useReportingPageStyles } from './ReportingPage.styles';
import {
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableColumnDefinition,
    TableColumnId,
    TableColumnSizingOptions,
    TableHeader,
    TableHeaderCell,
    TableRow,
    ToolbarButton,
    createTableColumn,
    useTableColumnSizing_unstable,
    useTableFeatures,
    useTableSort,
} from '@fluentui/react-components';
import { app } from '@microsoft/teams-js';
import { useTranslation } from 'react-i18next';
import {
    Add24Regular,
    CalendarCheckmarkRegular,
    ScanTypeRegular,
    StatusRegular,
} from '@fluentui/react-icons';
import NewReportDialogAction from './NewReportDialogAction/NewReportDialogAction';
import { useAppDispatch } from '../../store/hooks';
import { useSelector } from 'react-redux';
import {
    createNewReport,
    loadReports,
    selectReportingBoard,
    setCurrentPage,
    toggleSort,
} from '../../store/features/reporting/reportingSlice';
import { ReportModel } from '../../model';
import { formatDateUtc } from '../../i18n';
import Pagination from '../Pagination';

const ReportingPage = () => {
    const { t } = useTranslation();
    const styles = useReportingPageStyles();

    const [openNewReportDialog, setOpenNewReportDialog] = useState(false);

    const dispatch = useAppDispatch();
    const { loadStatus, items, pagination, sorting } =
        useSelector(selectReportingBoard);

    useEffect(() => {
        dispatch(loadReports());
    }, [dispatch]);

    useEffect(() => {
        if (loadStatus === 'idle') {
            app.notifySuccess();
        }
        app.notifySuccess();
    }, [loadStatus]);

    const columns = useMemo<TableColumnDefinition<ReportModel>[]>(
        () => [
            createTableColumn<ReportModel>({
                columnId: 'type',
                renderHeaderCell: () => {
                    return (
                        <>
                            <ScanTypeRegular />
                            {t('TableHeader_ReportType')}
                        </>
                    );
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout>
                            {t(`ReportType_${item.type}`)}
                        </TableCellLayout>
                    );
                },
            }),
            createTableColumn<ReportModel>({
                columnId: 'createdTimeUtc',
                renderHeaderCell: () => {
                    return (
                        <>
                            <CalendarCheckmarkRegular />
                            {t('TableHeader_Created')}
                        </>
                    );
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout>
                            {formatDateUtc(item.createdTimeUtc)}
                        </TableCellLayout>
                    );
                },
            }),
            createTableColumn<ReportModel>({
                columnId: 'status',
                renderHeaderCell: () => {
                    return (
                        <>
                            <StatusRegular />
                            {t('TableHeader_ReportProcessingStatus')}
                        </>
                    );
                },
                renderCell: (item) => {
                    return (
                        <TableCellLayout>
                            {t(`ReportStatus_${item.status}`)}
                        </TableCellLayout>
                    );
                },
            }),
        ],
        [t]
    );

    const columnSizingOptions = useMemo<TableColumnSizingOptions>(() => {
        return {
            type: {
                minWidth: 250,
            },
            createdTimeUtc: {
                minWidth: 200,
            },
            status: {
                minWidth: 200,
            },
        };
    }, []);

    const {
        sort: { getSortDirection },
        columnSizing_unstable: columnSizing,
    } = useTableFeatures(
        {
            columns,
            items: items,
        },
        [
            useTableSort({
                sortState: {
                    sortColumn: sorting.sortBy,
                    sortDirection: sorting.sortByDescending
                        ? 'ascending'
                        : 'descending',
                },
            }),
            useTableColumnSizing_unstable({
                columnSizingOptions,
            }),
        ]
    );

    const headerSortProps = useCallback(
        (columnId: TableColumnId) => ({
            onClick: () => {
                if (columnId === 'actions') {
                    return;
                }
                dispatch(toggleSort(columnId as string));
                dispatch(loadReports());
            },
            sortDirection: getSortDirection(columnId),
        }),
        [getSortDirection, dispatch]
    );

    return (
        <div className={styles.root}>
            <Table
                className={styles.table}
                size="small"
                role="grid"
                aria-label="Flights"
                sortable
                noNativeElements
            >
                <TableHeader>
                    <TableRow className={styles.tableRow}>
                        {columns.map((column) => (
                            <TableHeaderCell
                                key={column.columnId}
                                style={
                                    columnSizing.getTableHeaderCellProps(
                                        column.columnId
                                    )?.style
                                }
                                {...headerSortProps(column.columnId)}
                            >
                                {column.renderHeaderCell()}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {items?.map((item) => (
                        <TableRow key={item.id} className={styles.tableRow}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.columnId}
                                    tabIndex={0}
                                    role="gridcell"
                                    style={
                                        columnSizing.getTableHeaderCellProps(
                                            column.columnId
                                        )?.style
                                    }
                                >
                                    {column.renderCell(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination
                currentPage={pagination.currentPage}
                pageSize={pagination.pageSize}
                totalItems={pagination.totalCount}
                onChange={(page) => {
                    dispatch(setCurrentPage(page));
                    dispatch(loadReports());
                }}
                actions={
                    <ToolbarButton
                        onClick={() => setOpenNewReportDialog(true)}
                        icon={<Add24Regular />}
                    >
                        {t('ToolbarButton_GenerateReport')}
                    </ToolbarButton>
                }
            />
            <NewReportDialogAction
                open={openNewReportDialog}
                onOpenChange={(_, data) => {
                    setOpenNewReportDialog(data.open);
                }}
                onSubmit={(_, data) => {
                    dispatch(createNewReport(data));
                }}
            />
            {loadStatus === 'loading' && (
                <Spinner className={styles.spiner} appearance="primary" />
            )}
        </div>
    );
};

export default ReportingPage;
