import { makeStyles, tokens } from "@fluentui/react-components";

export const useBaggageSuccessIconStyles = makeStyles({
    root: {
        fill: '#107C10',
    },
    interactive: {
        '&:active,&:hover': {
            fill: tokens.colorNeutralForeground2BrandHover,
        }
    }
});