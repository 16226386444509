import { BaseApiUrl, BaseOdataUrl } from "../../../configVariables";
import ConnectToAvailableTeamsType from "../../../model/ConnectToAvailableTeamsType";
import IConnectToTeam from "../../../model/IConnectToTeam";
import { ODataResult } from "../../../model/odata";
import { FlightPassenger } from "../../../model/FlightPassenger";
import apiClient from "../../../utils/apiClient";
import { replaceSpecialCharacters } from "../../../utils/odata";

export const fetchConnectToTeams = async () => {
  let url = `${BaseApiUrl}/connectTo/getAvailableTeamsByType('${ConnectToAvailableTeamsType.FlightPassengers}')`;
  return await apiClient.get<IConnectToTeam[]>(url);
}

export const fetchCanSearchPassengers = async () => {
  let url = `${BaseApiUrl}/permissions/canCabinCrewSearchPassengers`;
  return await apiClient.get<boolean>(url);
}

export const fetchFlightPassengers = async (teamFlightId: string, queryText: string) => {
  let url = `${BaseOdataUrl}/FlightsPassengers/SearchByFlight(flightTeamId=${teamFlightId},queryText='${replaceSpecialCharacters(queryText)}')`;
  return await apiClient.get<ODataResult<FlightPassenger[]>>(url, false);
}