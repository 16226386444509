import { makeStyles, tokens, shorthands } from '@fluentui/react-components';

export const useHelpPageStyles = makeStyles({
    root: {
        ...shorthands.padding('1rem', tokens.spacingHorizontalXXXL),
        alignSelf: 'flex-start',
        justifyItems: 'start',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        maxHeight: '70vh',
        '& > :not(:last-child)': {
            marginBottom: '1.25rem',
        },
    },
    actions: {
        marginTop: 'auto',
        alignSelf: 'end',
    },
    formField: {
        display: 'flex',
        flexDirection: 'column',
        '& > label': {
            marginBottom: tokens.spacingVerticalMNudge,
        },
    },
    textArea: {
        // '> textarea': {
        //     height: '80px',
        // },
    },
    warning: {
        fontStyle: 'italic',
    },
});