import React from 'react';
import { useBaggageWarningIconStyles } from './BaggageWarningIcon.styles';
import { mergeClasses } from '@fluentui/react-components';

interface BaggageWarningIconProps {
    interactive?: boolean;
    width?: number;
    height?: number;
}

export const BaggageWarningIcon = React.memo(function BaggageWarningIcon({interactive = false, width = 20, height = 20} : BaggageWarningIconProps) {
    const styles = useBaggageWarningIconStyles();
    return (
        <svg
            className={mergeClasses(styles.root, interactive ? styles.interactive : '')}
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5003 2.00018C6.22416 2.00018 6.0003 2.22404 6.0003 2.50018C6.0003 2.77633 6.22416 3.00018 6.5003 3.00018H7.0003V4.00018H6.49313C5.11578 4.00018 3.99789 5.11423 3.99315 6.49157L3.96559 14.4916C3.96137 15.7166 4.83886 16.7384 6.0003 16.957V17.5002C6.0003 17.7763 6.22416 18.0002 6.5003 18.0002C6.77644 18.0002 7.0003 17.7763 7.0003 17.5002V17.0002H9.5998C9.43785 16.6834 9.30571 16.3488 9.20708 16.0002H6.46558C5.63513 16.0002 4.96272 15.3255 4.96559 14.495L4.99314 6.49502C4.99599 5.66861 5.66672 5.00018 6.49313 5.00018H13.5341C14.3646 5.00018 15.037 5.67491 15.0341 6.50535L15.0254 9.02477C15.3687 9.05731 15.7028 9.1214 16.0248 9.21411L16.0341 6.5088C16.0389 5.12473 14.9182 4.00018 13.5341 4.00018H13.0003V3.00018H13.5003C13.7764 3.00018 14.0003 2.77633 14.0003 2.50018C14.0003 2.22404 13.7764 2.00018 13.5003 2.00018H6.5003ZM8.0003 4.00018V3.00018H12.0003V4.00018H8.0003ZM6.99969 7.99994C6.72355 7.99994 6.49969 8.2238 6.49969 8.49994C6.49969 8.77608 6.72355 8.99994 6.99969 8.99994H12.9997C13.2758 8.99994 13.4997 8.77608 13.4997 8.49994C13.4997 8.2238 13.2758 7.99994 12.9997 7.99994H6.99969Z"
            />
            <path
                d="M14.5 19C16.9853 19 19 16.9853 19 14.5C19 12.0147 16.9853 10 14.5 10C12.0147 10 10 12.0147 10 14.5C10 16.9853 12.0147 19 14.5 19ZM14 12.5C14 12.2239 14.2239 12 14.5 12C14.7761 12 15 12.2239 15 12.5V14.5C15 14.7761 14.7761 15 14.5 15C14.2239 15 14 14.7761 14 14.5V12.5ZM15.125 16.5C15.125 16.8452 14.8452 17.125 14.5 17.125C14.1548 17.125 13.875 16.8452 13.875 16.5C13.875 16.1548 14.1548 15.875 14.5 15.875C14.8452 15.875 15.125 16.1548 15.125 16.5Z"
            />
        </svg>
    );
});
