import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getConfig } from './configAPI';
import { Config } from '../../../model/Config';

export interface ConfigState extends Config {
    status: 'idle' | 'loading' | 'failed';
}

const initialState: ConfigState = {
    botId: '',
    featureFlags: [],
    status: 'idle'
};

export const loadConfig = createAsyncThunk('config/load', async () => {
    const configResponse = await getConfig();
    return configResponse.data;
});

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadConfig.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadConfig.fulfilled, (state, action) => {
                state.status = 'idle';
                state.botId = action.payload.botId;
                state.featureFlags = action.payload.featureFlags;
            })
            .addCase(loadConfig.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const selectConfig = (state: RootState) => state.config;

export default configSlice.reducer;
