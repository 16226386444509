import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import IConnectToTeam from '../../../model/IConnectToTeam';
import { fetchConnectToTeams } from './helpAPI';


export interface HelpState {
  connectToTeamsStatus: 'idle' | 'loading' | 'failed';
  connectToTeams: IConnectToTeam[],
  selectedConnectToTeam?: IConnectToTeam;
  message: string;
  isSubmitting?: boolean;
}

const initialState: HelpState = {
  connectToTeamsStatus: 'loading',
  message: '',
  connectToTeams: []
};

export const getConnectToTeams = createAsyncThunk(
  'help/fetchConnectToTeams',
  async () => {
    const response = await fetchConnectToTeams();
    return response.data;
  }
);

export const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    selectTeam: (state, action: PayloadAction<string | undefined>) => {
      if (!action.payload) {
        state.selectedConnectToTeam = undefined;
      }
      else {
        const team = state.connectToTeams.find(x => x.key === action.payload);
        state.selectedConnectToTeam = team;
      }
    },
    updateMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setIsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConnectToTeams.pending, (state) => {
        state.connectToTeamsStatus = 'loading';
      })
      .addCase(getConnectToTeams.fulfilled, (state, action) => {
        state.connectToTeamsStatus = 'idle';
        state.connectToTeams = action.payload;
        if (action.payload.length > 0) {
          state.selectedConnectToTeam = action.payload[0];
        }
      })
      .addCase(getConnectToTeams.rejected, (state) => {
        state.connectToTeamsStatus = 'failed';
      });
  }
});

export const { selectTeam, updateMessage, setIsSubmitting } = helpSlice.actions;

export const selectHelp = (state: RootState) => state.help;

export default helpSlice.reducer;
