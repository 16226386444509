import { BaseOdataUrl } from "../../../configVariables";
import { BroadcastMessageWithStatistic, ODataResult } from "../../../model";
import apiClient from "../../../utils/apiClient";


export const fetchBroadcastMessagesApiCall = async (
    query?: string
): Promise<ODataResult<BroadcastMessageWithStatistic[]>> => {
  const url = `${BaseOdataUrl}/BroadcastMessages${query}`;
  const result = await apiClient.get<ODataResult<BroadcastMessageWithStatistic[]>>(url);
  return result.data;
}