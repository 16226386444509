import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useToolbarStyles = makeStyles({
    toolbar: {
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        boxShadow: tokens.shadow16,
        position: 'sticky',
        minHeight: 'fit-content',
        top: 0,
        left: 0,
        backgroundColor: tokens.colorNeutralBackground1,
        zIndex: 1,
        ...shorthands.padding(
            tokens.spacingVerticalS,
            tokens.spacingHorizontalXL
        ),
        '& button': {
            minWidth: 'fit-content',
            ...shorthands.padding(
                tokens.spacingVerticalXS,
                tokens.spacingHorizontalXXS
            ),
        },
        '@media (max-width: 500px)': {
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
            ...shorthands.gap(tokens.spacingVerticalS),

            '& > *': {
                width: '100%',
            },
        },
    },
    toolbarGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '@media (max-width: 500px)': {
            '&:empty': {
                display: 'none',
            },
        },
    },
    toolbarRightGroup: {
        '&>*': {
            flexShrink: 1,
        },
        ...shorthands.gap(tokens.spacingHorizontalXS),
    },
    searchBar: {
        display: 'inline-grid',
        gridTemplateColumns: 'auto 1fr',
    },
});
