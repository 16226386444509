import { BaseOdataUrl } from "../../../configVariables";
import { ODataResult, ReportModel, ReportParametersInputModel } from "../../../model";
import apiClient from "../../../utils/apiClient";


export const fetchReports = async (query?: string) => {
    const url = `${BaseOdataUrl}/reporting${query}`;
    const result = await apiClient.get<ODataResult<ReportModel[]>>(url);
    return result.data;
}

export const createReport = async (data: ReportParametersInputModel) => {
    const url = `${BaseOdataUrl}/reporting`;
    return await apiClient.post(url, data);
}