import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { IPagination, ISorting, FlightDepartureStatus, ODataResult, FlightPassenger } from '../../../model';
import { fetchFlightsPassengers } from './flightsPassengersBoardAPI';
import buildQuery, { Filter } from 'odata-query'
import { convertToOdataOptions } from '../../../utils/odata';
import { v4 as uuidv4 } from 'uuid';

export interface FlightsPassengersBoardFilterState {
  open: boolean;
  queryText: string;
  departureStatuses: { [k: string | FlightDepartureStatus]: boolean };
}

export interface FlightsPassengersBoardState {
  items: FlightPassenger[];
  pagination: IPagination;
  sorting: ISorting;
  selectedItems: FlightPassenger[];
  readonly selectedItemsStorageKey: string;
  readonly singleItemStorageKey: string;

  loadStatus: 'idle' | 'loading' | 'failed';
  filter: FlightsPassengersBoardFilterState;
}

const initialState: FlightsPassengersBoardState = {

  loadStatus: 'loading',
  items: [],
  selectedItems: [],
  pagination: {
    currentPage: 1,
    pageSize: 15,
    totalCount: 0
  },
  filter: {
    queryText: '',
    open: false,
    departureStatuses: {},
  },
  sorting: {
    sortBy: 'displayName',
    sortByDescending: false,
  },
  selectedItemsStorageKey: uuidv4(),
  singleItemStorageKey: uuidv4()
};

export const loadFlightsPassengers = createAsyncThunk<ODataResult<FlightPassenger[]>, void, { state: RootState }>(
  'flightsPassengersBoard/loadFlightsPassengers',
  async (_, { getState }) => {
    const { flightsPassengersBoard } = getState();

    const options = convertToOdataOptions<FlightPassenger>({
      count: flightsPassengersBoard.pagination.currentPage === 1,
      pagination: flightsPassengersBoard.pagination,
      sorting: flightsPassengersBoard.sorting
    });

    const andParts: Partial<Filter<FlightPassenger>>[] = [];

    if (!!flightsPassengersBoard.filter.queryText) {
      andParts.push({
        or: [
          { displayName: { contains: flightsPassengersBoard.filter.queryText } },
          { seatNumber: { contains: flightsPassengersBoard.filter.queryText } },
          { 'flightTeam/iataFlightIdentifier': { contains: flightsPassengersBoard.filter.queryText } }
        ]
      });
    }

    const departureStatuses = Object.keys(flightsPassengersBoard.filter.departureStatuses).filter((k) => flightsPassengersBoard.filter.departureStatuses[k]);
    if (departureStatuses.length) {
      andParts.push({
        or: [
          ...departureStatuses.map(status => {
            return { 'flightTeam/departureStatus': status }
          })
        ]
      });
    }

    options.filter = {
      and: [
        ...andParts
      ]
    };
    options.expand = ["flightTeam"];
    const query = buildQuery(options);
    return await fetchFlightsPassengers(query);
  }
);

export const flightsPassengersBoardSlice = createSlice({
  name: 'flightsPassengersBoard',
  initialState,
  reducers: {
    toggleSort: (state, action: PayloadAction<string>) => {
      if (state.sorting.sortBy === action.payload) {
        state.sorting.sortByDescending = !state.sorting.sortByDescending;
      }
      else {
        state.sorting.sortBy = action.payload;
        state.sorting.sortByDescending = false;
      }
    },
    showHideFitler: (state, action: PayloadAction<boolean>) => {
      state.filter.open = action.payload;
    },
    setDepartureStatusFilter: (state, action: PayloadAction<{ value: FlightDepartureStatus, checked: boolean }>) => {
      state.filter.departureStatuses[action.payload.value] = action.payload.checked;
    },
    setQueryText: (state, action: PayloadAction<string>) => {
      state.filter.queryText = action.payload ?? '';
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.pagination.currentPage = action.payload < 1 ? 1 : action.payload;
    },
    resetFilters: (state) => {
      Object.keys(state.filter.departureStatuses).forEach((key) => {
        state.filter.departureStatuses[key] = false;
      });
    },
    saveSinglePassenger: (state, action: PayloadAction<FlightPassenger>) => {
      sessionStorage.setItem(state.singleItemStorageKey, JSON.stringify([action.payload]));
    },
    clearSinglePassenger: (state) => {
      sessionStorage.removeItem(state.singleItemStorageKey);
    },
    selectPassengers: (state, action: PayloadAction<string[]>) => {
      const newSelectedItems: FlightPassenger[] = [];
      if (action.payload.length > 0) {
        const selectedItemsMap = new Map(state.selectedItems.map(x => [x.id, x]));
        const itemsMap = new Map(state.items.map(x => [x.id, x]));
        action.payload.forEach(selectedId => {
          if (itemsMap.has(selectedId)) {
            newSelectedItems.push(itemsMap.get(selectedId)!);
          } else if (selectedItemsMap.has(selectedId)) {
            newSelectedItems.push(selectedItemsMap.get(selectedId)!);
          }
        });
      }
      state.selectedItems = newSelectedItems;
      sessionStorage.setItem(state.selectedItemsStorageKey, JSON.stringify(state.selectedItems));
    },
    clearSelectedPassengers: (state) => {
      state.selectedItems = [];
      sessionStorage.removeItem(state.selectedItemsStorageKey);
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(loadFlightsPassengers.pending, (state) => {
        state.loadStatus = 'loading';
      })
      .addCase(loadFlightsPassengers.fulfilled, (state, action) => {
        state.loadStatus = 'idle';
        state.items = action.payload.value;
        if (action.payload['@odata.count'] !== undefined) {
          state.pagination.totalCount = action.payload['@odata.count'];
        }
      })
      .addCase(loadFlightsPassengers.rejected, (state) => {
        state.loadStatus = 'failed';
      })
      ;
  }
});


export const {
  toggleSort,
  setQueryText,
  showHideFitler,
  setDepartureStatusFilter,
  setCurrentPage,
  resetFilters,
  saveSinglePassenger,
  clearSinglePassenger,
  clearSelectedPassengers,
  selectPassengers
} = flightsPassengersBoardSlice.actions;

export const selectFlightsPassengersBoard = (state: RootState) => state.flightsPassengersBoard;
export const selectFlightsPassengersBoardFilter = (state: RootState) => state.flightsPassengersBoard.filter;

export default flightsPassengersBoardSlice.reducer;


