

import { FlightTeam } from "../model";
import { app, dialog } from "@microsoft/teams-js";

export const submitDialog = (data: any) => {

  if (!app.getFrameContext()) {

    //// HACK: IOS issue workaround. REMOVE code when issue will be fixed
    _submitDialog(data);
  }
  else {
    dialog.url.submit(data);
  }
}

//// HACK: IOS issue workaround.
///  frameContext returns '' on iOS (https://github.com/OfficeDev/microsoft-teams-library-js/issues/1639)
const _submitDialog = (data: any) => {
  const targetWindow: Window = window.parent !== window.self
    ? window.parent
    : window.opener;

  var postMessageContent = {
    id: 1000,
    func: "tasks.completeTask",
    timestamp: Date.now(),
    args: [data, []]
  }
  if (targetWindow) {
    targetWindow.postMessage(postMessageContent, "*");
  } else {
    const currentWindow = window as unknown as ExtendedWindow;
    if (currentWindow.nativeInterface) {
      currentWindow.nativeInterface.framelessPostMessage(JSON.stringify(postMessageContent));
    }
  }
}

interface TeamsNativeClient {
  framelessPostMessage(msg: string): void;
}
interface ExtendedWindow extends Window {
  nativeInterface: TeamsNativeClient;
}


export const generateFlightTeamDeepLink = (item: FlightTeam): string => {
  return `https://teams.microsoft.com/l/message/${item.teamConversationId}/${item.welcomeMessageId}?groupId=${item.teamId}&parentMessageId=${item.welcomeMessageId}&createdTime=${item.welcomeMessageId}&allowXTenantAccess=false`;
}