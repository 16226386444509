import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useReportingPageStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    toolbar: {
        justifyContent: 'space-between',
        boxShadow: tokens.shadow16,
    },
    table: {
        marginTop: tokens.spacingVerticalM,
        overflow: 'auto',
        flexGrow: 1,
    },
    tableRow: {
        justifyContent: 'space-between',
        minWidth: 'fit-content',
        ...shorthands.padding(0, tokens.spacingHorizontalXL),
    },
    spiner: {
        position: 'absolute',
        minWidth: '100%',
        top: '50%',
    }
});
