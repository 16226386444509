import React, { useEffect, useMemo } from "react";
import { app, authentication } from "@microsoft/teams-js";

import { Textarea, Button, Label, Spinner } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useSelector } from "../../store/hooks";


import { submitDialog } from "../../utils/teams";
import { useSendMessageToTeamPageStyles } from "./SendMessageToTeamPage.styles";
import { selectSendMessageToTeam, setIsSubmitting, updateMessage } from "../../store/features/sendMessageToTeam/sendMessageToTeamSlice";
import { IBotSubmitPayload } from "../../model/IBotSubmitPayload";
import { IBrokerConnectionSendMessageToFlightTeamData } from "../../model/brokerConnections/IBrokerConnectionSendMessageToFlightTeamData";
import BotSubmitAction from "../../model/BotSubmitAction";
import { useParams } from "react-router-dom";

const SendMessageToTeamPage = () => {
  const { flightTeamId } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const styles = useSendMessageToTeamPageStyles();

  const {
    message,
    isSubmitting
  } = useSelector(selectSendMessageToTeam);


  useEffect(() => {
    app.notifySuccess();
  }, []);

  const isSubmitDisable = useMemo(() => {
    const disable = isSubmitting;
    if (disable) {
      return true;
    }
    const text = message?.trim() || '';
    return text.length === 0 || text.length > 4000
  }, [isSubmitting, message]);


  return <div className={styles.root}>
    <div className={styles.formField}>
      <Label weight="semibold" required>{t('Message')}</Label>
      <Textarea
        rows={5}
        className={styles.textArea}
        value={message}
        onChange={(_, data) => {
          dispatch(updateMessage(data?.value));
        }}
      >
      </Textarea>
      <Label size="small" className={styles.warning}>{t('AskOrTellTeamWarning')}</Label>
    </div>
    <div className={styles.actions}>
      <Button
        iconPosition="before"
        icon={isSubmitting ? <Spinner size="tiny" /> : undefined}
        appearance="primary"
        disabled={isSubmitDisable || isSubmitting}
        onClick={async () => {
          if (isSubmitting) {
            return;
          }
          dispatch(setIsSubmitting(true));
          const token = await authentication.getAuthToken();

          const text = message?.trim() || '';
          const submitRequest: IBotSubmitPayload<IBrokerConnectionSendMessageToFlightTeamData> = {
            data: {
              flightTeamId: flightTeamId!,
              message: text!,
            },
            appUserAccessToken: token,
            action: BotSubmitAction.BrokerConnectionSendMessageToFlightTeam,
          };
          submitDialog(submitRequest);
        }}
      >
        {t('Submit')}
      </Button>
    </div>
  </div >;
};



export default SendMessageToTeamPage;