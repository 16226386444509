import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useWelcomeMessagesPanelStyles = makeStyles({
    root: {
        height: 'calc(100% - 50px)',
        boxSizing: 'border-box',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    table: {
        paddingTop: tokens.spacingVerticalM,
        height: 'fit-content',
        overflow: 'auto',
        flexGrow: 1,
    },
    tableRow: {
        justifyContent: 'space-between',
        minWidth: 'fit-content',
        ...shorthands.padding(0, tokens.spacingHorizontalXL),
    },
    header: {
        paddingRight: 'var(--scrollbar-width, 4px)',
        height: 'var(--header-height)',
    },
    list: {
        scrollbarGutter: 'stable',
        minHeight: '60vh',
    },
    grid: {
    },
    toolbar: {
        justifyContent: 'space-between',
        boxShadow: tokens.shadow16,
        ...shorthands.margin(0, 0, '20px')
    },
    spiner: {
        position: 'absolute',
        minWidth: '100%',
        top: '50%'
    }
});
