import { formatDateUtc } from '../i18n';
import { FlightTeam, PassengerLoyalty } from '../model';

export const formatFlightTime = (time?: string, isUtc?: boolean) => {
    if(!time) {
        return 'n/a';
    }
    return `${formatDateUtc(time)}${!!isUtc ? ' (UTC)' : ''}`;
};

export const formatFlightTeamName = (item: FlightTeam) => {
    return `${item.iataFlightIdentifier} ${item.departureAirportIata} - ${
        item.arrivalAirportIata
    } ${
        item.scheduledDepartureTime
            ? formatFlightTime(item.scheduledDepartureTime)
            : formatFlightTime(item.scheduledDepartureTimeUtc, true)
    }`;
};

export const formatPassengerLoyalty = (loyalty: PassengerLoyalty) => {
    let result = '';
    if (loyalty?.carrierCode) {
        result += loyalty.carrierCode;
        result += ' - ';
    }

    if (loyalty?.airlineTierCode) {
        result += loyalty.airlineTierCode;
    }

    return result ? result : 'None';
};
