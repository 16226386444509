import {
    TableColumnSizingOptions,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';

export const useFlightBaggageStatusTableStyles = makeStyles({
    table: {
        marginTop: tokens.spacingVerticalM,
        height: 'fit-content',
        overflow: 'auto',
        flexGrow: 1,
    },
    tableHeader: {
        minWidth: 'fit-content',
    },
    tableBody: {
        minWidth: 'fit-content',
    },
    tableRow: {
        minWidth: 'fit-content',
        ...shorthands.padding(0, tokens.spacingHorizontalXL)
    },
    cabinRow: {
        cursor: 'pointer',
        userSelect: 'none',
    },
    cell: {},
    actionCell: {
        '&:active': {
            backgroundColor: 'none',
        },
        '&:hover': {
            backgroundColor: 'none',
        },
    },
    sectionTitle: {
        display: 'inline-flex',
        alignItems: 'center',
        ...shorthands.gap('5px'),
    },
    grayText: {
        color: tokens.colorNeutralForeground3,
    },
});

export const columnSizingOptions: TableColumnSizingOptions = {
    displayName: {
        minWidth: 60,
    },
    'flightTeam/iataFlightIdentifier': {
        minWidth: 60,
    },
    'flightTeam/departureAirportIata,flightTeam/arrivalAirportIata': {
        minWidth: 80,
    },
    'flightTeam/scheduledDepartureTimeUtc': {
        minWidth: 110,
    },
    'flightTeam/scheduledArrivalTimeUtc': {
        minWidth: 110,
    },
    seatNumber: {
        minWidth: 60,
    },
    frequentFlyerTier: {
        minWidth: 60,
    },
};
