import React from 'react';
import { useBaggageSuccessIconStyles } from './BaggageSuccessIcon.styles';
import { mergeClasses } from '@fluentui/react-components';

interface BaggageSuccessIconProps { 
    interactive?: boolean;
    width?: number;
    height?: number;
}

export const BaggageSuccessIcon = React.memo(function BaggageSuccessIcon({interactive = false, width = 20, height = 20} : BaggageSuccessIconProps) {
    const styles = useBaggageSuccessIconStyles();
    return (
        <svg
            className={mergeClasses(styles.root, interactive ? styles.interactive : '')}
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5003 2.00018C6.22416 2.00018 6.0003 2.22404 6.0003 2.50018C6.0003 2.77633 6.22416 3.00018 6.5003 3.00018H7.0003V4.00018H6.49313C5.11578 4.00018 3.99789 5.11423 3.99315 6.49157L3.96559 14.4916C3.96137 15.7166 4.83886 16.7384 6.0003 16.957V17.5002C6.0003 17.7763 6.22416 18.0002 6.5003 18.0002C6.77644 18.0002 7.0003 17.7763 7.0003 17.5002V17.0002H9.5998C9.43785 16.6834 9.30571 16.3488 9.20708 16.0002H6.46558C5.63513 16.0002 4.96272 15.3255 4.96559 14.495L4.99314 6.49502C4.99599 5.66861 5.66672 5.00018 6.49313 5.00018H13.5341C14.3646 5.00018 15.037 5.67491 15.0341 6.50535L15.0254 9.02477C15.3687 9.05731 15.7028 9.1214 16.0248 9.21411L16.0341 6.5088C16.0389 5.12473 14.9182 4.00018 13.5341 4.00018H13.0003V3.00018H13.5003C13.7764 3.00018 14.0003 2.77633 14.0003 2.50018C14.0003 2.22404 13.7764 2.00018 13.5003 2.00018H6.5003ZM8.0003 4.00018V3.00018H12.0003V4.00018H8.0003ZM6.99969 7.99994C6.72355 7.99994 6.49969 8.2238 6.49969 8.49994C6.49969 8.77608 6.72355 8.99994 6.99969 8.99994H12.9997C13.2758 8.99994 13.4997 8.77608 13.4997 8.49994C13.4997 8.2238 13.2758 7.99994 12.9997 7.99994H6.99969Z"
            />
            <path
                d="M14.5 19C16.9853 19 19 16.9852 19 14.5C19 12.0147 16.9853 10 14.5 10C12.0147 10 10 12.0147 10 14.5C10 16.9852 12.0147 19 14.5 19ZM16.6652 12.5743C16.825 12.4146 17.0841 12.4146 17.2439 12.5743C17.4036 12.7341 17.4036 12.9932 17.2439 13.1529L13.9711 16.4256C13.8113 16.5854 13.5523 16.5854 13.3925 16.4256L11.7561 14.7892C11.5964 14.6295 11.5964 14.3705 11.7561 14.2107C11.9159 14.051 12.175 14.051 12.3348 14.2107L13.6818 15.5578L16.6652 12.5743Z"
            />
        </svg>
    );
});
