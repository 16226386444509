import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPagination, WelcomeMessageEntity } from '../../../model';
import { RootState } from '../..';
import {
    WelcomeMessagesResponse,
    createWelcomeMessage,
    deleteWelcomeMessage,
    fetchWelcomeMessages,
    updateWelcomeMessage,
} from './welcomeMessagesAPI';

export interface WelcomeMessagesPanelState {
    items: WelcomeMessageEntity[];
    nextPageToken: string | null;
    search: string | null;
    loadStatus: 'idle' | 'loading' | 'failed';
    pagination: IPagination;
}

const initialState: WelcomeMessagesPanelState = {
    loadStatus: 'loading',
    items: [],
    search: null,
    nextPageToken: null,
    pagination: {
        currentPage: 1,
        pageSize: 10,
        totalCount: 0,
    },
};

const getUniqWelcomeMessages = (messages: WelcomeMessageEntity[]) => {
    const welcomeMessagesMap = new Map(
        messages.map((x) => [x.rowKey, x.value])
    );
    return Array.from(
        welcomeMessagesMap,
        ([rowKey, value]): WelcomeMessageEntity => ({
            rowKey,
            value,
        })
    );
};

export const loadWelcomeMessages = createAsyncThunk<
    WelcomeMessagesResponse,
    void,
    { state: RootState }
>('welcomeMessagesPanel/loadWelcomeMessages', async (_, { getState }) => {
    const {
        welcomeMessagesPanel: { nextPageToken, search },
    } = getState();

    const queryParams = new Map();

    if (!!search) {
        queryParams.set('search', search);
    }

    if (!!nextPageToken) {
        queryParams.set('tableContinuationToken', nextPageToken);
    }

    const query: string[] = [];
    queryParams.forEach((val, key) => {
        if (query.length) {
            query.push(`&${key}=${val}`);
        } else {
            query.push(`?${key}=${val}`);
        }
    });

    return await fetchWelcomeMessages(query.join(''));
});

export const searchWelcomeMessages = createAsyncThunk<
    WelcomeMessagesResponse,
    string,
    { state: RootState }
>('welcomeMessagesPanel/searchWelcomeMessages', async (search) => {
    const query: string = `?search=${search}`;
    return await fetchWelcomeMessages(query);
});

export const createWelcomeMessages = createAsyncThunk<
    WelcomeMessageEntity,
    WelcomeMessageEntity,
    { state: RootState }
>('welcomeMessagesPanel/createWelcomeMessages', async (newWelcomeMesage) => {
    return await createWelcomeMessage(newWelcomeMesage);
});

export const updateWelcomeMessages = createAsyncThunk<
    WelcomeMessageEntity,
    WelcomeMessageEntity,
    { state: RootState }
>('welcomeMessagesPanel/updateWelcomeMessages', async (newWelcomeMesage) => {
    return await updateWelcomeMessage(newWelcomeMesage);
});

export const deleteWelcomeMessages = createAsyncThunk<
    string,
    string,
    { state: RootState }
>('welcomeMessagesPanel/deleteWelcomeMessages', async (rowKey: string) => {
    await deleteWelcomeMessage(rowKey);
    return rowKey;
});

export const welcomeMessagesSlice = createSlice({
    name: 'welcomeMessagesPanel',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadWelcomeMessages.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(loadWelcomeMessages.fulfilled, (state, action) => {
                state.loadStatus = 'idle';
                state.items = getUniqWelcomeMessages([
                    ...state.items,
                    ...(action.payload?.items ?? []),
                ]);
                state.search = action.payload.search;
                state.nextPageToken = !!action.payload.nextPageToken
                    ? JSON.stringify(action.payload.nextPageToken)
                    : null;
            })
            .addCase(loadWelcomeMessages.rejected, (state) => {
                state.loadStatus = 'failed';
            })
            .addCase(createWelcomeMessages.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(createWelcomeMessages.fulfilled, (state, action) => {
                state.loadStatus = 'idle';
                if(!state.search || action.payload.rowKey.includes(state.search.toUpperCase())) {
                    state.items = getUniqWelcomeMessages([...state.items, action.payload]);
                }
            })
            .addCase(createWelcomeMessages.rejected, (state) => {
                state.loadStatus = 'failed';
            })
            .addCase(updateWelcomeMessages.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(updateWelcomeMessages.fulfilled, (state, action) => {
                state.loadStatus = 'idle';
                state.items = state.items.map((x) => {
                    if (x.rowKey === action.payload.rowKey) {
                        return {
                            ...action.payload,
                        };
                    }
                    return x;
                });
            })
            .addCase(updateWelcomeMessages.rejected, (state) => {
                state.loadStatus = 'failed';
            })
            .addCase(deleteWelcomeMessages.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(deleteWelcomeMessages.fulfilled, (state, action) => {
                state.loadStatus = 'idle';
                state.items = state.items.filter(
                    (x) => x.rowKey !== action.payload
                );
            })
            .addCase(deleteWelcomeMessages.rejected, (state) => {
                state.loadStatus = 'failed';
            })
            .addCase(searchWelcomeMessages.pending, (state) => {
                state.loadStatus = 'loading';
            })
            .addCase(searchWelcomeMessages.fulfilled, (state, action) => {
                state.loadStatus = 'idle';
                state.items = action.payload?.items ?? [];
                state.search = action.payload.search;
                state.nextPageToken = !!action.payload.nextPageToken
                    ? JSON.stringify(action.payload.nextPageToken)
                    : null;
            })
            .addCase(searchWelcomeMessages.rejected, (state) => {
                state.loadStatus = 'failed';
            });
    },
});

export const selectWelcomeMessagesPanel = (state: RootState) =>
    state.welcomeMessagesPanel;

export default welcomeMessagesSlice.reducer;
